import React from 'react'
import Modal from './Modal/Modal'
import Watermark from '@uiw/react-watermark'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import imgIsotopo from '../Assets/img/Isotipo.png';


const VisorFile = ({ isOpen, setIsOIpen, fileName, doc }) => {
    return (
        <>
            <Modal isOpen={isOpen} color='#fff' width={1000}>
                <div>
                    <h6><strong>Nombre del archivo: {fileName}</strong></h6>
                    <section className='tbl-scroll' style={{ overflow: 'auto', height: '700px' }}>
                        <Watermark
                            rotate={0}
                            height={265}
                            width={265}
                            image={imgIsotopo}
                        >
                            <DocViewer
                                documents={doc?.map((file) => ({
                                    uri: window.URL.createObjectURL(file),
                                    fileName: file.name,
                                }))}
                                pluginRenderers={DocViewerRenderers}
                            />
                        </Watermark>
                    </section>

                    <div className='d-flex justify-content-end mt-5'>
                        <button className="btn col-2" type="button" onClick={() => { setIsOIpen(false) }}>Cerrar</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default VisorFile