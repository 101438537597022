import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService';
import Catalogo from '../../Services/Catalogos/Catalogo';
import SeguroCotizadoService from '../../Services/Seguros/SeguroCotizadoService';
import VehiculoService from '../../Services/Vehiculo/vehiculo';
import SeguroSinCotizar from '../../Pages/Seguro/SinCotizar/SeguroSincotizar';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroFinalService from '../../Services/Seguros/SeguroFinalService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import { message } from '../../Utils/functions';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import InstalacionDatamovilService from '../../Services/Inventario/InventarioDatamovilService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import EndosoService from '../../Services/Inventario/EndosoService';

const usePendienteDeEndoso = (toast) => {
    const navigate = useNavigate();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'fechaFirmaContrato', headerName: 'Fecha de firma de contrato', width: 230 },
        { field: 'tipoCompra', headerName: 'Tipo de compra', width: 150 },
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 },
        { field: 'nombreYear', headerName: 'Año', width: 100 },
        { field: 'producto', headerName: 'Producto', width: 200 },
    ];
    let DataFiltroFolder = [
        { label: "Todos", value: "" },
        { label: "Vehículos con folder", value: 1 },
        { label: "Vehículos sin folder", value: 0 },
    ];
    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    //DataDocs
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [dataVehiculo, setDataVehiculo] = useState(null);
    const [claveTipoCompra, setclaveTipoCompra] = useState("")
    //Filtro
    const [tipoCompra, settipoCompra] = useState([]);
    const [pageRows, setpageRows] = useState(10);
    const [item, setItem] = useState(null);

    useEffect(() => {
        habdleGetAll(currenPage);
        setCurrenPage(1);
    }, [str, pageRows, claveTipoCompra])
    useEffect(() => {
        handleGetTipoCompra();
    }, [])
    const handleGetTipoCompra = async () => {
        await SeguroPolizaService.getAllTipocompra()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCompraId,
                        label: item.tipoCompra
                    })
                });
                settipoCompra(NewData);
            }).catch(e => {
            })
    }

    const habdleGetAll = async (page) => {
        setLoading(true);
        await EndosoService.getAllListaPendienteEndoso(page, pageRows, claveTipoCompra, str)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleDownloadFile = async () => {
        setLoading(true);
        await InstalacionDatamovilService.downloadVehiculosEncontrato(str, claveTipoCompra)
            .then(res => {

                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `VehiculosEncontrato-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

                habdleGetAll(1);

                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleUploadFile = async (item) => {
        navigate(`/inventario/vehiculo/datadocs/${item.generalId}`, {
            state: { ...item, endoso: true },
        });
    }


    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        habdleGetAll(event.selected + 1);
    };
    const handleEdit = (value) => {
        navigate(`/inventario/gestoria/placas/${value.generalId}`, {
            state: { ...value, action: 1 },
        });

    }

    const handlechekedItem = (cheked, value, i) => {
        let table = document.querySelectorAll('.tbl-custome >tr');

        for (let index = 0; index < table.length; index++) {
            const element = table[index];
            if (index !== i)
                element.firstChild.firstChild.checked = false;
        }

        if (cheked) {
            setItem(value);
        } else {
            setItem(null);
        }

    }
    return {
        columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        tipoCompra,
        setclaveTipoCompra,
        handleDownloadFile
    }
}

export default usePendienteDeEndoso