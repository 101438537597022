import { Formik, Form, Field } from 'formik'
import React, { useRef, useState, useEffect } from 'react'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import ConfigDataDocsService from '../../Services/ConfigDataDocs/ConfigDataDocsService';
import { message } from '../../Utils/functions';
import Spinner from '../../Components/Loadig';
import Modal from '../../Components/Modal/Modal';

const ConfigFichero = ({ item,toast,handleGetAll }) => {
    const refbtn = useRef();
    console.log("-->",item)
    useEffect(() => {
        setInitialValue({
            id:item.value ?? "",
            ficheroId: item?.clave ?? "",
            nombre: item?.label ?? "",
            dataDocsID : item?.dataDocsID,
            dataDocsDesc : item?.dataDocsDesc
        })
    }, [item])

    const [initialValue, setInitialValue] = useState();
    const [loading, setLoading] = useState(false);

    const validate = Yup.object({
        ficheroId: Yup.string()
            .required("El campo es requerido."),
        nombre: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres')
    });

    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        setLoading(true)
        await ConfigDataDocsService.UpdateFichero(item)
            .then(res => {
                if(res.data.data.ban === 1){
                    toast.success(message("!Correcto¡",res.data.data.mensaje));
                    // handleGetAll(1)
                    window.location.reload();
                }
                else
                    toast.error(message("!Error¡",res.data.data.mensaje));

            }).catch(e => {
                toast.error(message("¡Error",e));
            }).finally(()=>{
                refbtn.current.disabled = false;
                setLoading(false);
            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values }) => (
                <section className='mx-4'>
                    <span className='fs-5'><strong>{`Configuración fichero: ${item?.label ?? ""}`}</strong></span>
                    <Form>
                        <div className="mb-3 row mt-2">
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <TextField id="dataDocsID" label="Fichero ID" name="dataDocsID" type="text" holder="Fichero ID" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <TextField id="dataDocsDesc" label="Fichero" name="dataDocsDesc" type="text" holder="Fichero" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-2 d-flex flex-column justify-content-around">
                                <span></span>
                                <button id="AT_btnGuardar" className='btn' ref={refbtn} type="submit" disabled={!(isValid)}>Guardar</button>
                            </div>
                        </div>
                    </Form>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </section>
            )
            }
        </Formik>
    )
}

export default ConfigFichero