import React, { useEffect, useState } from 'react'
import Modal from '../../../Components/Modal/Modal'
import { isOpenModalCheck } from '../../../Redux/Slice/quickActionsSlice'
import { useDispatch } from 'react-redux';
import { TextField } from '../../../Components/TextField';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';

const ModalClaveVehiculo = ({ isOpenModalClave, item }) => {
    const dispatch = useDispatch();    

    

    const [data, setdata] = useState({});
    const [claveArray, setClaveArray] = useState([]);
    useEffect(() => {
        handleGetByIdClave();
    }, [])

    const handleGetByIdClave = async () => {
        await VentaVehiculoService.getAllVehiculoClave(item.generalId)
            .then(res => {
                
                setdata(res.data.data);
                let clave = res.data.data.clave
                let array = [...clave]
                setClaveArray(array);
            }).catch(e => {

            })
    }
    return (
        <Modal isOpen={isOpenModalClave} color='#fff'>
            <section className='my-4 mx-4' style={{ width: '40rem' }}>
                <section className='py-2 d-flex flex-column'>
                    <div className='d-flex justify-content-between'>
                        <span className='fs-5'><strong>Consulta de clave</strong></span>
                    </div>
                    <span className='mt-2'>A continuación se muestra el tipo de solicitud, la clave y su vigencia del vehículo previamente seleccionado:</span>
                </section>

                <div className="mb-3 row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <span>Clave</span>
                        <div className='d-flex mt-2'>
                            {
                                claveArray.map((item, i) => {
                                    return <div className='section-clave' key={i}>{item}</div>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <span>Vigencia de la clave</span>
                        <input type="text" disabled className='form-control mt-2' value={data.vigencia_clave} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <span>VIN</span>
                        <input type="text" disabled className='form-control mt-2' value={data.vin} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <span>Número de contrato</span>
                        <input type="text" disabled className='form-control mt-2' value={data.contrato} />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <span>Tipo de solicitud</span>
                        <input type="text" disabled className='form-control mt-2' value={data.tipoSolicitud} />
                    </div>

                </div>

                <section className='my-3 d-flex justify-content-end'>
                    <fieldset className='btn px-5' type='reset' onClick={() => { dispatch(isOpenModalCheck({ check: !isOpenModalClave })) }}>Cerrar</fieldset>
                </section>

            </section>
        </Modal>
    )
}

export default ModalClaveVehiculo