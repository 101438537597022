import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Field, Formik } from 'formik'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import BuscadorSiniestroVINModal from '../../../Components/Modal/BuscadorSiniestroVINModal';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import BuscadorGestoriaModal from '../../../Components/Modal/BuscadorGestoriaModal';
// import BuscadorSiniestroVINModal from '../../../Components/Modal/BuscadorSiniestroVINModal';

const SeguimientoGestoria = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const options = []
    let initial = {
        buscar: "",
        estatus: null,
        fechaInicio: "",
        fechaFin: "",
        procesoId: null,
        concluido: false,
        page: 1,
        rows: 10
    }
    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabInfo, setTabInfo] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [initialValue, setInitialValue] = useState(initial);
    const [concluidos, setConcluidos] = useState(false);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });
    
    const columns = [
        { field: 'folioId', headerName: 'Folio gestoría'},
        { field: 'vin', headerName: 'VIN'},
        { field: 'numContrato', headerName: 'Contrato'},
        { field: 'tipoTramite', headerName: 'Tipo trámite'},
        { field: 'estatus', headerName: 'Estatus'},
        { field: 'nombre', headerName: 'A nombre de'},
        { field: 'subestatus', headerName: 'Subestatus'},
        { field: 'fechaActualiza', headerName: 'Última actualización'},
        { field: 'nombreMarca', headerName: 'Marca'},
        { field: 'nombreModelo', headerName: 'Modelo'},
        { field: 'nombreYear', headerName: 'Año'},
    ];
    useEffect(() => {
        seguimientoGestoriaBusqueda(initialValue)
    }, []);
    const dateFormat = (d) => {
        let dateBD = new Date(d+"Z");
        let formattedDate = ("0"+dateBD.getDate()).slice(-2)+"-"+("0"+dateBD.getMonth()).slice(-2)+"-"+dateBD.getFullYear()+" "+("0"+dateBD.getHours()).slice(-2)+":"+("0"+dateBD.getMinutes()).slice(-2)+" hrs.";
        return formattedDate;
    }
    const handleEdit = (item) => {
        const dataUrl = {
            url: location.pathname
        }
        dispatch(setUrlSlice(dataUrl));
        navigate(`/gestoria/seguimiento/${item.vin}/${item.gestoriaRegistroInicialId}`);
    }
    const [pagina, setPagina] = useState(1);
    
    
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            estatus: refFormik.current.values.estatus,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
    };
    const getCounter = (tab) => {
        console.log(tabInfo[tab]);
        return tabInfo[tab]?.length || 0;
    };

    const seguimientoGestoriaBusqueda = async (data) => {
        console.log(concluidos);
        data.concluido = concluidos;
        console.log(data.concluido);
        setLoading(true);
        await Gestoria.getSeguimientoGestoria(data)
        .then(resp => {
            
            resp.data.data = resp.data.data.map(item => {
                // console.log(item)
                if(item.fechaActualiza !== null){
                    item.fechaActualiza = item.fechaActualiza.split("T")[0].split("-").reverse().join("-");
                }
                return item;
            });
            setData(resp.data);
        })
        .catch(err => {
            console.error(err)
        })
        .finally(() => setLoading(false))
    }

    const [collpsableGrid, setCollpsableGrid] = useState(false);
    const [busquedaAvanzada, setBusquedaAvanzada] = useState(false);
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <BuscadorGestoriaModal 
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                    </Modal>
                    <section>
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Seguimiento a gestoría</strong>
                                        </div>
                                        <div className="col-auto">
                                            <button className='btn' onClick={(e) => {setModalMessage({ isOpen: true, type: 3, title: 'Buscador de VIN'})}}>
                                                Registrar trámite
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className='col-12' >
                                        <div className="row" id="collapseBusqueda">
                                            <div className="col-12 col-md-3 mt-2 mr-1 inner-addon right-addon">
                                                <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" 
                                                onBlur={(event) => {
                                                    seguimientoGestoriaBusqueda(values)
                                                }}
                                                onChange={(event) => {
                                                    setFieldValue("buscar", event.target.value);
                                                }} />
                                            </div>
                                            {/* <div className="col-12 col-md-3 mt-2 mr-1">
                                                <SelectField id={'procesoId'}
                                                    name={'procesoId'}
                                                    items={options}                                                                                            
                                                    onChange={(event) => {
                                                        setFieldValue('procesoId', event.value);
                                                }} />
                                            </div> */}
                                            <div className="col-12 col-md-3 mt-2 mr-1">
                                                <SelectField id={'estatus'}
                                                    name={'estatus'}
                                                    items={options}                                                                                            
                                                    onChange={(event) => {
                                                        setFieldValue('estatus', event.value);
                                                        seguimientoGestoriaBusqueda(values)
                                                }} />
                                            </div>
                                            <div className="col-12 col-md-2 mt-2 mr-1">
                                                <TextField id="fechaInicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechaInicio", event.target.value);
                                                    seguimientoGestoriaBusqueda(values)
                                                }} />
                                            </div>
                                            <div className="col-12 col-md-2 mt-2 mr-1">
                                                <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("fechaFin", event.target.value);
                                                    seguimientoGestoriaBusqueda(values)
                                                }} />
                                            </div>
                                            <div className="col-12 col-md-auto mt-3 mr-1">
                                                <Field className="form-check-input" type="checkbox" name="concluido"
                                                onClick={(event) => {
                                                    console.log(event.target.checked);
                                                    setConcluidos(event.target.checked);
                                                    setFieldValue("concluido", event.target.checked);
                                                    seguimientoGestoriaBusqueda(values);
                                                }} />
                                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Incluir finalizados</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-3'>
                                        <div className="row justify-content-end">
                                            <div className="col-auto col-md-auto mt-2 mr-1">
                                                <button className='btn btn-blue' onClick={(e) => seguimientoGestoriaBusqueda(values)}>
                                                    <i className="glyphicon fas fa-search"></i>
                                                </button>
                                            </div>
                                            <div className="col-auto col-md-auto mt-2 mr-1">
                                                <button className='btn btn-blue' onClick={(e) => setBusquedaAvanzada(!busquedaAvanzada)}>
                                                    { busquedaAvanzada ? 'Búsqueda Simple' : 'Búsqueda Avanzada' }
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className='col-12'>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            {
                                                tabs.map((tab, idx) => {
                                                    return <li className="nav-item" key={'tab_'+idx} role="presentation">
                                                        <button className={`nav-link ${tabIndex == idx ? 'tab-active' : ''}`} value={idx} type="button" role="tab" onClick={(e) => { setTabIndex(idx); setDataToShow(tabInfo[tab]) }}>{tab} ({getCounter(tab)})</button>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        {
                                            tabIndex !== 0 && dataToShow.length > 0 &&
                                            <DataTable
                                                column={columns}
                                                data={dataToShow}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                            />
                                        }
                                        {
                                            tabIndex === 0 &&
                                            <DataTable
                                                column={columns}
                                                data={data?.data}
                                                editable={true}
                                                handleEdit={handleEdit}
                                                pageCount={data?.totalPages}
                                                currentPage={currenPage - 1}
                                                handlePageClick={handlePageClick}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                            />
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}
export default SeguimientoGestoria;