import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Normalize } from '../../../Utils/functions';
import EtapaService from '../../../Services/ControlEtapa/confEtapa';
import Gestoria from '../../../Services/Gestoria/Gestoria';
import AuthLogin from '../../../Utils/AuthLogin';
import Spinner from '../../../Components/Loadig';
import Modal from '../../../Components/Modal/Modal';

const auth = new AuthLogin();
const SubMenuPrealta = ({ id, state, progressBar }) => {
    const { role } = useSelector((state) => state.loginSlice);
    const location = useLocation();
    let url = location.pathname.split("/");
    // 
    let module = url[3];

    const [dataEtapa, setDataEtapa] = useState([]);
    const [revisionUnidad, setRevisionUnidad] = useState(false);
    const [essociocomercial, setEssociocomercial] = useState(role === "DMSocioComercial" ? true : false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleGetAllMenu();
        // handleGetRevisionHabilitada();
    }, [])

    const handleGetAllMenu = async () => {

        let generalId = id == 0 ? "" : id;
        if (generalId !== "") {
            setLoading(true);
            await EtapaService.getAllSubMenuEtapas(generalId, state?.productoId)
                .then(res => {
                    if (essociocomercial) {
                        setDataEtapa(res.data.data.filter(x => x.etapa === "Prealta"))
                    } else
                        setDataEtapa(res.data.data);
                
                    setLoading(false);            
                }).catch(e => {
                    setLoading(false);
                })
        }
    }

    const handleGetRevisionHabilitada = async () => {
        if (id !== 0) {
            await Gestoria.RevisionUnidadHabilitadaGet(id)
                .then(resp => {
                    setRevisionUnidad(resp.data.revisionUnidadHabilitada);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    const navigate = useNavigate();

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <aside id="sidebar" className="sub-sidebar col-2 p-0 tbl-scroll-mn" style={{ height: '90vh', overflowY: 'auto' }}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    {
                        dataEtapa?.map((item, index) => {
                            if (item.menuEtapa) {
                                return (
                                    <li className={`nav-item                                 
                                    ${(item?.etapa === 'Placas') ? state?.ventaContado ? (state?.etapaId >= 2 && item?.revisionUnidad) ? "" : 'disabled' : state?.revisionUnidad ? (state?.etapaId >= 2 && item?.revisionUnidad) ? '' : 'disabled' : (state?.etapaId >= 2) ? '' : 'disabled' : ''}
                                    ${(item?.etapaId === 3) ? item?.registroPlaca ? "" : 'disabled' : ''} 
                                    ${state?.ventaContado ? (Normalize(item.etapa) === 'autorizacion') ? state?.etapaId >= 3 ? "" : "disabled" : "" : ""}
                                    ${(item?.etapaId >= 2) ? (Normalize(item.etapa) === 'revisiondeunidad') ? state?.revisionUnidad ? "" : 'disabled' : '' : ''}
                                    `} key={index}>
                                        <a className={`nav-link ${module === Normalize(item.etapa) ? "active-menu" : "collapsed"}`} data-bs-target={`#components-${Normalize(item.etapa)}`} data-bs-toggle="collapse" onClick={() => {
                                            if (item?.etapa === 'Placas') {
                                                navigate(`/inventario/gestoria/${Normalize(item.etapa)}/${id}`, {
                                                    state: state
                                                })
                                            } else {
                                                if (state?.ventaContado) {
                                                    navigate(`/inventario/venta/${Normalize(item.etapa)}/${id}`, {
                                                        state: state
                                                    })
                                                } else {
                                                    navigate(`/inventario/vehiculo/${Normalize(item.etapa)}/${id}`, {
                                                        state: state
                                                    })
                                                }

                                            }
                                        }}><strong>{item.etapa}</strong><i className="bi bi-chevron-down ms-auto"></i> </a>
                                        <ul id={`components-${Normalize(item.etapa)}`} className={`nav-content collapse ${module === Normalize(item.etapa) ? "show" : ""}`} data-bs-parent="#sidebar-nav" style={{ backgroundColor: '#e8eaf6' }}>
                                            <section className='d-flex flex-column px-2'>
                                                <div className="progress custome-progress" >
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${progressBar}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <span>Documentos</span>
                                                <span>Datos</span>
                                            </section>
                                        </ul>
                                    </li>
                                )
                            }
                        })
                    }
                </ul>
            </aside>
        </>
    )
}

export default SubMenuPrealta