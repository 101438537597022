import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import GestoriaServices from '../../Services/Inventario/GestoriaServices';
import VehiculoConvertidoService from '../../Services/Inventario/VehiculoConvertidoService';
import EtapaService from '../../Services/ControlEtapa/confEtapa';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import Catalogo from '../../Services/Catalogos/Catalogo';

const useVehiculosEnCredito = () => {
  const navigate = useNavigate();
  const columns = [
    { field: 'vin', headerName: 'VIN', width: 150 },
    { field: 'contrato', headerName: 'Contrato', width: 250 },
    { field: 'tipoExpediente', headerName: 'Tipo de Expediente', width: 150 },
    { field: 'strFechaEntrega', headerName: 'Fecha de entrega del expediente', width: 280 },
    { field: 'nombreUbicacion', headerName: 'Ubicación del expediente', width: 150 },
    { field: 'encargadoEntrega', headerName: 'Entregado', width: 150 },
    { field: 'tipoCompra', headerName: 'Tipo de compra', width: 100 },
    { field: 'tipoExterno', headerName: 'Externo', width: 150 },
    { field: 'recibidoAtencionClientes', headerName: 'Atención a cliente', width: 170 },
    { field: 'strFechaRecibidoAtencionClientes', headerName: 'Fecha de recibido de atención a cliente', width: 250 },
    { field: 'recibidoContraloria', headerName: 'Contraloria', width: 150 },
    { field: 'strFechaRecibidoContraloria', headerName: 'Fecha de recibidó de contraloria', width: 250 },
    { field: 'producto', headerName: 'Producto', width: 200 },
  ];

  const [data, setData] = useState([]);
  const [currenPage, setCurrenPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(false);
  //Filtro
  const [DataEtapa, setDataEtapa] = useState([]);
  const [tipoCompra, settipoCompra] = useState([]);
  const [DataUsoUnidad, setDataUsoUnidad] = useState([])
  const [DataSocioComercial, setDataSocioComercial] = useState([])
  const [claveTipoCompra, setclaveTipoCompra] = useState("");
  const [claveVendedor, setClaveVendedor] = useState("");
  const [pageRows, setpageRows] = useState(10);
  const [fechInicio, setFechInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [ClaveUsoUnidad, setClaveUsoUnidad] = useState("")
  const [EtapaId, setEtapaId] = useState("")
  const [DataUbicacion, setDataUbicacion] = useState([])
  const [claveUbicacion, setClaveUbicacion] = useState("");

  useEffect(() => {
    habdleGetAll(currenPage);
    setCurrenPage(1);
  }, [pageRows, claveTipoCompra, claveVendedor, claveUbicacion, str, fechInicio, fechaFin])
  useEffect(() => {
    handleGetAllSelects();
  }, [])

  const handleGetAllSelects = async () => {
    setLoading(true)
    const [] = await Promise.all([handleGetAllUsoUnidad(), handleGetAllEtapa(), handleGetTipoCompra(), handleGetSocioComercial(), handleGetAllUbicacion()]).finally(() => {
      setLoading(false)
    });
  }
  const habdleGetAll = async (page) => {
    setLoading(true);
    await VehiculoConvertidoService.getAllVehiculosEnCredito(page, pageRows, claveTipoCompra, claveVendedor, claveUbicacion, str, fechInicio, fechaFin)
      .then(res => {
        console.log(res.data.data)
        // res.data.data.strFechaRecibidoAtencionClientes = res.data.data.strFechaRecibidoAtencionClientes.split("T")[0];
        setData(res.data.data);
        setPageCount(res.data.totalPages);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }

  const handleGetTipoCompra = async () => {
    await SeguroPolizaService.getAllTipocompra()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.tipoCompraId,
            label: item.tipoCompra
          })
        });
        settipoCompra(NewData);
      }).catch(e => {
      })
  }
  const handleGetAllUsoUnidad = async () => {
    await SeguroSinCotizarService.getlAllUsoUnidad()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.usoUnidadId,
            label: item.usoUnidad
          })
        });
        setDataUsoUnidad(NewData);
      }).catch(e => {

      })
  }
  const habdleDownloadFile = async () => {
    setLoading(true);
    await VehiculoConvertidoService.DownloadVehiculosEnCredito(claveTipoCompra, claveVendedor, claveUbicacion, str, fechInicio, fechaFin)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `VehiculosEnCredito-${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

        habdleGetAll(1);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetAllEtapa = async () => {
    setLoading(true);
    await EtapaService.getAllEtapa()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.etapaId,
            label: item.etapa
          })
        });
        setDataEtapa(NewData);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }
  const handleGetSocioComercial = async () => {
    await SocioComercialServices.getAllSocioComercialList()
      .then(res => {
        let NewData = [];
        NewData.push({
          value: "",
          label: "Todos"
        })
        res.data.data?.map(item => {
          NewData.push({
            value: item.socioComercialId,
            label: item.nombre
          })
        });
        setDataSocioComercial(NewData);
      }).catch(e => {

      })
  }
  // const handleGetAllUbicacion = async () => {
  //   await SeguroSinCotizarService.getlAllUbicacion()
  //     .then(res => {
  //       let NewData = [];
  //       NewData.push({
  //         value: "",
  //         label: "Todos"
  //       })
  //       res.data.data?.map(item => {
  //         NewData.push({
  //           value: item.ubicacionId,
  //           label: item.nombre
  //         })
  //       });
  //       setDataUbicacion(NewData);
  //     }).catch(e => {

  //     })
  // }
  const handleGetAllUbicacion = async () => {
    await Catalogo.getdataTipoUbicacion("Archivo")
        .then(res => {
            let NewData = [];
            res.data.data?.map(item => {
                NewData.push({  
                    value: item.ubicacionId,
                    label: item.ubicacion
                })
            });
            setDataUbicacion(NewData);
        }).catch(e => {
        })
}
  const handlePageClick = (event) => {
    setCurrenPage(event.selected + 1);
    habdleGetAll(event.selected + 1);
  };
  const handleDetail = (value) => {
    value.vehiculoEnCredito = true;
    if (value.etapaId === 1) {
      navigate(`/inventario/vehiculo/prealta/${value.generalId}`, {
        state: value
      });
    } else if (value.etapaId === 2) {
      navigate(`/inventario/vehiculo/alta/${value.generalId}`, {
        state: value
      });
    } else if (value.etapaId === 3 || value.etapaId === 11 || value.etapaId === 5 || value.etapaId === 15) {
      navigate(`/inventario/vehiculo/valoracion/${value.generalId}`, {
        state: value
      });
    } else {
      navigate(`/inventario/vehiculo/prealta/${value.generalId}`, {
        state: value
      });
    }
    // else if (value.etapaId === 5) {
    //   navigate(`/seguro/cotizado/seguro/${value.generalId}`, {
    //     state: {...value,action:1},      
    //   });
    // }
  }
  return {
    columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    setpageRows,
    habdleDownloadFile,
    DataSocioComercial,
    tipoCompra,
    setclaveTipoCompra,
    setClaveVendedor,
    setFechInicio,
    setFechaFin,
    DataEtapa,
    DataUsoUnidad,
    setClaveUsoUnidad,
    setEtapaId,
    DataUbicacion,
    setClaveUbicacion,
    handleDetail
  }
}

export default useVehiculosEnCredito