import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { tabActivePrealta } from '../../../Redux/Slice/navigateSlicePrealta';
import ModalCostos from './ModalCostos';
import SubMenuPrealta from '../Prealta/subMenuPrealta';
import { FormatNumberMoney, message } from '../../../Utils/functions';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import AuthLogin from '../../../Utils/AuthLogin';
import ModalConfirAlta from './ModalCofirmAlta';
import ModalConfirmAlta from './ModalCofirmAlta';

const auth = new AuthLogin();
const VehiculoAlta = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const role = auth.getSesionValue("role");
    const refFormik = useRef();
    let initial = {
        generalId: id,
        transmisionId: "",
        numMotor: "",
        color: "",
        kilometraje: "",
        precioLista: "",
        ubicacionId: "",
        aplicaEntidadPlacas: "",
        entidadPlacasId: "",
        costoEntidadPlacas: "",
        tenencia: "",
        cambioPropietario: "",
        cambioPropietarioValue: "",
        costo: "",
        costoPrealta: "",
        registroCostos: [],
        esRolCompras: (role === "Compras" || role === "Administrador") ? true : false,
        numSolicitud: "",
        usoUnidadId: "",
        esSocioIndependiente: (state?.tipoCompra === "Socio Comercial" || state?.tipoCompra === "Independiente") ? true : false,
        numSolicitudExterno: state?.numSolicitud,
        usoUnidadIdExterno: state?.usoUnidadId,
        ventaContado: state?.ventaContado,
        vehiculoEnCredito: state?.vehiculoEnCredito
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [isOpenModalconfirm, setIsOpenModalconfirm] = useState(false);

    const validate = Yup.object({
        transmisionId: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        numMotor: Yup.string()
            .required("El campo es requerido."),
        color: Yup.string()
            .required("El campo es requerido."),
        kilometraje: Yup.number()
            .required("El campo es requerido.")
            .positive("Ingrese Valores positivos."),
        precioLista: Yup.string()
            .required("El campo es requerido."),
        // .positive("Ingrese Valores positivos."),
        ubicacionId: Yup.string()
            .required("El campo es requerido."),
        aplicaEntidadPlacas: Yup.bool()
            .required("El campo es requerido."),
        entidadPlacasId: Yup.string()
            .when("aplicaEntidadPlacas", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        costoEntidadPlacas: Yup.string()
            .when("aplicaEntidadPlacas", (aplicaEntidadPlacas) => {
                console.log(aplicaEntidadPlacas)
                if (aplicaEntidadPlacas)
                    return Yup.string().required("El campo es requerido.")
            }),
        tenencia: Yup.string()
            .required("El campo es requerido."),
        cambioPropietario: Yup.string()
            .required("El campo es requerido."),
        costo: Yup.string()
            .required("El campo es requerido."),
        // .moreThan(0,"el monto debe ser mayor a 0"),
        numSolicitud: Yup.string()
            .when(["esRolCompras", "esSocioIndependiente"], (esRolCompras, esSocioIndependiente) => {
                if (esRolCompras && esSocioIndependiente) {
                    return Yup.string()
                        .required("El campo es requerido.")
                        .trim("El texto no debe empezar ni terminar con espacios")
                        .strict(true)
                        .matches(
                            "^[0-9-]+(?: [0-9-]+)*$",
                            "Ingrese numeros y guiones"
                        )
                }
            }),
        usoUnidadId: Yup.string()
            .when(["esRolCompras", "esSocioIndependiente"], (esRolCompras, esSocioIndependiente) => {
                if (esRolCompras && esSocioIndependiente) {
                    return Yup.string()
                        .required("El campo es requerido.")
                }
            }),
    });

    const dataAplica = [
        { value: true, label: "Si aplica" },
        { value: false, label: "No aplica" }
    ];

    const [dataEdoMexico, setdataEdoMexico] = useState([]);
    const [dataTransmision, setDataTransmision] = useState([]);
    const [loading, setLoading] = useState(false);
    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [tipoUbicacion, setTipoUbicacion] = useState([]);
    const [dataColores, setDataColores] = useState([]);
    const [progressBar, setProgressBar] = useState(0);
    const [isOpenModalcostos, setisOpenModalcostos] = useState(false)
    const [rowsCostos, setRowsCostos] = useState([]);
    const [costoVehiculoState, setCostoVehiculoState] = useState(0);
    const [costoPropiertarioState, setCostoPropiertarioState] = useState(0);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([]);
    const [costoCambioPropietario, setCostoCambioPropietario] = useState(0);
    const [costoVehiculoValor, setCostoVehiculoValor] = useState(0);
    const [registroCostosState, setRegistroCostosState] = useState([]);

    let CostoVehiculo = 0;
    let costoPropietarioValue = 0;
    let costos = [];
    useEffect(() => {
        setLoading(true)

        // if (state.etapaId < 2) {
        //     handleGetValorTenencia();
        // }
        handleGetAllSelects()

        if (state.etapaId > 1) {
            handleChageProgress(refFormik.current.errors);
        }
        return () => {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        const data = initialValue;
        let cambio = (parseFloat(costoPropiertarioState) / 100) * costoVehiculoState;
        setInitialValue({
            ...data,
            cambioPropietario: cambio,
            costo: costoVehiculoState
        });
    }, [costoPropiertarioState, costoVehiculoState])

    useEffect(() => {
        const data = initialValue;
        setInitialValue({
            ...data,
            registroCostos: registroCostosState,
        });

    }, [registroCostosState]);

    const handleGetAllSelects = async () => {
        // setLoading(true)
        const [] = await Promise.all([
            handleGetCambioPropietario(),
            handleGetAllEdoMexico(),
            handleGetTipoTRansmision(),
            handleGetTipoUbicacion(),
            handleGetallCostosById(),
            handleGetAllColores(),
            handleGetAllUsoUnidad(),
            handleGetValorTenencia()
        ]).finally(() => {
            // setLoading(false)
        });
    }

    const handleGetAllCostoVehiculo = async () => {
        await VentaVehiculoService.getCostoVehiculo(id)
            .then(res => {
                CostoVehiculo = res.data.data.costo;
                // setInitialValue({ ...initialValue, costo: res.data.data.costo ?? "",cambioPropietario: costoPropietarioValue * res.data.data.costo })              
            }).catch(e => {
            })
    }
    const handleGetAllColores = async () => {
        await VentaVehiculoService.getAllColores()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.cvColorValue,
                        label: item.cvColorValue
                    })
                });
                setDataColores(NewData);
            }).catch(e => {
            })
    }
    const handleGetById = async (data) => {
        await VentaVehiculoService.getlAllVehiculoAltaById(id)
            .then(async (res) => {
                let item = res.data.data;
                console.log("item-->", item)

                // let cambioPropietarioCosto = (costoPropietarioValue / 100) * CostoVehiculo;
                // let costo = await handleCostos(data);


                let initial = {
                    generalId: id,
                    transmisionId: item?.transmisionId ?? "",
                    numMotor: item?.numMotor ?? "",
                    color: item?.color ?? "",
                    kilometraje: item?.kilometraje ?? "",
                    precioLista: (item?.precioLista.toFixed(2) ?? ""),
                    ubicacionId: item?.ubicacionId ?? "",
                    aplicaEntidadPlacas: item?.aplicaEntidadPlacas ?? false,
                    entidadPlacasId: item?.entidadPlacasId ?? "",
                    costoEntidadPlacas: (item?.costoEntidadPlacas ?? ""),
                    tenencia: (item?.tenencia ?? ""),
                    cambioPropietario: (costoPropietarioValue / 100) * CostoVehiculo,
                    costo: handleCostos(data),
                    // costo: item?.costo ?? "",                    
                    costoPrealta: item?.costo ?? "",
                    registroCostos: data,
                    costoPropietarioValue,
                    costoVehiculoValue: CostoVehiculo,
                    esRolCompras: (role === "Compras" || role === "Administrador") ? true : false,
                    esSocioIndependiente: (state?.tipoCompra === "Socio Comercial" || state?.tipoCompra === "Independiente") ? true : false,
                    numSolicitud: item?.numSolicitud ?? "",
                    usoUnidadId: item?.usoUnidadId ?? "",
                    numSolicitudExterno: state?.numSolicitud,
                    usoUnidadIdExterno: state?.usoUnidadId,
                    ventaContado: state?.ventaContado,
                    vehiculoEnCredito: state?.vehiculoEnCredito
                }
                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllUsoUnidad = async () => {
        // setLoading(true);
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
                // setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetallCostosById = async () => {
        // setLoading(true);
        await Catalogo.getdataCostosById(id)
            .then(res => {
                CostoVehiculo = res.data.data[0].total;
                costos = res.data.data

                setCostoVehiculoState(res.data.data[0].total);
                // setInitialValue({ ...initialValue, registroCostos: res.data.data,costo: CostoVehiculo });
                if (state.etapaId > 1) {
                    handleGetById(res.data.data);
                }
                // setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetTipoUbicacion = async () => {
        // setLoading(true)
        await Catalogo.getdataTipoUbicacion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.ubicacion
                    })
                });
                setTipoUbicacion(NewData);
                // setLoading(false)
            }).catch(e => {
                // setLoading(false)
            })
    }
    const handleGetAllEdoMexico = async () => {
        await Catalogo.getdataTabla("EdoMexico", 1, 9999)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item?.status === true) {
                        NewData.push({
                            value: item.edoMexicoId,
                            label: item.estado,
                            costo: item.costo
                        })
                    }
                });
                setdataEdoMexico(NewData);
            }).catch(e => {
            })
    }


    const handleGetTipoTRansmision = async () => {
        await Catalogo.getdataTabla("Transmision", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item?.status === true) {
                        NewData.push({
                            value: item.transmisionId,
                            label: item.transmision
                        })
                    }
                });
                setDataTransmision(NewData);
            }).catch(e => {

            })
    }
    const handleGetValorTenencia = async () => {
        // setLoading(true);
        if (state.etapaId < 2) {
            await Catalogo.getValorParametro("tenencia")
                .then(res => {

                    let cambioPro = (parseFloat(costoPropietarioValue) / 100) * CostoVehiculo;
                    setCostoCambioPropietario(cambioPro);
                    setCostoVehiculoValor(CostoVehiculo);
                    console.log(costos);
                    setRegistroCostosState(costos);

                    // let cambioPro = (parseFloat(costoPropiertarioState) / 100) * costoVehiculoState;
                    setInitialValue({ ...initialValue, tenencia: res.data.data.valor ?? "", registroCostos: costos, costo: CostoVehiculo, costoPrealta: CostoVehiculo, costoPropietarioValue, cambioPropietario: cambioPro, costoVehiculoValue: CostoVehiculo })
                    // setInitialValue({ ...initialValue, tenencia: res.data.data.valor ?? "", costo: CostoVehiculo,costoPrealta:CostoVehiculo,costoPropietarioValue, cambioPropietario: costoPropietarioValue * CostoVehiculo,costoVehiculoValue:CostoVehiculo})                
                    // setLoading(false);
                    // setTimeout(() => {
                    setLoading(false);
                    // }, 3000);
                }).catch(e => {
                    setLoading(false);
                })
        }

    }
    const handleGetCambioPropietario = async () => {
        await Catalogo.getValorParametro("cambioPropietario")
            .then(res => {
                costoPropietarioValue = res.data.data.valor;
                setCostoPropiertarioState(res.data.data.valor);
            }).catch(e => {

            })
    }

    const handleChangeCosto = async (values, value) => {
        await setInitialValue({ ...values, costo: value, cambioPropietario: (parseFloat(costoPropiertarioState / 100).toFixed(2) * value).toFixed(2) })
    }

    const handleAddEdit = async (value) => {

        // refbtn.current.disabled = true;        
        if (state.etapaId === 1) {
            setLoading(true);
            await VentaVehiculoService.AddVehiculoAlta(value)
                .then(res => {
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                    setLoading(false);
                    if (state?.esSocioComercial)
                        navigate('/inventario/sociocomercial')
                    else if (state?.ventaContado)
                        navigate('/inventario/venta/ventacontado');
                    else
                        navigate('/inventario/vehiculo')
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else if (state.etapaId > 1) {
            setLoading(true);
            await VentaVehiculoService.updateVehiculoAlta(value)
                .then(res => {
                    toast.success(message("¡Correcto.!", res.data.data.mensaje));
                    setLoading(false);
                    if (state?.esSocioComercial)
                        navigate('/inventario/sociocomercial')
                    else
                        navigate('/inventario/vehiculo')
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }
    }

    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 10;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);

    }
    const handleCostos = (items) => {
        let total = 0;
        items?.map(x => {
            if (x.total !== "")
                total = total + parseFloat(x.total)
        })
        return total.toFixed(2);
    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                if (values.esRolCompras) {
                    if (values.esSocioIndependiente) {
                        if (state.role === 'Compras') {
                            handleAddEdit(values);
                        } else {
                            setIsOpenModalconfirm(true);
                        }
                    } else {
                        handleAddEdit(values);
                    }
                }
                else
                    handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial')
                            else if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else
                                navigate('/inventario/vehiculo')
                        }}></i>
                        Regresar a listado general de vehículos
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />
                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <span className='fs-5'><strong>Información del vehiculo</strong></span>
                                        <div className='d-flex'>
                                            {
                                                !values.vehiculoEnCredito &&
                                                <>
                                                    <fieldset className='btn btn-outline mx-2 d-flex align-items-center' onClick={() => {
                                                        if (state.numIdFolder !== null) {
                                                            navigate(`/inventario/vehiculo/datadocs/${id}`, {
                                                                state: state,
                                                            });
                                                        } else
                                                            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Comuníquese con su administrador para habilitar el alta de Documentos en este Vehiculo.")}</div>);

                                                    }}><i className="icon ri-file-3-fill mx-1"></i>Subir documentos</fieldset>
                                                    <button id="Guardar_Editar" className='btn mx-2' type="submit" onClick={() => { console.log(errors) }}>{state.etapaId > 1 ? "Editar" : "Guardar"}</button>
                                                </>
                                            }
                                            {
                                                values.vehiculoEnCredito &&
                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                            }
                                        </div>

                                        {/* disabled={!(isValid)} */}
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <p> <strong>Caracteristicas del vehículo</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={values.vehiculoEnCredito} id="Transmision" label="Transmisión:" name="transmisionId" items={dataTransmision} onChange={(event) => {
                                                    setFieldValue("transmisionId", event.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} id="NumeroDeMotor" label="Numero de motor:" name="numMotor" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("numMotor", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                {/* <SelectField label="Color:" name="color" items={dataColores} onChange={(event) => {
                                                    setFieldValue("color", event.value);
                                                    handleChageProgress(errors);
                                                }} /> */}
                                                <TextField disabled={values.vehiculoEnCredito} id="Color" label="Color:" name="color" type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("color", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />

                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} id="Kilometraje" label="Kilometraje:" name="kilometraje" type="number" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("kilometraje", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} id="PrecioLista" label="Precio lista:" icon="bi bi-currency-dollar" name="precioLista" isMoney={true} type="text" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("precioLista", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={values.vehiculoEnCredito} id="Ubicacion" label="Ubicación:" name="ubicacionId" items={tipoUbicacion} onChange={(event) => {
                                                    setFieldValue("ubicacionId", event.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                        </div>
                                        {
                                            (values.esRolCompras && values.esSocioIndependiente) &&
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField disabled={values.vehiculoEnCredito} id="noSolicitud" label="No de Solicitud:" name="numSolicitud" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField disabled={values.vehiculoEnCredito} id="usoUnidad" label="Uso de unidad:" name="usoUnidadId" items={DataUsoUnidad} onChange={(event) => {
                                                        setFieldValue("usoUnidadId", event.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='my-2'>
                                        <p> <strong>Entidad placas</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <SelectField disabled={values.vehiculoEnCredito} id="Aplica" label="Aplica:" name="aplicaEntidadPlacas" items={dataAplica} onChange={(event) => {
                                                    setFieldValue("aplicaEntidadPlacas", event.value);
                                                    setFieldValue("entidadPlacasId", event.value ?? "");
                                                    setFieldValue("costoEntidadPlaca", event.value === "" ? "" : "");
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            {
                                                values.aplicaEntidadPlacas &&
                                                <>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField disabled={values.vehiculoEnCredito} id="Entidad" label="Entidad:" name="entidadPlacasId" items={dataEdoMexico} onChange={(event) => {
                                                            setFieldValue("entidadPlacasId", event.value);
                                                            setFieldValue("costoEntidadPlacas", event.value !== "" ? event.costo.toFixed(2) : "");
                                                            handleChageProgress(errors);
                                                        }} />
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <TextField disabled id="CostoEntidadPlacas" label="Costo entidad placas:" icon="bi bi-currency-dollar" isMoney={true} name="costoEntidadPlacas" type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("costoEntidadPlacas", event.target.value);
                                                            handleChageProgress(errors);
                                                        }} />
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p> <strong>Tenencia y cambio de propietario</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled={values.vehiculoEnCredito} id="Tenencia" label="Tenencia:" name="tenencia" type="text" icon="bi bi-currency-dollar" isMoney={true} holder="Escribe" onChange={(event) => {
                                                    setFieldValue("tenencia", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled id="CambioPropietario" label="cambio propietario:" icon="bi bi-currency-dollar" isMoney={true} name="cambioPropietario" type="text" holder="Escribe" onChange={async (event) => {
                                                    await setFieldValue("cambioPropietario", event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-2'>
                                        <p> <strong>Costo</strong></p>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField label="Costo" disabled name="costo" type="text" icon="bi bi-currency-dollar" holder="Escribe" isMoney={true} onChange={(event) => {
                                                    setFieldValue("costo", event.target.value);
                                                    handleChangeCosto(values, event.target.value);
                                                    handleChageProgress(errors);
                                                }} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <label className='text'></label>
                                                <fieldset disabled={values.vehiculoEnCredito} className='btn d-flex justify-content-center align-items-center' onClick={() => { setisOpenModalcostos(true); }}>Registrar costos</fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </Form>

                            </div>

                        </section>
                    </div>
                    {
                        isOpenModalcostos &&
                        <Modal isOpen={isOpenModalcostos} color='#fff'>
                            <ModalCostos
                                setisOpenModalcostos={setisOpenModalcostos}
                                isOpen={isOpenModalcostos}
                                setValue={setInitialValue}
                                value={values}
                                costoProp={costoPropietarioValue}
                            />
                        </Modal>
                    }
                    {
                        isOpenModalconfirm &&
                        <ModalConfirmAlta
                            isOpen={isOpenModalconfirm}
                            setIsOpen={setIsOpenModalconfirm}
                            item={values}
                            handleGetAll={() => { handleAddEdit(values) }}
                        />
                    }

                </>
            )
            }
        </Formik>
    )
}

export default VehiculoAlta