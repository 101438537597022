import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 1
}
export const NavigaSliceTblSecundaria = createSlice({
  name: 'navigateTblSecundaria',
  initialState,
  reducers: {
    tabActiveTblSecundaria: (state, action) => {
      return { ...state, value: parseInt(action.payload) }
    }   
  },
})

export const { tabActiveTblSecundaria } = NavigaSliceTblSecundaria.actions

export default NavigaSliceTblSecundaria.reducer