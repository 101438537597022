import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { getDateFormat, handleUnchekedColumns, message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../../../Components/datatable/DataTable';
//import Checkbox from '../../../Components/checkBox/CheckBox';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import AgregarAdeudoModal from '../../../Components/Modal/AgregarAdeudoModal';
import { set } from 'date-fns';
import AgregarPagoModal from '../../../Components/Modal/AgregarPagoModal';
import DataTableCheck from '../../../Components/datatable/DataTableCheck';
import UnidadDetenidaCard from './UnidadDetenidaCard';
import dataDocsServices from '../../../Services/DataDocs/dataDocsServices';
import AuthLogin from '../../../Utils/AuthLogin';
import axios from 'axios';
import VisorFile from '../../../Components/VisorFile';
import Garantias from '../../../Services/Cartera/Garantias';
import documentsHelper from '../../../Utils/documents';

const auth = new AuthLogin();
const PagoTramitesCard
    = React.forwardRef(({ ...props }, ref) => {
        let url = auth.UrlDataDocs();
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, generalid, siniestroid } = useParams();
        const navigate = useNavigate();
        let { state } = props;
        let userName = auth.getNombreUsuario();
        const { fechaEntrega,
            nombreYear,
            vin,
            color,
            nombreMarca,
            kilometraje,
            nombreModelo,
            procedenciaAuto,
            nombreVersion,
            numContrato,
            clienteAcendes } = useSelector((state) => state.DataVinSlice);


        let initial = {
            siniestroPagoTramitesId: null,
            siniestroId: null,
            siniestroPagoTramitesStatusId: null,
            pagoDeducible: null,
            aplicaFinanciamiento: null,
            montoFinanciamiento: null,
            fechaAlta: "",
            fechaActualiza: null,
            usuarioAlta: null,
            usuarioActualiza: null,
            status: null,
            concluido: null,
            fechaConclusion: "",
            siniestroPagoTramitesStatus: "Pendiente",
            color: "default",
            proceso: null,
            usuarioAltaNombre: null,
            usuarioActualizaNombre: null,
            pagos: [],
            adeudos: []
        };

        const [initialValue, setInitialValue] = useState(initial);
        const [totalAdeudos, setTotalAdeudos] = useState(0);
        const [totalPagos, setTotalPagos] = useState(0);
        const [data, setData] = useState([]);
        const [montoFinanciar, setMontoFinanciar] = useState(0);
        const [idAdeudo, setidAdeudo] = useState("");
        const [pagosData, setPagosData] = useState([]);
        const [adeudosData, setAdeudosData] = useState([]);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado", idAdeudo: '' });
        const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];
        const [disabled, setDisabled] = useState(false);
        const [selectedCheckbox, setSelectedCheckbox] = useState(null);
        const [selectedItem, setSelectedItem] = useState();
        const [isOpenModalAdeudo, setIsOpenModalAdeudo] = useState(false);
        // ver doc
        const [fileName, setFileName] = useState("");
        const [isOpenDocs, setIsOpenDocs] = useState(false)
        const [SelectedDocs, setSelectedDocs] = useState([]);

        const columnsAdeudos = [
            { field: 'orden', headerName: 'No. Orden' },
            { field: 'usuarioEjecutivoNombre', headerName: 'Ejecutivo' },
            { field: 'tipoAdeudo', headerName: 'Adeudo' },
            { field: 'fechaAdeudo', headerName: 'Fecha de adeudo' },
            { field: 'subtotal', headerName: 'Subtotal' },
            { field: 'iva', headerName: 'IVA' },
            { field: 'total', headerName: 'Total' },
            { field: 'fechaAlta', headerName: 'Fecha del registro' }
        ];


        const columnsPagos = [

            { field: 'folio', headerName: 'Folio de pago' },
            { field: 'fechaAlta', headerName: 'Fecha y hora' },
            { field: 'usuarioEjecutivoNombre', headerName: 'Ejecutivo' },
            { field: 'nota', headerName: 'Notas' },
            { field: 'tipoPago', headerName: 'Método de pago' },
            { field: 'monto', headerName: 'Monto' },
            { field: 'descarga', headerName: 'Recibo', width: 80 },
            { field: 'verEvidencia', headerName: 'ver Evidencia', width: 120 }
        ];


        useEffect(() => {
            getPagoTramites();
        }, []);


        /* CONSULTAS */
        const getPagoTramites = async () => {
            setLoading(true);

            await Siniestros.siniestroPagoTramitesGet(siniestroid)
                .then((response) => {

                    let pagos = [];
                    let adeudos = [];
                    let totalPagos = 0;
                    let totalAdeudos = 0;
                    // response.data.pagos.sort((a, b) => {
                    //     return new Date(b.fechaAlta) - new Date(a.fechaAlta);
                    // });

                    // response.data.adeudos.sort((a, b) => {
                    //     return new Date(b.fechaAlta) - new Date(a.fechaAlta);
                    // });

                    //Agregar una columna autonumèrica en data.pagos con el nombre de 'folio'
                    response.data.pagos.map((item, index) => {
                        // item.folio = index + 1;
                        totalPagos += item.monto;
                        pagos.push(item);
                    });
                    //Agregar una columna autonumèrica en data.adeudos con el nombre de 'orden'
                    response.data.adeudos.map((item, index) => {
                        item.orden = item.folio
                        item.total = item.subtotal + item.iva;
                        totalAdeudos += item.total;
                        adeudos.push(item);
                    });

                    //Actualizar los valores a formato moneda en data.pagos
                    response.data.pagos.map((item) => {
                        item.monto = formatMoney(item.monto);
                        item.fechaAlta = getDateFormat(item.fechaAlta,'');
                    });

                    //Actualizar los valores a formato moneda en data.adeudos
                    response.data.adeudos.map((item) => {
                        item.subtotal = formatMoney(item.subtotal);
                        item.iva = formatMoney(item.iva);
                        item.total = formatMoney(item.total);
                        item.fechaAlta = dateOnlyFormat(item.fechaAlta);
                        item.fechaAdeudo = getDateFormat(item.fechaAdeudo, "");
                    });

                    setTotalAdeudos(totalAdeudos);
                    setTotalPagos(totalPagos);
                    setMontoFinanciar(totalAdeudos - totalPagos);
                    response.data.sol_id = response.data?.sol_id ?? "";
                    response.data.Contrato = numContrato;
                    response.data.vin = vin;
                    response.data.aplicaFinanciamiento = response.data.aplicaFinanciamiento ?? "";

                    response.data.fechaAlta = response.data.fechaAlta?.split("T")[0];
                    response.data.fechaActualiza = response.data.fechaActualiza?.split("T")[0];
                    response.data.fechaInicio = response.data.fechaInicio?.split("T")[0];
                    response.data.fechaFin = response.data.fechaFin?.split("T")[0];

                    setInitialValue(response.data); //3=valuacion , 4= PerdidaTotal , 5= Reparacion  
                    
                    console.log(pagos,"////////////////")
                    // pagos.map((item) => {
                    //     item.fechaAlta = getDateFormat(item.fechaAlta,'');
                    // });

                    setPagosData(pagos);
                    setAdeudosData(adeudos);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => setLoading(false));

        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const handleChekedColumns = (cheked, value, i) => {
            if (cheked) {
                setSelectedItem(value);
                let table = document.querySelectorAll('#tablaPagos > .tbl-custome > tr');

                for (let index = 0; index < table.length; index++) {
                    const element = table[index];
                    if (index !== i)
                        element.firstChild.firstChild.checked = false;
                }
            } else {
                setSelectedItem();
            }
        }

        const handleEdit = (item) => {
            setidAdeudo(item.siniestroPagoTramitesAdeudoId);
            setModalMessage({ isOpen: false, title: 'Registrar Adeudo', type: 5, idAdeudo: item.siniestroPagoTramitesAdeudoId })
            setIsOpenModalAdeudo(true)
        }
        const handleDelete = (item) => {
            Siniestros.siniestroPagoTramitesAdeudoDelete(item.siniestroPagoTramitesAdeudoId)
                .then(resp => {
                    if (resp.data.data.ban === 1) {
                        toast.success(message("¡Correcto!", resp.data.data.mensaje));
                        getPagoTramites();
                    } else {
                        toast.error(message("¡Operación fallida!", resp.data.data.mensaje));
                    }

                }).catch(error => {
                    toast.error(message("¡Operación fallida!", "Error al agregar un adeudo"));
                    console.log(error)
                })
        }
        const dateFullFormat = (d) => {
            let dateBD = new Date(d + "Z");
            let formattedDate = ("0" + dateBD.getDate()).slice(-2) + "-" + ("0" + dateBD.getMonth()).slice(-2) + "-" + dateBD.getFullYear() + " " + ("0" + dateBD.getHours()).slice(-2) + ":" + ("0" + dateBD.getMinutes()).slice(-2) + " hrs.";
            return formattedDate;
        }

        const dateOnlyFormat = (d) => {
            let dateBD = new Date(d + "Z");
            let formattedDate = ("0" + dateBD.getDate()).slice(-2) + "-" + ("0" + dateBD.getMonth()).slice(-2) + "-" + dateBD.getFullYear(); //+" "+("0"+dateBD.getHours()).slice(-2)+":"+("0"+dateBD.getMinutes()).slice(-2)+" hrs.";
            return formattedDate;
        }

        // const getDateFormat = (fecha, msj) => {
        //     let d = new Date(fecha + "Z");
        //     let format = "";
        //     if (fecha !== undefined && fecha !== "") {
        //         format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        //         return msj + format;
        //     }
        // }

        const submitForm = async (values) => {
            if (values.concluido) {
                if (!values.aplicaFinanciamiento) {
                    if (montoFinanciar > 0) {
                        toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Adeudos pendientes por pagar.")}</div>);
                        return;
                    }
                }
                if (initialValue.valuacionConcluida && initialValue.codEstatus === 5) {
                    if (values?.adeudos.filter(x => x.tipoAdeudo === 'Deducible')) {
                        toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Falta el registro Deducible en la tabla de adeudos.")}</div>);
                        return;
                    }

                }
            }
            if (values.concluido) {
                values.siniestroPagoTramitesStatusId = 30;
                // crearUnidadDetenida();
            }

            setLoading(true);
            await Siniestros.siniestroPagoTramitesActualizar(values)
                .then(resp => {
                    toast.success(message("Se actualizó el registro de pago de trámites correctamente"));
                    getPagoTramites()
                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error, al intentar actualizar el registro de pago de trámites de unidad!"));
                })
                .finally(() => {



                    if (values.concluido) {
                        // crearUnidadDetenida();
                    }
                    setLoading(false);
                });
        }

        const crearUnidadDetenida = async () => {
            let param = {
                generalId: generalid,
                siniestroUnidadDetenidaStatusId: 10,
                siniestroId: siniestroid
            };

            await Siniestros.siniestroUnidadDetenidaCrear(param);
        }


        const validate = Yup.object({
            aplicaFinanciamiento: Yup.string()
                .when(["concluido", "codEstatus"], (concluido, codEstatus) => {
                    if (concluido && codEstatus === 4) {
                        return Yup.string().test({
                            name: 'is-sku',
                            skipAbsent: true,
                            test(value, ctx) {
                                if (value === undefined)
                                    return ctx.createError({ message: 'El campo es requerido' })
                                return true
                            }
                        })
                    }

                }),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };
        const handleGetTokenDatadocs = async () => {
            await dataDocsServices.UpdateTokenDataDc()
                .then(res => {
                    auth.handleAuthSesionDataDocs(res.data.data);
                }).catch(e => { })
        }
        const handleViewdocs = async (item) => {
            if (!item.subido) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", `No se a subido ningun documento para el folio: ${item.folio}`)}</div>);
                return;
            }
            setLoading(true);
            setFileName(item.nombreDocumento);
            await handleGetTokenDatadocs();
            await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + auth.tokenDataDocs(),
                },
                responseType: "blob"
            })
                .then(res => {
                    var file = new File([res.data], res.data.name, { type: res.data.type });
                    setSelectedDocs([file])
                    setLoading(false);
                    setIsOpenDocs(true)
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("Error.!", e.response.data.errors?.error));
                    else
                        toast.error(message("Error.!", e.message));
                })
        }
        const formatMoney = (value) => {
            if (value === undefined) {
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }
        const handleUploadFile = () => {
            if (selectedItem === null || selectedItem === undefined) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Selecciona un pago.")}</div>);
                return;
            }
            navigate(`/seguro/siniestros/datadocs/fSiniestro/eSiniestro/${state?.folio}/${siniestroid}`, {
                state: { ...selectedItem, card: true, vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }
            });
        }
        const handleDownload = async (data) => {
            console.log("dwn", data)
            const requestBody = {
                nombrePlantilla: "Recibo",
                parametros: {
                    "<<VARIABLE_FECHA>>": data.fechaAlta ? data.fechaAlta.split(" ")[0] : "",
                    "<<VARIABLE_NOMBRE>>": clienteAcendes,
                    "<<VARIABLE_NOMBRE_USUARIO>>": userName,
                    "<<VARIABLE_CONTRATO>": numContrato,
                    "<<VARIABLE_CANTIDAD>>": data.monto,
                    "<<VARIABLE_CONCEPTO>>": data.nota,
                    "<<VARIABLE_TELEFONO>>": "",
                    "<<VARIABLE_DIRECCION_UBICACION>>": "",
                }
            };

            await Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                //Pendiente de revisar por que no jala con el file-server                
                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
            }).catch(err => {
                console.error(err);
            });
        };
        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 6 &&
                        <AgregarPagoModal
                            modalMessage={modalMessage}
                            getPagoTramites={getPagoTramites}
                            setModalMessage={setModalMessage}
                            siniestroPagosData={initialValue}
                        />
                    }
                </Modal>
                {
                    isOpenModalAdeudo &&
                    <AgregarAdeudoModal
                        modalMessage={modalMessage}
                        getPagoTramites={getPagoTramites}
                        setModalMessage={setModalMessage}
                        siniestroPagosData={initialValue}
                        idAdeudo={idAdeudo}
                        setIsOpenModalAdeudo={setIsOpenModalAdeudo}
                        isOpenModalAdeudo={isOpenModalAdeudo}
                    />
                }
                {
                    isOpenDocs &&
                    <VisorFile
                        isOpen={isOpenDocs}
                        setIsOIpen={setIsOpenDocs}
                        fileName={fileName}
                        doc={SelectedDocs}
                    />
                }
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapsePagoTramites" role="button" aria-expanded="false" aria-controls="collapsePagoTramites" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Pago de trámites</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.siniestroPagoTramitesStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapsePagoTramites">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                        onSubmit={(values) => {
                                            submitForm({ ...values, MontoFinanciamiento: montoFinanciar });
                                        }}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <Form>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-12">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        disabled={initialValue.siniestroPagoTramitesStatusId === 30}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-auto">
                                                            <small><strong>Adeudos</strong></small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <fieldset
                                                                className='btn'
                                                                disabled={!initialValue.siniestroPagoTramitesId || initialValue.siniestroPagoTramitesStatusId === 30}
                                                                onClick={(event) => {
                                                                    setidAdeudo("");
                                                                    setModalMessage({ isOpen: false, title: 'Registrar Adeudo', type: 5, idAdeudo: "" })
                                                                    setIsOpenModalAdeudo(true)
                                                                }}>
                                                                Registrar adeudo
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-12">
                                                            <DataTable
                                                                name="adeudos"
                                                                column={columnsAdeudos}
                                                                data={adeudosData}
                                                                editable={true}
                                                                handleEdit={handleEdit}
                                                                deleteable={true}
                                                                handleDelete={handleDelete}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2 justify-content-end">
                                                        <div className="col-auto">
                                                            <small><strong>Total: {formatMoney(totalAdeudos)}</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-auto">
                                                            <small><strong>Pagos cliente</strong></small>
                                                        </div>
                                                        <div className="col-auto">
                                                            <fieldset
                                                                className='btn'
                                                                disabled={!initialValue.siniestroPagoTramitesId || initialValue.siniestroPagoTramitesStatusId === 30}
                                                                onClick={(event) => {
                                                                    setModalMessage({ isOpen: true, title: 'Registrar Pago', type: 6 })

                                                                }}>
                                                                Registrar pago
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-auto">
                                                            <fieldset
                                                                className='btn btn-gray'
                                                                onClick={handleUploadFile}
                                                                disabled={!selectedItem}
                                                            // disabled={!initialValue.siniestroPagoTramitesId || initialValue.siniestroPagoTramitesStatusId === 30}
                                                            >
                                                                <i className="icon-light fa fa-file "></i> Subir documento
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-12">
                                                            <DataTable
                                                                id="tablaPagos"
                                                                name="pagos"
                                                                column={columnsPagos}
                                                                data={pagosData}
                                                                checkbox={true}
                                                                handleCheck={handleChekedColumns}
                                                                // downloable={true}
                                                                handleDownload={handleDownload}
                                                                handleDocuments={handleViewdocs}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2 justify-content-end">
                                                        <div className="col-auto">
                                                            <small><strong>Total: {formatMoney(totalPagos)}</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-auto">
                                                            <small><strong>Balance</strong></small>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4 px-4">
                                                        <div className="col-3">
                                                            <table className="table" style={{ "border": "solid thin", "borderColor": "#E0E0E0" }}>
                                                                <thead style={{ "backgroundColor": "#E0E0E0" }}>
                                                                    <tr>
                                                                        <th scope="col">Concepto</th>
                                                                        <th scope="col">Monto</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Adeudos</th>
                                                                        <td>{formatMoney(totalAdeudos)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Pagos cliente</th>
                                                                        <td>{formatMoney(totalPagos)}</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot style={{ "backgroundColor": "#E0E0E0" }}>
                                                                    <tr>
                                                                        <th scope="col">Pendiente</th>
                                                                        <th scope="col">{formatMoney(montoFinanciar)}</th>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {
                                                        initialValue.valuacionConcluida &&
                                                        <>
                                                            <div className="row mt-2 px-4 mb-2">
                                                                <div className="col-auto">
                                                                    <small><strong>Cierre de proceso</strong></small>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                {
                                                                    (initialValue?.codEstatus === 5) &&
                                                                    <div className="col-12 col-lg-3">
                                                                        <SelectField id="pagoDeducible"
                                                                            label="¿Cliente pago deducible?"
                                                                            disabled={initialValue?.concluido}
                                                                            name="pagoDeducible"
                                                                            items={opcionesBool}
                                                                            onChange={(event) => {
                                                                                setFieldValue("pagoDeducible", event.value);
                                                                            }} />
                                                                    </div>
                                                                }

                                                                <div className="col-12 col-lg-3">
                                                                    <SelectField id="aplicaFinanciamiento"
                                                                        label="Aplica financiamiento"
                                                                        disabled={initialValue?.concluido}
                                                                        name="aplicaFinanciamiento"
                                                                        items={opcionesBool}
                                                                        onChange={(event) => {
                                                                            setFieldValue("aplicaFinanciamiento", event.value);
                                                                        }} />
                                                                </div>
                                                                <div className="col-12 col-lg-3">
                                                                    <TextField
                                                                        id="montoFinanciamiento"
                                                                        disabled={initialValue?.concluido}
                                                                        label="Monto a financiar"
                                                                        holder=""
                                                                        name="montoFinanciamiento"
                                                                        type="text"
                                                                        value={formatMoney(montoFinanciar)}
                                                                    // onChange={(event) => {
                                                                    //     setFieldValue("montoFinanciar", event.target.value);
                                                                    // }} 
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                                        setFieldValue("concluido", event.target.value);
                                                                    }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Pago de unidad concluido</label>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="row justify-content-end">
                                                                        <div className="col-auto">
                                                                            {
                                                                                initialValue.fechaAlta !== "" &&
                                                                                <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                            }
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            {
                                                                                initialValue.concluido &&
                                                                                <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </Form>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default PagoTramitesCard