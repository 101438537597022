import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useDispatch, useSelector } from 'react-redux';
import { tabActivePrealta } from '../../../Redux/Slice/navigateSlicePrealta';
import DataTable from '../../../Components/datatable/DataTable';
import { TextAreaField } from '../../../Components/TextAreaField';
import SubMenuPrealta from '../Prealta/subMenuPrealta';
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { AccionesRapidas, Section, SubModule } from '../../../Auth/Authorization';

const VehiculoCambioUbicacion = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    let { id } = useParams();
    const { item } = useSelector((state) => state.quickActionsSlice);
    const dispatch = useDispatch();
    const refFormik = useRef();
    let initial = {
        VIN: item?.vin,
        marca: item?.nombreMarca,
        modelo: item?.nombreModelo,
        year: item?.nombreYear,
        version: item?.nombreVersion,
        condicion: item?.tipoCondicion,
        transmision: item?.transmision ?? "",

        generalId: id,
        ubicacionOrigenId: "",
        ubicacionDestinoId: "",
        motivoCambio: "",
        comentarios: "",
        statusDatamovil: ""
    }
    const columns = [
        { field: 'nombreUbicacionOrigen', headerName: 'Ubicación Oriden', width: 200 },
        { field: 'nombreUbicacionDestino', headerName: 'Ubicación Destino', width: 200 },
        { field: 'fecha', headerName: 'Fecha y Hora', width: 240 },
        { field: 'motivoCambio', headerName: 'Motivo', width: 230 },
        { field: 'comentarios', headerName: 'Comentario', width: 130 },
        { field: 'nombreUsuario', headerName: 'Usuario', width: 130 }
    ];
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        ubicacionOrigenId: Yup.string()
            .required("El campo es requerido."),
        ubicacionDestinoId: Yup.string()
            .required("El campo es requerido."),
        motivoCambio: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
    });

    const [dataStatus, setDataStatus] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [progressBar, setProgressBar] = useState(0);
    const [dataModal, setDataModal] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [tipoUbicacion, setTipoUbicacion] = useState([]);
    const [statusDatamovil, setStatusDatamovil] = useState("");
    const [Motivoubicacion, setMotivoubicacion] = useState([]);

    // let statusDatamovil = "";
    useEffect(() => {
        handleGetById();
        handleGetByIdStatus()
        handleGetTipoUbicacion();
        handleGetAllMotivoUbicacion()
        handleChageProgress(refFormik.current.errors);
    }, [])

    const handleGetById = async () => {
        setLoading(true);
        await VentaVehiculoService.getlAllVehiculoAltaById(id)
            .then(res => {
                let itemx = res.data.data;
                let initial = {
                    VIN: item?.vin,
                    marca: item?.nombreMarca,
                    modelo: item?.nombreModelo,
                    year: item?.nombreYear,
                    version: item?.nombreVersion,
                    condicion: item?.tipoCondicion,
                    transmision: item?.transmision ?? "",

                    generalId: id,
                    ubicacionOrigenId: itemx?.ubicacionId ?? "",
                    ubicacionDestinoId: "",
                    motivoCambio: "",
                    comentarios: ""
                }
                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetByIdStatus = async () => {
        // setLoading(true);
        await VentaVehiculoService.getStatusDatamovil(id)
            .then(res => {
                let item = res.data.data;

                // statusDatamovil = item.statusDatamovil;
                setStatusDatamovil(item.statusDatamovil);
                // setInitialValue({ ...initialValue, statusDatamovil: item?.statusDatamovil ?? "" });
                // setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }

    const handleGetAllStatusDatamovil = async () => {
        await VehiculoService.getAllStatusDatamovil()
            .then(res => {
                let NewData = [];

                res.data.data?.map(item => {
                    if (item.status) {
                        NewData.push({
                            value: item.statusDatamovilId,
                            label: item.statusDatamovil
                        })
                    }

                });
                setDataStatus(NewData);
            }).catch(e => {
            })
    }
    const handleDataHistory = async (page) => {
        setLoading(true);
        await VehiculoService.getAllHistoryCambioUbicacion(id, page, 10)
            .then(res => {

                setDataModal(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleDataHistory(event.selected + 1);
    };
    const handleGetTipoUbicacion = async () => {
        setLoading(true)
        await Catalogo.getdataTipoUbicacion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.ubicacion
                    })
                });
                setTipoUbicacion(NewData);
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
    }
    const handleAddEdit = async (value) => {
        setLoading(true);
        await VentaVehiculoService.addCambioUbicacion(value)
            .then(res => {
                toast.success(message("¡Correcto.!", res.data.data.mensaje));
                handleGetById();
                setLoading(false);
                setTimeout(() => {
                    navigate("/inventario/vehiculo");
                }, 2000);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("¡Error.!", e.response.data.message));
                else
                    toast.error(message("¡Error.!", e.message));
            })
    }
    const handleGetAllMotivoUbicacion = async () => {
        await Catalogo.getdataTabla("MotivoUbicacion", 1, 9999)
            .then(res => {

                let NewData = [];
                res.data.data?.map(item => {
                    if (item?.status === true) {
                        NewData.push({
                            value: item.motivoUbicacionId,
                            label: item.motivoUbicacion,
                        })
                    }
                });
                setMotivoubicacion(NewData);
            }).catch(e => {
            })
    }
    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;

        let totalCampos = 4;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);

    }
    const message = (title, message) => <p style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></p>
    const status = (dataStatus) => {
        if (dataStatus?.toLowerCase() === "contrato") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'green' }}></span>
        }
        else if (dataStatus?.toLowerCase() == "demo") {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'orange' }}></span>
        } else {
            return <span className='status-icon mx-1' style={{ backgroundColor: 'red' }}></span>
        }

    }
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);

            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    {/* <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate('/inventario/vehiculo') }}></i>
                        Regresar a Listado general de Vehículo
                    </header> */}
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        {/* <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />                       */}
                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                <Form>
                                    <section className='d-flex justify-content-between py-2'>
                                        <div className='d-flex'>
                                            <span className='fs-5 mx-2'><strong>Información del vehiculo</strong></span>
                                            <fieldset type="button" className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status(statusDatamovil)}{statusDatamovil === "" ? "Sin dispositivo" : statusDatamovil}</fieldset>
                                        </div>
                                        <ValidatePermission isActive={validateManyPermissions(["Agregar", "Actualizar"], ValidatePermissionById(4, Section.CambioDeUbicacionesSection, AccionesRapidas.CambioDeUbicaciones))}>
                                            <div className='d-flex'>
                                                {/* <fieldset type="button" className='btn btn-light secondary d-flex align-items-center' data-bs-toggle="tooltip" data-bs-placement="top" title="Estatus datamovil"> {status(values.statusDatamovil)}{values.statusDatamovil === "" ? "Sin dispositivo" : values.statusDatamovil}</fieldset> */}
                                                <button className='btn mx-2' type="submit" >{"Guardar"}</button>
                                            </div>
                                        </ValidatePermission>

                                        {/* disabled={!(isValid)} */}
                                    </section>
                                    <div className='my-2 mt-4'>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="VIN:" name="VIN" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Marca:" name="marca" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Modelo:" name="modelo" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Año:" name="year" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Versión:" name="version" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Condición:" name="condicion" type="text" holder="Escribe" />
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <TextField disabled label="Transmisión:" name="transmision" type="text" holder="Escribe" />
                                            </div>
                                        </div>
                                    </div>
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.CambioDeUbicacionesSection, AccionesRapidas.CambioDeUbicaciones).Ver}>
                                        <div className='my-2 mt-4'>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span> <strong>Ubicación</strong></span>
                                                <ValidatePermission isActive={ValidatePermissionById(4, Section.CambioDeUbicacionesSection, AccionesRapidas.CambioDeUbicaciones).Historial}>
                                                    <div className="col-sm-1 mx-2">
                                                        <fieldset className='btn btn-light secondary d-flex align-items-center' style={{ width: '40px' }} onClick={() => { setIsOpenModal(true); handleDataHistory(currenPage) }} ><i className='ri-timer-fill'></i></fieldset>
                                                    </div>
                                                </ValidatePermission>
                                            </div>

                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField disabled label="Ubicación Actual:" name="ubicacionOrigenId" items={tipoUbicacion} onChange={(event) => {
                                                        setFieldValue("ubicacionOrigenId", event.value);
                                                        handleChageProgress(errors)
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Hacía dónde va:" name="ubicacionDestinoId" items={tipoUbicacion} onChange={(event) => {
                                                        setFieldValue("ubicacionDestinoId", event.value);
                                                        handleChageProgress(errors)
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    {/* <TextField label="Motivo de cambio:" name="motivoCambio" type="text" holder="Escribe" onKeyUp={(event) => {
                                                    setFieldValue("motivoCambio", event.target.value);
                                                    handleChageProgress(errors)
                                                }} /> */}
                                                    <SelectField label="Motivo de cambio::" name="motivoCambio" items={Motivoubicacion} onChange={(event) => {
                                                        setFieldValue("motivoCambio", event.value);
                                                        handleChageProgress(errors)
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    {/* <TextAreaField label="Motivo de cambio:" name="serieDatamovil" type="number" holder="Escribe" onChange={(event) => {
                                                    setFieldValue("serieDatamovil", event.target.value);
                                                    handleChageProgress(errors)
                                                }} /> */}
                                                    <TextAreaField label="Comentario" className="form-control col-12" rows="2" name="comentarios" type="text" holder="Escribe" onKeyUp={(event) => {
                                                        setFieldValue("comentarios", event.target.value);
                                                        handleChageProgress(errors)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </ValidatePermission>
                                </Form>

                            </div>

                        </section>
                    </div>
                    <Modal isOpen={isOpenModal} color='#fff'>
                        <div className='d-flex justify-content-center'>
                            <span><strong>Historico de ubicaciónes vehiculo: {dataModal[0]?.nombreVehiculo}</strong></span>

                        </div>
                        <DataTable
                            column={columns}
                            data={dataModal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                            currentPage={currenPage - 1}
                        />
                        <section className='my-3 d-flex justify-content-end mt-4'>
                            <fieldset className='btn btn-outline mx-1 px-5' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                        </section>
                    </Modal>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                </>
            )
            }
        </Formik>
    )
}

export default VehiculoCambioUbicacion