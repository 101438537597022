import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { TextAreaField } from '../TextAreaField';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { message, pageRows } from '../../Utils/functions';
import { useParams } from 'react-router-dom';
import Siniestros from '../../Services/Siniestro/Siniestro';



const AgregarAdeudoModal = ({ modalMessage, getPagoTramites, setModalMessage, siniestroPagosData, idAdeudo, setIsOpenModalAdeudo, isOpenModalAdeudo }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const { id, generalid, siniestroid } = useParams();
    const dispatch = useDispatch();
    const inicial = {
        siniestroPagoTramitesAdeudoId: modalMessage.idAdeudo ?? "",
        siniestroPagoTramitesId: siniestroPagosData.siniestroPagoTramitesId ?? "",
        tipoAdeudoId: "",
        fechaAdeudo: new Date(),
        subtotal: "",
        iva: "",
        tipoAdeudo: "",

    };

    const [initialValue, setInitialValue] = useState(inicial);
    const [dataTipoCosto, setDataTipoCosto] = useState([]);
    //const {siniestroPagoTramitesId} = useSelector((state) => state.SiniestroPagoTramitesSlice);

    useEffect(() => {
        getTipoCosto();
        if (idAdeudo)
            getAdeudo();
    }, []);

    const getAdeudo = async () => {
        setLoading(true);
        await Siniestros.siniestroPagoTramitesAdeudoGet(idAdeudo)
            .then(resp => {
                setInitialValue(resp.data);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));

    };

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const validate = Yup.object().shape({
        subtotal: Yup.number()
            .positive('El valor debe ser positivo')
            .typeError('Debe ser un número')
            .test('decimal-places', 'Máximo 2 decimales', value => {
                if (value) {
                    const decimalPlaces = value.toString().split('.')[1];
                    return !decimalPlaces || decimalPlaces.length <= 2;
                }
                return true;
            })
            .required('Campo requerido'),
        // iva: Yup.number()
        //     .positive('El valor debe ser positivo')
        //     .typeError('Debe ser un número')
        //     .required('Campo requerido'),
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'ingrese un valor positivo ' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99.99' })
                return true
            }
        }),
        tipoAdeudoId: Yup.string()
            .required("Requerido.")
    });



    /* CATALOGOS */
    const getTipoCosto = async () => {
        await Catalogo.getdataTipocosto("Adeudo")
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.tipoCostoId,
                        label: item.nombre
                    })
                    // }
                });
                setDataTipoCosto(NewData);
            }).catch(e => {
            })
    }
    /* TERMINA CATALOGOS */

    const handlerGuardar = (values) => {
        console.log(values)
        if (values.siniestroPagoTramitesAdeudoId === "") {
            Siniestros.siniestroPagoTramitesAdeudoCrear(values).then(resp => {
                console.log(resp)
                toast.success(message("¡Operación exitosa!", "Adeudo registrado correctamente"));
                getPagoTramites();
                setIsOpenModalAdeudo(false);
            }).catch(error => {
                toast.error(message("¡Operación fallida!", "Error al agregar un adeudo"));
                console.log(error)
            })
        } else {
            Siniestros.siniestroPagoTramitesAdeudoActualizar(values).then(resp => {
                console.log(resp)
                toast.success(message("¡Operación exitosa!", "Adeudo Actualizado correctamente"));
                getPagoTramites();
                setIsOpenModalAdeudo(false);
            }).catch(error => {
                toast.error(message("¡Operación fallida!", "Error al actualizado un adeudo"));
                console.log(error)
            })
        }

    }

    return (
        <>

            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    handlerGuardar(values);
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <>
                        <Modal isOpen={isOpenModalAdeudo} color='#fff'>
                            <Form>

                                <section className='d-flex justify-content-end'>
                                    <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => setIsOpenModalAdeudo(false)}><i className="ri-close-fill"></i></button>
                                </section>
                                <div className='justify-content-start p-2 align-items-center'>
                                    <div className="row">
                                        <div className="col col-12">
                                            <h5><strong>{modalMessage.title}</strong></h5>
                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    A continuación completa todos los campos para el registro solicitado
                                                </small>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-lg-6 col-12">
                                                    <SelectField id="tipoAdeudoIds"
                                                        label="Tipo de adeudo"
                                                        disabled={false}
                                                        name="tipoAdeudoId"
                                                        items={dataTipoCosto}
                                                        onChange={(event) => {
                                                            setFieldValue("tipoAdeudoId", event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-lg-6 col-12">
                                                    <TextField
                                                        id="subtotal"
                                                        disabled={false}
                                                        label="Subtotal($)"
                                                        name="subtotal"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("subtotal", event.target.value);
                                                        }} />
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <TextField
                                                        id="iva"
                                                        label="I.V.A. (%)"
                                                        name="iva"
                                                        type="text"
                                                        holder="Escribe"
                                                        onChange={(event) => {
                                                            setFieldValue("iva", event.target.value);

                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <section className='d-flex justify-content-end'>
                                        <div className="row">
                                            <div className="col-auto">
                                                <button
                                                    type='reset'
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-outline d-flex justify-content-center align-items-center'
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    onClick={() => {
                                                        setIsOpenModalAdeudo(false)
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    id="AT_BtnCloseModal"
                                                    className='btn btn-blue d-flex justify-content-center align-items-center'
                                                    disabled={!(isValid)}
                                                    style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                    type="submit"
                                                >
                                                    Agregar
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Form>

                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                        }
                    </>
                )}
            </Formik>
        </>
    );
}

export default AgregarAdeudoModal;
