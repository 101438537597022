import React, { useEffect, useRef, useState } from 'react'
import useLlamada from '../../Hooks/Incidencias/useLlamada';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import { NavLink } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import InformacionLlamada from './IncidenciasInfo/CardLlamada';
import { useDispatch, useSelector } from 'react-redux'
import { toast, Toaster } from 'react-hot-toast';
import Llamada from '../../Services/Cartera/Llamadas';
import { setInfoVin } from '../../Redux/Slice/IncidenciasSlice/dataVinSlice';

const IncidenciasInfo_ = ({ inInspeccionRapida = false }) => {

    const { serieDatamovil, generalId, transmision, fechaEntrega, nombreYear, vin, color, nombreMarca, kilometraje, nombreModelo, 
        procedenciaAuto, nombreVersion, numMotor, numContrato, numSolicitud, producto, cliente, usoUnidad, fechaUltimoPago, TallerAutorizado,FolioCarnet,nombre,fechaLiquidacion } = useSelector((state) => state.DataVinSlice);
    const { Url } = useSelector((state) => state.UrlSlice);
    const location = useLocation();
    const dispatch = useDispatch();

    const [dataAdeudo, setAdeudo] = useState(
        {
            "sol_id": "",
            "contrato": "",
            "vin": "",
            "saldo_credito": 0,
            "periodo": 0,
            "termino_contrato": "",
            "ultimo_pago": "",
            "status_contrato": "",
            "cliente": "",
            "fecha_ultima_clave": "",
            "vigencia_clave": "",
            "status": "",
            "message": ""
        }
    );

    let {
        loading
    } = useLlamada()

    const { id } = useParams();

    let UrlPath = Url;
    if (Url === location.pathname || Url.trim().length === 0) {
        UrlPath = '/garantias/incidencias';
    }

    useEffect(() => {
        if (numSolicitud !== "" && vin !== "" && numContrato !== "") {
            getAdeudoCredito();
        }

    }, [numSolicitud, vin, numContrato]);

    const getAdeudoCredito = async () => {

        // numeroSolicitud: numSolicitud,
        //     vin: vin,
        //     contrato: numContrato
        //'sol_id': '469', 'contrato': 'PGP0000366', 'vin': '1HGCM82694A123456',

        await Llamada.getAdeudoCredito({
            numeroSolicitud: numSolicitud,
            vin: vin,
            contrato: numContrato
        }).then(res => {
            let resp = res.data
            if (resp.result.status == 'proceso_correcto') {                
                setAdeudo({
                    "sol_id": resp.result.sol_id,
                    "contrato": resp.result.contrato,
                    "vin": resp.result.vin,
                    "saldo_credito": resp.result.saldo_credito,
                    "periodo": resp.result.periodo,
                    "termino_contrato": resp.result.termino_contrato,
                    "ultimo_pago": getDateFormat(resp.result.ultimo_pago),
                    "status_contrato": resp.result.status_contrato,
                    "cliente": resp.result.cliente,
                    "fecha_ultima_clave": resp.result.fecha_ultima_clave,
                    "vigencia_clave": resp.result.vigencia_clave,
                    "status": resp.result.status,
                    "message": resp.result.message,
                })

                dispatch(setInfoVin({
                    generalId: generalId,
                    fechaEntrega: fechaEntrega,
                    nombreYear: nombreYear,
                    vin: vin,
                    color: color,
                    nombreMarca: nombreMarca,
                    kilometraje: kilometraje,
                    nombreModelo: nombreModelo,
                    procedenciaAuto: procedenciaAuto,
                    nombreVersion: nombreVersion,
                    numMotor: numMotor,
                    numContrato: numContrato,
                    numSolicitud: numSolicitud,
                    producto: producto,
                    usoUnidad: usoUnidad,
                    fechaUltimoPago: fechaUltimoPago,
                    cliente: resp.result.cliente,
                    serieDatamovil: serieDatamovil,
                    transmision: transmision,
                    TallerAutorizado: TallerAutorizado,
                    FolioCarnet:FolioCarnet,
                    nombre:nombre,
                    fechaLiquidacionAcendes:fechaLiquidacion,
                }))

            }
        })
    }

    const getDateFormat = (fecha) => {
        if (fecha !== undefined) {
            if (fecha.includes('T')) {
                const d = new Date(fecha);
                const format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear();
                return format;
            } else {
                const d = new Date(fecha + "T00:00:00");
                const format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear();
                return format;
            }
        } else {
            return ""; // O manejar el caso de fecha indefinida según tus necesidades
        }
    }

    // Ejemplo de uso:


    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section >
                <div className='headerTab'>
                    <section className='mx-4 my-4 d-flex flex-column'>
                        <div className='row d-flex justify-content-between'>
                            <div className="col-6 mt-2 mr-1 pb-1">
                                {/* <NavLink to={`/garantias/incidencias/${id}`} className={'text-black'}> */}
                                <NavLink to={`${UrlPath}`} className={'text-black'}>
                                    <small><i className="glyphicon fas fa-angle-left"></i> Regresar al listado de incidencias</small>
                                </NavLink>
                            </div>
                            <div className="col-4 mt-2 mr-1 pb-1">
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <small>Última actualización por</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4'>
                        <div className="col-6 px-md-3">
                            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                                <div className="row px-4">
                                    <div className="col-12 underline-border">
                                        <h6><strong>Información del vehículo</strong></h6>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>Fecha de Entrega:</strong> {getDateFormat(fechaEntrega)}</small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>Año:</strong> {nombreYear}</small>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>VIN:</strong> {vin}</small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>Color:</strong> {color}</small>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>Marca:</strong> {nombreMarca}</small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>Kilometraje:</strong> {Math.floor(kilometraje)}</small>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>Modelo:</strong> {nombreModelo}</small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>Procedencia Auto:</strong> {procedenciaAuto}</small>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>Versión:</strong> {nombreVersion}</small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>No. Motor:</strong> {numMotor}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 px-md-3">
                            <div className="p-4 white-wrapper" style={{ height: '240px' }}>
                                <div className="row px-4">
                                    <div className="col-12 underline-border">
                                        <h6>
                                            <strong>Información del contrato</strong>
                                            <span className='mx-2 badge bg-success-dm'>Activo</span>
                                        </h6>

                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>No. de Contrato:</strong> {numContrato}</small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>Producto:</strong> {producto}</small>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>Cliente:</strong> {dataAdeudo.cliente} </small>
                                    </div>
                                    <div className="col-6">
                                        <small><strong>Uso unidad:</strong> {usoUnidad}</small>
                                    </div>
                                </div>
                                <div className="row mt-2 px-4">
                                    <div className="col-6">
                                        <small><strong>Fecha de último pago:</strong> {dataAdeudo?.ultimo_pago}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InformacionLlamada inInspeccionRapida={inInspeccionRapida} datageneralId={generalId} />
            </section>

        </>)
}

export default IncidenciasInfo_