import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { Formik, Field } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'

import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../../../Components/TextAreaField';

import Llamada from '../../../Services/Cartera/Llamadas';
import { EtapaBandera, message, pageRows } from '../../../Utils/functions'
import { setGruaSlice } from '../../../Redux/Slice/IncidenciasSlice/gruaSlice';
import { setSchedule } from '../../../Redux/Slice/calendarSlice';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import useLlamada from '../../../Hooks/Incidencias/useLlamada';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import ConfirmarModal from '../../../Components/Modal/ConfirmModal';
import DetailAuthCitaModal from '../../../Components/Modal/DetailAuthCitaModal';
import RechazaCitaModal from '../../../Components/Modal/RechazaCitaModal';
import CardResponsabilidadCliente from './CardResponsabilidadCliente';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Garantias from '../../../Services/Cartera/Garantias';
import CardRecepcion from './CardRecepcion';
import Garantia from '../../../Services/Garantias/Garantias';
import documentsHelper from '../../../Utils/documents';


const CardInspeccionRapida
    = (props) => {
        const { generalId } = props

        // const { fechaEntrega, nombreYear, vin, color, nombreMarca, kilometraje, nombreModelo, procedenciaAuto, nombreVersion, numMotor, numContrato, producto, cliente, usoUnidad, fechaUltimoPago } = useSelector((state) => state.DataVinSlice);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { FolioLlamada } = useSelector((state) => state.LlamadaSlice);
        const { FolioGarantia, GarantiaId, GeneralId } = useSelector((state) => state.GarantiaSlice);
        const { refFormik } = useRef();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        let { idllamada } = useParams();
        const [responsabilidadCliente, setRespabilidadCliente] = useState(false);
        const [recepcionUnidad, setRecepcionUnidad] = useState(false);
        const [inspeccionRapidaEstatus, setInspeccionRapidaEstatus] = useState('');
        const [etapaFinalizado, setEtapaFinalizado] = useState(false);
        const myRef = useRef();

        const {
            fechaEntrega,
            nombreYear,
            vin,
            color,
            nombreMarca,
            kilometraje,
            nombreModelo,
            procedenciaAuto,
            nombreVersion,
            numMotor,
            numContrato,
            producto,
            cliente,
            usoUnidad,
            fechaUltimoPago } = useSelector((state) => state.DataVinSlice);

        let initial = {
            GarantiaId: GarantiaId,
            folioInspeccion: 0,
            fecha: "",
            fechaAlta: "",
            fechaConclusion: "",
            hora: "",
            km: '',
            tipoReporteId: null,
            clasificacionFallaId: "",
            pruebaManejo: null,
            comentariosTecnico: "",
            tipoReporteTecnicoId: "",
            clasificacionFallaTecnicoId: null,
            tipoFallaTecnicoId: null,
            presentoFalla: null,
            responsablidadCliente: null,
            motivoResponsabilidadId: "",
            fallaRecurente: false,
            fallaRecurrenteTecnico: null,
            cambioDevolucion: false,
            fechaEntrega: "",
            horaEntrega: "",
            inspeccionConcluida: false,
            concluida: false,
            garantiaStatusInspeccionRapidaId: 0
        }

        const [disabled, setDisabled] = useState(true);
        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [TipoReportes, setTipoReportes] = useState([]);
        const [Clasificaciones, setClasificaciones] = useState([]);
        const [TipoFallas, setTipoFallas] = useState([]);
        const [recepcionStatus, setRecepcionStatus] = useState('');

        const downloadPDF = async () => {
            if (initialValue.folioInspeccion === 0) {
                setLoading(true);
                const initialSave = {};
                initialSave.GarantiaId = initial.GarantiaId;
                initialSave.GarantiaStatusInspeccionRapidaId = 20;
                await Garantias.addInspeccionRapida(initialSave)
                    .then(resp => {
                        const result = resp.data;
                        setDisabled(false);
                        setInitialValue({
                            GarantiaId: result.garantiaId,
                            folioInspeccion: result.folioInspeccion !== null ? result.folioInspeccion : 0,
                            fechaAlta: result.fechaAlta !== null ? result.fechaAlta : '',
                            fechaConclusion: result.fechaConclusion !== null ? result.fechaConclusion : '',
                            fecha: result.fecha ? result.fecha : '',
                            hora: result.hora !== null ? result.hora : '',
                            km: result.km !== null ? result.km : '',
                            tipoReporteId: result.tipoReporteId !== null ? result.tipoReporteId : '',
                            clasificacionFallaId: result.ClasificacionFallaId !== null ? result.ClasificacionFallaId : '',
                            pruebaManejo: result.pruebaManejo !== null ? (result.pruebaManejo ? 1 : 0) : null,
                            comentariosTecnico: result.comentariosTecnico !== null ? result.comentariosTecnico : '',
                            tipoReporteTecnicoId: result.tipoReporteTecnicoId !== null ? result.tipoReporteTecnicoId : '',
                            clasificacionFallaTecnicoId: result.clasificacionFallaTecnicoId !== null ? result.clasificacionFallaTecnicoId : '',
                            tipoFallaTecnicoId: result.tipoFallaTecnicoId !== null ? result.tipoFallaTecnicoId : '',
                            presentoFalla: result.presentoFalla ? (result.presentoFalla ? 1 : 0) : null,
                            responsablidadCliente: result.responsabilidadCliente ? result.responsabilidadCliente : false,
                            motivoResponsabilidadId: result.motivoResponsabilidadId !== null ? result.motivoResponsabilidadId : '',
                            fallaRecurente: result.fallaRecurente ? result.fallaRecurente : false,
                            fallaRecurrenteTecnico: result.fallaRecurrenteTecnico ? (result.fallaRecurrenteTecnico ? 1 : 0) : null,
                            cambioDevolucion: result.cambioDevolucion ? result.cambioDevolucion : false,
                            fechaEntrega: result.fechaEntrega ? result.fechaEntrega : '',
                            horaEntrega: result.horaEntrega ? result.horaEntrega : '',
                            concluida: result.concluida ? result.concluida : false,
                            fechaConclusion: result.fechaConclusion ? result.fechaConclusion : '',
                            horaConclusion: result.horaConclusion ? result.horaConclusion : '',
                            garantiaStatusInspeccionRapidaId: result.garantiaStatusInspeccionRapidaId
                        });

                        setInspeccionRapidaEstatus(result.garantiaStatusInspeccionRapida);
                        toast.success(message("¡Operación exitosa!", "Se inició la inspección rápida correctamente"));

                        setTimeout(() => {
                            const requestBody = {
                                nombrePlantilla: "InspeccionRapida",
                                parametros: {
                                    "<<VARIABLE_VIN>>": vin,
                                    "<<VARIABLE_CONTRATO>>": numContrato,
                                    "<<VARIABLE_FECHA>>": resp.data.fecha.split('T')[0],
                                    "<<VARIABLE_FOLIO>>": resp.data.folioInspeccion,
                                    "<<VARIABLE_CLIENTE>>": cliente,
                                    "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                                    "<<VARIABLE_HORA_INICIO>>": resp.data.hora.split('.')[0],
                                    "<<VARIABLE_DATAMOVIL>>": "",
                                    "<<VARIABLE_DIRECCION_UBICACION>>": ""
                                }
                            };

                            Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                                documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta.split('T')[0]}.pdf`);
                            }).catch(err => {
                                console.error(err);
                            });
                        }, 500);
                    })
                    .catch(err => {
                        toast.error(message("¡Operación fallida!", "No se pudo iniciar la inspección rápida"));
                    })
                    .finally(() => {
                        setDisabled(false);
                        setLoading(false)
                    });
            } else {
                const requestBody = {
                    nombrePlantilla: "InspeccionRapida",
                    parametros: {
                        "<<VARIABLE_VIN>>": vin,
                        "<<VARIABLE_CONTRATO>>": numContrato,
                        "<<VARIABLE_FECHA>>": initialValue.fecha?.split('T')[0],
                        "<<VARIABLE_FOLIO>>": initialValue.folioInspeccion,
                        "<<VARIABLE_CLIENTE>>": cliente,
                        "<<VARIABLE_DATOS_VEHICULO>>": nombreMarca + " " + nombreModelo + " " + nombreVersion + " MOD. " + nombreYear,
                        "<<VARIABLE_HORA_INICIO>>": initialValue.hora.split('.')[0],
                        "<<VARIABLE_DATAMOVIL>>": "",
                        "<<VARIABLE_DIRECCION_UBICACION>>": ""
                    }
                };
                Garantias.obtenerDocumentoRecepcion(requestBody).then(resp => {
                    //Pendiente de revisar por que no jala con el file-server
                    documentsHelper.downloadBase64File(resp.data.archivo, `${resp.data.procesoRelacionado}-${resp.data.fechaAlta?.split('T')[0]}.pdf`);
                }).catch(err => {
                    console.error(err);
                });
            }
        }

        const getEtapa = async () => {
            EtapaBandera("kGarantia", "eInsRapida", idllamada).then(acabado => {
                setEtapaFinalizado(acabado)
            }).catch(error => {
                // Manejar errores si la promesa es rechazada
            });
        }

        const downloadBase64File = (base64Data, filename) => {
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            link.remove();
        }

        useEffect(() => {
            getInspeccionRapida();
            getTipoReportes();
            getClasificaciones();
            getTipoFallas();
            getStatusRecepcion();
        }, []);

        const getKmFormat = (value) => {
            let dato = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
                currencyDisplay: "code"
            })
                .format(value)
                .replace("MXN", "")
                .split(".")[0]
                .trim();
            return dato + " Km";
        }

        const setKmFormat = (value, values) => {
            let dato = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
                currencyDisplay: "code"
            })
                .format(value)
                .replace("MXN", "")
                .split(".")[0]
                .trim();

            if (value.trim().length !== 0) {
                setInitialValue({
                    ...values,
                    km: dato + " Km"
                });
            } else {
                setInitialValue({
                    ...values,
                    km: ""
                });
            }
        }

        const setInputFormat = (value, values) => {
            let dato = value.replaceAll(",", "")
                .replaceAll("Km", "")
                .trim();

            setInitialValue({
                ...values,
                km: dato
            });
        }

        const onlyNumbers = (field, value, fn) => {
            const re = /^[0-9\b]+$/;
            if (value === '' || re.test(value)) {
                fn(field, value);
            }
        }

        const getStatusRecepcion = async () => {
            let statusId = '';
            await Catalogo.getDropdownsByTable('Catalogos.RecepcionStatus')
                .then(resp => {
                    statusId = resp.data.filter(item => item.RecepcionStatus.toLowerCase() === 'pendiente')[0].RecepcionStatusId;
                    setRecepcionStatus(statusId);
                })
                .catch(err => {
                    console.error(err);
                })
        }

        const getInspeccionRapida = async () => {
            setLoading(true)
            await Garantias.getInspeccionRapida(initial.GarantiaId)
                .then(resp => {
                    if (resp.status === 200) {
                        const result = resp.data;
                        if (!result.concluida) {
                            setDisabled(false);
                        }
                        setInitialValue({
                            GarantiaId: result.garantiaId,
                            folioInspeccion: result.folioInspeccion !== null ? result.folioInspeccion : '',
                            fechaAlta: result.fechaAlta !== null ? result.fechaAlta : '',
                            fechaConclusion: result.fechaConclusion !== null ? result.fechaConclusion : '',
                            fecha: result.fecha !== null ? result.fecha : '',
                            hora: result.hora !== null ? result.hora : '',
                            km: result.km !== null ? result.km : '',
                            tipoReporteId: result.tipoReporteId !== null ? result.tipoReporteId : '',
                            clasificacionFallaId: result.ClasificacionFallaId !== null ? result.ClasificacionFallaId : '',
                            pruebaManejo: result.pruebaManejo !== null ? (result.pruebaManejo ? 1 : 0) : null,
                            comentariosTecnico: result.comentariosTecnico !== null ? result.comentariosTecnico : '',
                            tipoReporteTecnicoId: result.tipoReporteTecnicoId !== null ? result.tipoReporteTecnicoId : '',
                            clasificacionFallaTecnicoId: result.clasificacionFallaTecnicoId !== null ? result.clasificacionFallaTecnicoId : '',
                            tipoFallaTecnicoId: result.tipoFallaTecnicoId !== null ? result.tipoFallaTecnicoId : '',
                            presentoFalla: result.presentoFalla ? (result.presentoFalla ? 1 : 0) : null,
                            responsablidadCliente: result.responsablidadCliente ? result.responsablidadCliente : false,
                            motivoResponsabilidadId: result.motivoResponsabilidadId !== null ? result.motivoResponsabilidadId : '',
                            fallaRecurente: result.fallaRecurente ? result.fallaRecurente : false,
                            fallaRecurrenteTecnico: result.fallaRecurrenteTecnico ? (result.fallaRecurrenteTecnico ? 1 : 0) : null,
                            cambioDevolucion: result.cambioDevolucion ? result.cambioDevolucion : false,
                            fechaEntrega: result.fechaEntrega ? result.fechaEntrega.split("T")[0] : '',
                            horaEntrega: result.horaEntrega ? result.horaEntrega : '',
                            concluida: result.concluida ? result.concluida : false,
                            fechaConclusion: result.fechaConclusion ? result.fechaConclusion : '',
                            horaConclusion: result.horaConclusion ? result.horaConclusion : '',
                            garantiaStatusInspeccionRapidaId: result.garantiaStatusInspeccionRapidaId
                        });
                        setInspeccionRapidaEstatus(result.garantiaStatusInspeccionRapida);
                        if (result?.responsablidadCliente) {
                            setRespabilidadCliente(true);
                        } else {
                            setRecepcionUnidad(true);
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };

        const getTipoReportes = async () => {
            if (TipoReportes.length > 0) {
                return false;
            }
            await Catalogo.getDropdownsByTable('Catalogos.TipoReporte')
                .then(res => {
                    let tmpList = [];
                    res.data.forEach(item => {
                        tmpList.push({ value: item.TipoReporteId, label: item.TipoReporte });
                    });

                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setTipoReportes(tmpList);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const getClasificaciones = async () => {
            if (TipoReportes.length > 0) {
                return false;
            }
            await Catalogo.getDropdownsByTable('Catalogos.ClasificacionFalla')
                .then(res => {
                    let tmpList = [];
                    res.data.forEach(item => {
                        tmpList.push({ value: item.ClasificacionFallaId, label: item.Falla });
                    });

                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setClasificaciones(tmpList);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const getTipoFallas = async () => {
            if (TipoReportes.length > 0) {
                return false;
            }
            await Catalogo.getDropdownsByTable('Catalogos.TipoFalla')
                .then(res => {
                    let tmpList = [];
                    res.data.forEach(item => {
                        tmpList.push({ value: item.TipoFallaId, label: item.TipoFalla });
                    });

                    const compare = (a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    }

                    tmpList.sort(compare);
                    setTipoFallas(tmpList);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const validateTipoFalla = (tipo, values) => {
            const result = TipoFallas.filter(tipoF => tipoF.value === tipo)[0].label.toLowerCase().replaceAll(' ', '_');
            if (result === 'responsabilidad_del_cliente') {
                // responsablidadCliente
                setInitialValue({
                    ...values,
                    tipoFallaTecnicoId: tipo,
                    responsablidadCliente: true
                })
                setRespabilidadCliente(true);
                setRecepcionUnidad(false);
            } else {
                setInitialValue({
                    ...values,
                    tipoFallaTecnicoId: tipo,
                    responsablidadCliente: false
                })
                setRespabilidadCliente(false);
                setRecepcionUnidad(true);
            }
            console.log(initialValue.responsablidadCliente);
            console.log(responsabilidadCliente);
        }

        const boletinar = async (values) => {
            values.responsablidadCliente = true;
            await Garantias.updateInspeccionRapida(values)
                .then(resp => {
                    const result = resp.data;
                    setInitialValue({
                        GarantiaId: result.garantiaId,
                        folioInspeccion: result.folioInspeccion !== null ? result.folioInspeccion : '',
                        fechaAlta: result.fechaAlta !== null ? result.fechaAlta : '',
                        fechaConclusion: result.fechaConclusion !== null ? result.fechaConclusion : '',
                        fecha: result.fecha !== null ? result.fecha : '',
                        hora: result.hora !== null ? result.hora : '',
                        km: result.km !== null ? result.km : '',
                        tipoReporteId: result.tipoReporteId !== null ? result.tipoReporteId : '',
                        clasificacionFallaId: result.ClasificacionFallaId !== null ? result.ClasificacionFallaId : '',
                        pruebaManejo: result.pruebaManejo !== null ? (result.pruebaManejo ? 1 : 0) : 0,
                        comentariosTecnico: result.comentariosTecnico !== null ? result.comentariosTecnico : '',
                        tipoReporteTecnicoId: result.tipoReporteTecnicoId !== null ? result.tipoReporteTecnicoId : '',
                        clasificacionFallaTecnicoId: result.clasificacionFallaTecnicoId !== null ? result.clasificacionFallaTecnicoId : '',
                        tipoFallaTecnicoId: result.tipoFallaTecnicoId !== null ? result.tipoFallaTecnicoId : '',
                        presentoFalla: result.presentoFalla ? (result.presentoFalla ? 1 : 0) : 0,
                        responsablidadCliente: result.responsabilidadCliente ? result.responsabilidadCliente : false,
                        motivoResponsabilidadId: result.motivoResponsabilidadId !== null ? result.motivoResponsabilidadId : '',
                        fallaRecurente: result.fallaRecurente ? result.fallaRecurente : false,
                        fallaRecurrenteTecnico: result.fallaRecurrenteTecnico ? (result.fallaRecurrenteTecnico ? 1 : 0) : 0,
                        cambioDevolucion: result.cambioDevolucion ? result.cambioDevolucion : false,
                        fechaEntrega: result.fechaEntrega ? result.fechaEntrega : '',
                        horaEntrega: result.horaEntrega ? result.horaEntrega : '',
                        concluida: result.concluida ? result.concluida : false,
                        fechaConclusion: result.fechaConclusion ? result.fechaConclusion : '',
                        horaConclusion: result.horaConclusion ? result.horaConclusion : '',
                        garantiaStatusInspeccionRapidaId: result.garantiaStatusInspeccionRapidaId
                    });
                    setRespabilidadCliente(true);
                    setRecepcionUnidad(false);
                    toast.success(message("¡Operación exitosa!", "Se boletinó al cliente correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "Oops, ocurrió un error al boletinar al cliente"));
                })
                .finally(() => setLoading(false));
        }

        const submitForm = async (values) => {
            if (values.concluida) {
                setModalMessage({ isOpen: true, type: 5, title: 'Confirmar etapa concluida', message: '', data: values });
            } else {
                values.responsablidadCliente = responsabilidadCliente;
                values.km = values.km;

                setLoading(true);
                values.GarantiaId = GarantiaId;
                await Garantias.updateInspeccionRapida(values)
                    .then(resp => {
                        const result = resp.data;
                        setInitialValue({
                            GarantiaId: result.garantiaId,
                            folioInspeccion: result.folioInspeccion !== null ? result.folioInspeccion : '',
                            fechaAlta: result.fechaAlta !== null ? result.fechaAlta : '',
                            fechaConclusion: result.fechaConclusion !== null ? result.fechaConclusion : '',
                            fecha: result.fecha !== null ? result.fecha : '',
                            hora: result.hora !== null ? result.hora : '',
                            km: result.km !== null ? result.km : '',
                            tipoReporteId: result.tipoReporteId !== null ? result.tipoReporteId : '',
                            clasificacionFallaId: result.ClasificacionFallaId !== null ? result.ClasificacionFallaId : '',
                            pruebaManejo: result.pruebaManejo !== null ? (result.pruebaManejo ? 1 : 0) : 0,
                            comentariosTecnico: result.comentariosTecnico !== null ? result.comentariosTecnico : '',
                            tipoReporteTecnicoId: result.tipoReporteTecnicoId !== null ? result.tipoReporteTecnicoId : '',
                            clasificacionFallaTecnicoId: result.clasificacionFallaTecnicoId !== null ? result.clasificacionFallaTecnicoId : '',
                            tipoFallaTecnicoId: result.tipoFallaTecnicoId !== null ? result.tipoFallaTecnicoId : '',
                            presentoFalla: result.presentoFalla ? (result.presentoFalla ? 1 : 0) : 0,
                            responsablidadCliente: result.responsabilidadCliente ? result.responsabilidadCliente : false,
                            motivoResponsabilidadId: result.motivoResponsabilidadId !== null ? result.motivoResponsabilidadId : '',
                            fallaRecurente: result.fallaRecurente ? result.fallaRecurente : false,
                            fallaRecurrenteTecnico: result.fallaRecurrenteTecnico ? (result.fallaRecurrenteTecnico ? 1 : 0) : 0,
                            cambioDevolucion: result.cambioDevolucion ? result.cambioDevolucion : false,
                            fechaEntrega: result.fechaEntrega ? result.fechaEntrega.split("T")[0] : '',
                            horaEntrega: result.horaEntrega ? result.horaEntrega : '',
                            concluida: result.concluida ? result.concluida : false,
                            fechaConclusion: result.fechaConclusion ? result.fechaConclusion : '',
                            horaConclusion: result.horaConclusion ? result.horaConclusion : '',
                            garantiaStatusInspeccionRapidaId: result.garantiaStatusInspeccionRapidaId
                        });
                        toast.success(message("¡Operación exitosa!", "Se actualizó la inspección rápida correctamente"));
                    })
                    .catch(err => {
                        toast.error(message("¡Operación fallida!", "Oops, ocurrió un error al actualizar la inspección rápida"));
                    })
                    .finally(() => setLoading(false));
            }
        }

        const handleActualizar = async (data) => {
            setModalMessage({ isOpen: false, type: 4, title: '', message: '' });
            setLoading(true);
            setDisabled(true);
            data.responsablidadCliente = responsabilidadCliente;

            //data.km = data.km.replaceAll(",", "").replaceAll("Km", "").trim();

            if (data.km && typeof data.km === 'string') {
                data.km = data.km.replace(/,/g, "").replace("Km", "").trim();
                console.log(data.km);
            }
            data.GarantiaStatusInspeccionRapidaId = 30;
            data.GarantiaId = GarantiaId;

            await Garantias.updateInspeccionRapida(data)
                .then(resp => {
                    const result = resp.data;
                    setInitialValue({
                        GarantiaId: result.garantiaId,
                        folioInspeccion: result.folioInspeccion !== null ? result.folioInspeccion : '',
                        fechaAlta: result.fechaAlta !== null ? result.fechaAlta : '',
                        fecha: result.fecha !== null ? result.fecha : '',
                        hora: result.hora !== null ? result.hora : '',
                        km: result.km !== null ? result.km : '',
                        tipoReporteId: result.tipoReporteId !== null ? result.tipoReporteId : '',
                        clasificacionFallaId: result.ClasificacionFallaId !== null ? result.ClasificacionFallaId : '',
                        pruebaManejo: result.pruebaManejo !== null ? (result.pruebaManejo ? 1 : 0) : 0,
                        comentariosTecnico: result.comentariosTecnico !== null ? result.comentariosTecnico : '',
                        tipoReporteTecnicoId: result.tipoReporteTecnicoId !== null ? result.tipoReporteTecnicoId : '',
                        clasificacionFallaTecnicoId: result.clasificacionFallaTecnicoId !== null ? result.clasificacionFallaTecnicoId : '',
                        tipoFallaTecnicoId: result.tipoFallaTecnicoId !== null ? result.tipoFallaTecnicoId : '',
                        presentoFalla: result.presentoFalla ? (result.presentoFalla ? 1 : 0) : 0,
                        responsablidadCliente: result.responsabilidadCliente ? result.responsabilidadCliente : false,
                        motivoResponsabilidadId: result.motivoResponsabilidadId !== null ? result.motivoResponsabilidadId : '',
                        fallaRecurente: result.fallaRecurente ? result.fallaRecurente : false,
                        fallaRecurrenteTecnico: result.fallaRecurrenteTecnico ? (result.fallaRecurrenteTecnico ? 1 : 0) : 0,
                        cambioDevolucion: result.cambioDevolucion ? result.cambioDevolucion : false,
                        fechaEntrega: result.fechaEntrega ? result.fechaEntrega.split("T")[0] : '',
                        horaEntrega: result.horaEntrega ? result.horaEntrega : '',
                        concluida: result.concluida ? result.concluida : false,
                        fechaConclusion: result.fechaConclusion ? result.fechaConclusion : '',
                        horaConclusion: result.horaConclusion ? result.horaConclusion : '',
                        garantiaStatusInspeccionRapidaId: result.garantiaStatusInspeccionRapidaId
                    });

                    setInspeccionRapidaEstatus(result.garantiaStatusInspeccionRapida);
                    // tipoFallaTecnicoId

                    const tipoFallaTecnico = TipoFallas.filter(tipoF => tipoF.value === result.tipoFallaTecnicoId)[0].label.toLowerCase().replaceAll(' ', '_');
                    if (tipoFallaTecnico !== 'responsabilidad_del_cliente') {
                        insertRecepcion();
                    }

                    toast.success(message("¡Operación exitosa!", "Se concluyó la inspección rápida correctamente"));
                })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "Oops, ocurrió un error al intentar concluir la inspección rápida"));
                })
                .finally(() => {
                    setLoading(false)
                });
        }

        const insertRecepcion = async () => {
            const recepcion = {
                garantiaId: GarantiaId,
                recepcionStatusId: recepcionStatus
            };
            await Garantias.crearRecepcion(recepcion)
                .then(resp => {
                    toast.success(message("¡Operación exitosa!", "Se inició la recepción correctamente"));
                    myRef.current?.getRecepcion();
                })
                .catch(err => {
                    toast.error(message("¡Operación fallida!", "Oops, ocurrió un error al intentar iniciar la recepción"));
                })
        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined) {
                format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }

        const getFallaTecnicaText = (tipo) => {
            if (tipo !== undefined && tipo !== "") {
                const result = TipoFallas.filter(tipoF => tipoF.value === tipo)[0]?.label.toLowerCase().replaceAll(' ', '_');
                return result;
            }
        }

        const validate = Yup.object({
            km: Yup.number()
                .typeError('Debe contener solo números y no exceder los 10 caracteres')
                .integer()
                .min(kilometraje, `El kilometraje no debe ser menor a ${kilometraje}`)
                .required("El campo es requerido.")
        });

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 3 &&
                        <DetailAuthCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 4 &&
                        <RechazaCitaModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 5 &&
                        <ConfirmarModal
                            handleActualizar={handleActualizar}
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <Formik
                        innerRef={refFormik}
                        enableReinitialize={true}
                        validationSchema={validate}
                        initialValues={initialValue}
                    >
                        {({ isValid, values, setFieldValue, errors }) => (
                            <>
                                <div className='row mx-md-4 my-4' >
                                    <div className="col-12 px-md-3">
                                        <div className="p-4 white-wrapper mb-2">

                                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseInspeccion" role="button" aria-expanded="false" aria-controls="collapseInspeccion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                                <div className="col-11">
                                                    <h6><span className={initialValue.folioInspeccion !== 0 && !initialValue.concluida ? 'semaforo semaforo-yellow' : (initialValue.folioInspeccion !== 0 && initialValue.concluida ? 'semaforo semaforo-green' : 'semaforo semaforo-default')}></span> <strong>Inspección rápida</strong></h6>
                                                </div>
                                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                                </div>
                                                <div className="col-12">
                                                    <small>{inspeccionRapidaEstatus}</small>
                                                </div>
                                            </div>
                                            <div className="collapse" id="collapseInspeccion">
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-8">
                                                        <small><strong>Generar Documento</strong></small>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="row justify-content-end">
                                                            {
                                                                !responsabilidadCliente && initialValue.concluida &&
                                                                <div className="col-auto">
                                                                    <button className='btn btn-gray' onClick={() => boletinar(values)}>
                                                                        <i className="icon-blue bx bx-bell"></i>
                                                                    </button>
                                                                </div>
                                                            }
                                                            {
                                                                !initialValue.concluida &&
                                                                <div className="col-auto">

                                                                    <button
                                                                        disabled={disabled}
                                                                        style={{ background: etapaFinalizado ? '#277917' : '' }}
                                                                        className={etapaFinalizado ? 'btn' : 'btn btn-gray'}
                                                                        // className='btn btn-gray'
                                                                        onClick={() => {
                                                                            navigate(`/garantias/datadocs/kGarantia/eInsRapida/${FolioGarantia}/${idllamada}`, {
                                                                                state: values,
                                                                            });
                                                                        }}>
                                                                        <i className="icon-light fa fa-file "></i> Subir documento
                                                                    </button>

                                                                </div>

                                                            }
                                                            {
                                                                !initialValue.concluida &&
                                                                <div className="col-auto">
                                                                    <button className='btn' disabled={!isValid || (!isValid && disabled) || disabled} onClick={() => submitForm(values)}>
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small>Documento de inspección rápida</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <button className='btn btn-gray' onClick={() => downloadPDF()}>
                                                            <i className="icon-blue bx bx-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12">
                                                        <small><strong>Inspección rápida</strong></small>
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField
                                                            id="km"
                                                            label="KM de entrada"
                                                            disabled={disabled}
                                                            name="km"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                onlyNumbers("km", event.target.value, setFieldValue);
                                                            }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="pruebaManejo" label="Prueba de Manejo" disabled={disabled} name="pruebaManejo" items={opcionesBool} onChange={(event) => {
                                                            setFieldValue("pruebaManejo", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="presentoFalla" label="¿Presentó falla?" disabled={disabled} name="presentoFalla" items={opcionesBool} onChange={(event) => {
                                                            setFieldValue("presentoFalla", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="tipoReporteId" label="Tipo reporte técnico" disabled={disabled} name="tipoReporteId" items={TipoReportes} onChange={(event) => {
                                                            setFieldValue("tipoReporteId", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="clasificacionFallaTecnicoId" label="Clasificación falla técnico" disabled={disabled} name="clasificacionFallaTecnicoId" items={Clasificaciones} onChange={(event) => {
                                                            setFieldValue("clasificacionFallaTecnicoId", event.value);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="tipoFallaTecnicoId" label="Tipo de falla técnico" disabled={disabled} name="tipoFallaTecnicoId" items={TipoFallas} onChange={(event) => {
                                                            setFieldValue("tipoFallaTecnicoId", event.value);
                                                            validateTipoFalla(event.value, values);
                                                        }} />
                                                    </div>
                                                    <div className="col-3">
                                                        <SelectField id="fallaRecurrenteTecnico" label="Falla recurrente técnico" disabled={disabled} name="fallaRecurrenteTecnico" items={opcionesBool} onChange={(event) => {
                                                            setFieldValue("fallaRecurrenteTecnico", event.value);
                                                        }} />
                                                    </div>
                                                    {
                                                        responsabilidadCliente && getFallaTecnicaText(values.tipoFallaTecnicoId) === 'responsabilidad_del_cliente' &&
                                                        <>
                                                            <div className="col-3">
                                                                <TextField id="fechaEntrega" label="Fecha de entrega:" disabled={disabled} name="fechaEntrega" type="date" holder="Escribe" max={new Date().toISOString().slice(0, 10)} onChange={(event) => {
                                                                    setFieldValue("fechaEntrega", event.target.value);
                                                                }} />
                                                            </div>
                                                            <div className="col-3">
                                                                <TextField id="horaEntrega" label="Hora de entrega:" disabled={disabled} name="horaEntrega" type="time" min="09:00" max="15:00" step="600" holder="Escribe" onChange={(event) => {
                                                                    setFieldValue("horaEntrega", event.target.value);
                                                                }} />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentariosTecnico" label="Comentarios técnicos" disabled={disabled} className="form-control col-12" rows="3" name="comentariosTecnico" type="text" holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentariosTecnico", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        {/* <Field className="form-check-input" disabled={disabled || !etapaFinalizado} type="checkbox" name="concluida" onClick={(event) => { */}
                                                        <Field className="form-check-input" disabled={disabled} type="checkbox" name="concluida" onClick={(event) => {
                                                            setFieldValue("concluida", event.target.value);
                                                            // setValidationScheme(event.target.checked);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Inspección concluida</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaConclusion !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </Formik>
                </div>

                {
                    responsabilidadCliente &&
                    Number(initialValue.garantiaStatusInspeccionRapidaId) === 30 &&
                    <CardResponsabilidadCliente generalId={generalId} />
                }
                {
                    recepcionUnidad &&
                    Number(initialValue.garantiaStatusInspeccionRapidaId) === 30 &&
                    <CardRecepcion ref={myRef} />
                }
            </>
        )
    }
export default CardInspeccionRapida