import React from 'react'
import '../Modal/Modal.css'

const Modal = ({children,isOpen,closeModal,color,width,height=null}) => {
  return (
    <>
    <article className={`modal ${isOpen && "is-open"}`} >
        <div className='modal-container' style={{backgroundColor:`${color}`,width:`${width}px`}}>                        
            {children}
        </div>
    </article>
    </>
  )
}

export default Modal    