import { Formik, Form, Field } from 'formik'
import React, { useRef, useState } from 'react'
import Modal from '../../Components/Modal/Modal'
import { TextField } from '../../Components/TextField'
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../../Components/SelectField';
import ConfigDataDocsService from '../../Services/ConfigDataDocs/ConfigDataDocsService';
import { message } from '../../Utils/functions';
import Spinner from '../../Components/Loadig';

const AddDocumento = ({ isOpen, setIsOpen, item, handleGetAll, toast }) => {
    const refbtn = useRef();
console.log("item",item)
    let initial = {
        binarioId: item?.binarioId ?? "",
        binario: item?.binario ?? "",
        ficheroId: item?.ficheroId,
        etapaId: item?.etapaId,
        dataDocsId: item?.dataDocsId ?? "",
        tipoCarga: item?.tipoCarga ?? "",
        requerido: item?.requerido ?? false
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const TipoCarga = [
        { value: '1', label: "Único" },
        { value: '2', label: "Múltiple" },
    ]
    const validate = Yup.object({
        binario: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        dataDocsId: Yup.string()
            .required("El campo es requerido."),
        tipoCarga: Yup.string()
            .required("El campo es requerido."),
    });

    const handleAddEdit = async (value) => {
        refbtn.current.disabled = true;
        if (item.action === 'Add') {
            setLoading(true);
            await ConfigDataDocsService.AddConfigBinario(value)
                .then(res => {
                    console.log("data",res.data)
                    if (res.data.data.ban === 1) {
                        toast.success(message("!Correcto¡", res.data.data.mensaje));
                        setIsOpen(false);
                        handleGetAll(1);
                    }
                    else {
                        setIsOpen(false);
                        toast.error(message("!Error¡", res.data.data.mensaje));
                    }
                }).catch(e => {
                    setIsOpen(false);
                }).finally(()=>{setLoading(false)})
        }
        if (item.action === 'Edit') {
            setLoading(true)
            await ConfigDataDocsService.UpdConfigBinario(value)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        toast.success(message("!Correcto¡", res.data.data.mensaje));
                        setIsOpen(false);
                        handleGetAll(1);
                    }
                    else {
                        setIsOpen(false);
                        toast.error(message("!Error¡", res.data.data.mensaje));
                    }
                }).catch(e => {
                    setIsOpen(false);
                }).finally(()=>{setLoading(false)})
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={isOpen} color='#fff'>
                        <section className='d-flex justify-content-between mx-4'>
                            <span className='fs-6'><strong>Agregar documento a etapa: {item?.tablaId ?? ""}</strong></span>
                        </section>
                        <section className='my-4 mx-4' style={{ width: '30rem' }}>
                            <span className='fs-6'><strong>Configuración</strong></span>
                            <Form>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <TextField id="Banco" label="Documento" name="binario" type="text" holder="Escribe" />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField id="Banco" label="ID Datadocs" name="dataDocsId" type="text" holder="Escribe" />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-6">
                                        <SelectField id="TipoUbicacion" label="Tipo carga" name="tipoCarga" items={TipoCarga} onChange={(value) => {
                                            setFieldValue("tipoCarga", value.value);
                                        }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Requerido</label>
                                        <div className="col-ms-6 form-check form-switch">
                                            <label className="form-check-label">{values?.requerido ? "SI" : "NO"}</label>
                                            <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="requerido" />
                                        </div>
                                    </div>
                                </div>
                                <section className='my-3 d-flex align-items-center justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                    <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{item?.action === "Add" ? "Guardar" : "Editar"}</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

                    }
                </>
            )
            }
        </Formik>
    )
}

export default AddDocumento