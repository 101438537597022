import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { getDateFormat, message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import { useParams } from 'react-router-dom';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import PagoTramitesCard from './PagoTramitesCard';
const ClienteDesisteCard
    = React.forwardRef(({ ...props }, ref) => {
        const { nombreYear } = useSelector((state) => state.DataVinSlice);
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, generalid, siniestroid } = useParams();


        let initial = {
            color: 'default',
            proceso: "",
            siniestroClienteDesisteId: "",
            siniestroId: "",
            siniestroClienteDesisteStatusId: "",
            siniestroClienteDesisteStatus: "",
            siniestroClienteDesisteMotivoId: "",
            siniestroClienteDesisteMotivo: "",
            comentarios: "",
            fechaAlta: "",
            fechaActualiza: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: ""

        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [disabled, setDisabled] = useState(false);
        const [dataClienteDesisteMotivo, setDataClienteDesisteMotivo] = useState([]);

        useEffect(() => {
            getSiniestroClienteDesisteMotivo();
            getSiniestroClienteDesiste();

        }, []);


        /* CONSULTAS */
        const getSiniestroClienteDesiste = async () => {
            setLoading(true);
            await Siniestros.siniestroClienteDesisteGet(siniestroid)
                .then(resp => {
                    console.log("clienteDesiste-->",resp.data)
                    resp.data.fechaAlta = resp.data.fechaAlta?.split("T")[0];
                    resp.data.fechaActualiza = resp.data.fechaActualiza?.split("T")[0];
                    resp.data.fechaInicio = resp.data.fechaInicio?.split("T")[0];
                    resp.data.fechaFin = resp.data.fechaFin?.split("T")[0];
                    resp.data.comentarios = resp.data.comentarios ?? "";
                    resp.data.siniestroClienteDesisteMotivoId = resp.data.siniestroClienteDesisteMotivoId ?? "";
                    setInitialValue(resp.data);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        const getSiniestroClienteDesisteMotivo = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.SiniestroClienteDesisteMotivo')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.SiniestroClienteDesisteMotivoId, label: item.SiniestroClienteDesisteMotivo })
                    });
                    setDataClienteDesisteMotivo(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };
        /* TERMINA CATALOGOS */      

        const submitForm = async (values, modal) => {

            if (values.concluido) {
                values.siniestroClienteDesisteStatusId = 30;
                //crearPagoTramites();
            }

            setLoading(true);
            await Siniestros.siniestroClienteDesisteActualizar(values)
                .then(resp => {
                    if (resp.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                        getSiniestroClienteDesiste();
                    } else {
                        toast.error(message("¡Error.!", resp.data.data.mensaje));
                    }
                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error, al intentar actualizar el registro de cliente desiste!"));
                })
                .finally(() => {
                    if (values.concluido) {
                        //crearPagoTramites();
                    }
                    setLoading(false);
                });
        }

        const crearPagoTramites = async () => {
            let param = {
                generalId: generalid,
                siniestroPagoTramitesStatusId: 10,
                siniestroId: siniestroid
            };

            await Siniestros.siniestroPagoTramitesCrear(param);

        }

        const validate = Yup.object({
            siniestroClienteDesisteMotivoId: Yup.string()
                .required("El campo es requerido."),
            comentarios: Yup.string()
                .required("El campo es requerido."),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseClienteDesiste" role="button" aria-expanded="false" aria-controls="collapseClienteDesiste" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Cliente desiste</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.siniestroClienteDesisteStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseClienteDesiste">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                        onSubmit={(values) => {
                                            submitForm(values);
                                        }}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4 mb-2">
                                                    <div className="col-12">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    // disabled={initialValue?.concluido}
                                                                    disabled={initialValue.siniestroClienteDesisteStatusId === 30}
                                                                    onClick={() => submitForm(values)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="siniestroClienteDesisteMotivoId"
                                                            label="Motivo"
                                                            disabled={initialValue?.concluido}
                                                            name="siniestroClienteDesisteMotivoId"
                                                            items={dataClienteDesisteMotivo}
                                                            onChange={(event) => {
                                                                setFieldValue("siniestroClienteDesisteMotivoId", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={initialValue?.concluido}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaInicio, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            {}
                                                            <div className="col-auto">
                                                                {
                                                                   initialValue?.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaFin, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {
                    initialValue.concluido &&
                    <PagoTramitesCard />
                } */}

            </>
        )
    })
export default ClienteDesisteCard