import axios from '../../Utils/ApiService';

const Cobranza = {
    /* SEGUIMIENTO COBRANZA */
    seguimientoAccesorios: (data) => {
        return axios.post(`/cartera/getSeguimientoFinanciar`, data);
    },
    /* REPORTE SEGUIMIENTO COBRANZA */
    reporteAccesorios: (data) => {
        return axios.post(`/cartera/getReporteFinanciar`, data);
    },
}

export default Cobranza