import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import Garantias from '../../Services/Garantias/Garantias';
import { TextField } from '../TextField';
import { useNavigate } from 'react-router-dom';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { Toaster, toast } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import { SelectField } from '../SelectField';
import Cierres from '../../Services/Resguardos/Cierres';
import { TextAreaField } from '../TextAreaField';

const PolizaPorCancelar8Modal = ({ modalMessage, setModalMessage, cierreContratoAdeudoId, getAdeudos }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const refFormik = useRef();
    const initial = {
        comentarios: "",
    };


    const [tipoAdeudos, setTipoAdeudos] = useState([]);
    const [initialValue, setInitialValue] = useState(initial);
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
    const opcionesBoolPolizas = [{ value: 1, label: 'Mantener' }, { value: 0, label: 'Cancelar' }];
    const data = modalMessage.data;

    useEffect(() => {
        getTipoAdeudos();
    }, []);

    // const validate = Yup.object().shape({});
    
    const validate = Yup.object({
        cierreContratoAdeudoTipoId: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
        monto: Yup.string()
            .typeError("El campo es requerido.")
            .required("El campo es requerido."),
    });

    const getTipoAdeudos = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoAdeudoTipo')
        .then(resp => {
            resp.data.forEach(item => {
                items.push({
                    value: item.CierreContratoAdeudoTipoId,
                    label: item.CierreContratoAdeudoTipo
                });
            });
            setTipoAdeudos(items);
        })
        .catch(err => {
            console.error(err);
        });
    };

    const submitForm = async (values) => {
    };

    const getOnlyDateFormat = (fecha) => {
        let d = new Date(fecha + "Z");
        let format = "";
        if (fecha !== undefined && fecha !== "") {
            format = ("0"+d.getDate()).slice(-2)+"-"+("0"+(d.getMonth()+1)).slice(-2)+"-"+d.getFullYear();
            return format;
        }
    }

    const onlyNumbers = (field, value, fn) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            fn(field, value);
        }
    }

    const nextModal = () => {
        setModalMessage({ isOpen: false });
        setModalMessage({ isOpen: true, type: 5 });
    }
    

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={() => { }}
        >
            {({ isValid, values, setFieldValue, errors, resetForm }) => (
                <div>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    {/* <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 5 &&
                            <>
                                <div>
                                    <div className='justify-content-start px-4 py-3 align-items-center'>
                                        <div className="row">
                                            <div className="col col-12">
                                                <h6><strong>Confirmar cambio de estatus póliza</strong></h6>
                                            </div>
                                        </div>
                                        <div className="row px-2 mt-3 mb-2">
                                            <div className="col col-12 py-2" style={{"background": "#F5F5F5"}}>
                                                <span id="AT_TxtBasicModalMenssage">¿Estás seguro que deseas {values.detallePoliza ? 'Mantener' : 'Cancelar'} la póliza?</span>
                                            </div>
                                        </div>
                                        
                                        <section className='d-flex justify-content-end'>
                                            <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={() => { setModalMessage({ ...modalMessage, isOpen: false }) }}>Cerrar</button>
                                        </section>
                                    </div>
                                </div>
                            </>
                        }
                    </Modal> */}
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className='d-flex justify-content-end'>
                        <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} onClick={() => { 
                            setModalMessage({ ...modalMessage, isOpen: false });
                            resetForm();
                            }}><i className="ri-close-fill"></i></button>
                    </section>
                    <div className='justify-content-start p-2 align-items-center'>
                        <div className="row">
                            <div className="col col-12">
                                <h5><strong>{modalMessage.title}</strong></h5>
                            </div>
                        </div>
                        <div className="row mt-1 mb-2">
                            <div className="col col-12">
                                <div className="row">
                                    <small id="AT_TxtBasicModalMenssage">
                                        Para continuar captura un comentario respecto a la cancelación de seguro del vehículo con VIN: {data.vin}
                                    </small>
                                </div>
                                <div className="row mt-3">
                                    <strong>
                                        <small>Información de último crédito</small>
                                    </strong>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <small>No. de contrato: {data.numContrato} | 
                                        Uso de unidad: {data.usoUnidad} | 
                                        Origen: {data.origen} | 
                                        Tipo de falla: {data.falla}</small>
                                        {
                                            data.comentariosGarantia !== null &&
                                            <>
                                                <small>| Comentarios: </small><button type="button" class="btn btn-sm btn-gray" data-bs-toggle="tooltip" data-bs-placement="bottom" title={data.comentariosGarantia}>
                                                    <i className="fa fa-comment-alt"></i>
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <strong>
                                        <small>Información de póliza</small>
                                    </strong>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-auto">
                                        <small>Póliza: {data.numeroPoliza} | 
                                        Inicio póliza: { getOnlyDateFormat(data.fechaInicio) } | 
                                        Vigencia: { getOnlyDateFormat(data.fechaFin) } </small>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <SelectField id={'detallePoliza'}
                                            name={'detallePoliza'}
                                            items={opcionesBoolPolizas}
                                            disabled={values.concluido}
                                            onChange={(event) => {
                                                setFieldValue('detallePoliza', event.value);
                                        }} />
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-between">
                                    <div className="col-12">
                                        <TextAreaField
                                            id="comentarios"
                                            label="Comentarios"
                                            className="form-control"
                                            rows="3"
                                            name="comentarios"
                                            type="text"
                                            holder="Escribe"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-end">
                                    <div className="col-auto">
                                        <button className='btn btn-gray' onClick={(e) => {
                                            setModalMessage({ isOpen: false });
                                        }}>Cancelar</button>
                                    </div>
                                    <div className="col-auto">
                                        <button className='btn'
                                            onClick={(event) => {
                                                nextModal(values);
                                            }}
                                        >Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default PolizaPorCancelar8Modal;
