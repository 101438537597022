import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../Components/TextAreaField';
import handlePhonNumberFormatter, { getDateFormat, message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import { TextField } from '../../../Components/TextField';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import { el } from 'date-fns/locale';
import PagoTramitesCard from './PagoTramitesCard';
import ValuacionAseguradoraCard from './ValuacionAseguradoraCard';
import UnidadDetenidaCard from './UnidadDetenidaCard';
import ClienteEntregaUnidadCard from './ClienteEntregaUnidadCard';
import BoletinarClienteCard from './BoletinarClienteCard';
import ClienteDesisteCard from './ClienteDesisteCard';

const RegistroSiniestroCard = React.forwardRef(({ ...props }, ref) => {
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();
    const { id, generalid, siniestroid } = useParams();
    const navigate = useNavigate();
    const { fechaEntrega,
        nombreYear,
        vin,
        color,
        nombreMarca,
        kilometraje,
        nombreModelo,
        procedenciaAuto,
        nombreVersion,
        numContrato,
        numSolicitud } = useSelector((state) => state.DataVinSlice);
    let { state } = props;
    let initial = {
        siniestroId: '',
        siniestroStatusId: '',
        siniestroStatusNombre: 'Pendiente',
        reportaNombre: '',
        telefonoMovil: '',
        telefonoFijo: '',
        telefonoTrabajo: '',
        correoElectronico: '',
        siniestroTipo: '',
        siniestroTipoId: '',
        unidadDetenida: '',
        cuentaFolioAseguradora: '',
        folioAseguradora: '',
        folio: '',
        comentarios: '',
        fechaAlta: '',
        fechaActualiza: '',
        usuarioAlta: '',
        usuarioAltaNombre: '',
        usuarioActualiza: '',
        usuarioActualizaNombre: '',
        status: '',
        fechaInicio: '',
        fechaFin: '',
        concluido: false,
        generalId: '',
        color: 'default',
        proceso: '',
        siniestroProcesoId: '',
        siniestroProceso: '',
        subido: false
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
    const [dataTipoSiniestro, setDataTipoSiniestro] = useState([]);
    const [UnidadDetenida, setUnidadDetenida] = useState();
    const [formDisabled, setFormDisabled] = useState(false);
    useEffect(() => {
        getTipoSiniestro();
        getSiniestro();
    }, []);


    /* CONSULTAS */
    const getSiniestro = async () => {
        setLoading(true);
        await Siniestros.siniestroGet(siniestroid)
            .then(resp => {
                console.log("getSiniestro-->", resp.data)
                // resp.data.unidadEnUbicacion = resp.data.unidadEnUbicacion === null ? null : (resp.data.unidadEnUbicacion ? "Sí" : "No");
                resp.data.unidadDetenida = resp.data.unidadDetenida === null ? null : (resp.data.unidadDetenida ? 1 : 0);
                resp.data.cuentaFolioAseguradora = resp.data.cuentaFolioAseguradora === null ? null : (resp.data.cuentaFolioAseguradora ? 1 : 0);
                resp.data.fechaAlta = resp.data.fechaAlta?.split("T")[0];
                resp.data.fechaActualiza = resp.data.fechaActualiza?.split("T")[0];
                resp.data.fechaInicio = resp.data.fechaInicio?.split("T")[0];
                resp.data.fechaFin = resp.data.fechaFin?.split("T")[0];
                resp.data.telefonoFijo = handlePhonNumberFormatter(resp.data.telefonoFijo) ?? "";
                resp.data.telefonoMovil = handlePhonNumberFormatter(resp.data.telefonoMovil) ?? "";
                resp.data.telefonoTrabajo = handlePhonNumberFormatter(resp.data.telefonoTrabajo) ?? "";
                resp.data.siniestroTipoId = resp.data?.siniestroTipoId ?? "";
                resp.data.unidadDetenida = resp.data?.unidadDetenida ?? "";
                resp.data.cuentaFolioAseguradora = resp.data?.cuentaFolioAseguradora ?? "";
                resp.data.reportaNombre = resp.data?.reportaNombre ?? "";
                resp.data.correoElectronico = resp.data?.correoElectronico ?? "";
                resp.data.folioAseguradora = resp.data?.folioAseguradora ?? "";
                setFormDisabled(resp?.data?.concluido ?? false);
                setInitialValue(resp.data);
                setUnidadDetenida(resp.data.unidadDetenida ?? 0);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };
    /*  TERMINA CONSULTAS */

    /* CATALOGOS */
    const getTipoSiniestro = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroTipo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.SiniestroTipoId, label: item.SiniestroTipo })
                });
                setDataTipoSiniestro(items);
            })
            .catch(err => {
                console.error(err);
            });
    };
    /* TERMINA CATALOGOS */


    const formatMoney = (value) => {
        if (value === undefined) {
            value = 0;
        }
        let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        return money;
    }

    // const getDateFormat = (fecha, msj) => {
    //     let d = new Date(fecha + "Z");
    //     let format = "";
    //     if (fecha !== undefined && fecha !== "") {
    //         format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2) + (d.getHours() >= 12 ? " PM" : " AM");
    //         return msj + format;
    //     }
    // }
    const submitForm = async (values) => {
        if (values.concluido) {
            if (!values.subido) {
                toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario cargar la evidencia.")}</div>);
                return;
            }
        }
        if (values.concluido) {
            values.siniestroStatusId = 30;
        }

        setLoading(true);
        await Siniestros.siniestroActualizar(values)
            .then(resp => {
                resp.data.unidadDetenida = resp.data.unidadDetenida === null ? null : (resp.data.unidadDetenida ? 1 : 0);
                resp.data.cuentaFolioAseguradora = resp.data.cuentaFolioAseguradora === null ? null : (resp.data.cuentaFolioAseguradora ? 1 : 0);
                resp.data.telefonoFijo = resp.data.telefonoFijo;
                resp.data.telefonoMovil = resp.data.telefonoMovil;
                resp.data.telefonoTrabajo = resp.data.telefonoTrabajo;
                setInitialValue(resp.data);
                getSiniestro();

                toast.success(message("Se actualizó el registro de siniestro correctamente"));
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de siniestro!"));
            })
            .finally(() => {

                if (values.concluido) {
                    crearPagoTramites();
                    crearVualuacionAseguradora();
                }
                if (values.concluido && UnidadDetenida == 1) {
                    crearUnidadDetenida();
                }
                setLoading(false);
            });
    }
    const crearUnidadDetenida = async () => {
        let param = {
            generalId: generalid,
            siniestroUnidadDetenidaStatusId: 10,
            siniestroId: siniestroid
        };

        await Siniestros.siniestroUnidadDetenidaCrear(param);
    }
    const crearPagoTramites = async () => {
        let param = {
            generalId: generalid,
            siniestroPagoTramitesStatusId: 10,
            siniestroId: siniestroid
        };

        await Siniestros.siniestroPagoTramitesCrear(param)
        .then(res=>{
            console.log("pago tramite-->",res)
        }).catch(error=>{
            console.log(error)
        });

    }

    const crearVualuacionAseguradora = async () => {
        let param = {
            generalId: generalid,
            siniestroValuacionAseguradoraStatusId: 10,
            siniestroId: siniestroid
        };

        await Siniestros.siniestroValuacionAseguradoraCrear(param).then(res => {
            console.log(res?.data)
        }).catch(error => {
            console.log(error)
        });
    }



    const validate = Yup.object().shape({
        siniestroTipoId: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        unidadDetenida: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        // cuentaFolioAseguradora: Yup.string()
        //     .when("concluido", {
        //         is: true,
        //         then: Yup.string()
        //             .required("El campo es requerido.")
        //     }),        
        folioAseguradora: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
        correoElectronico: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .trim("El texto no debe empezar ni terminar con espacios")
                    .strict(true)
                    .email("Ingrese un email valido."),
            }),
        telefonoMovil: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string()
                    .required("El campo es requerido.")
                    .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
            }),
        reportaNombre: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        comentarios: Yup.string()
            .when("concluido", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            })

    });
    const handleUploadFile = () => {
        navigate(`/seguro/siniestros/datadocs/fSiniestro/eSiniestro/${state?.folio}/${siniestroid}`, {
            state: { vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }

        });
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">

                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseRegistroSiniestro" role="button" aria-expanded="false" aria-controls="collapseRegistroSiniestro" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Registro de siniestro - {initialValue.folio}</strong></h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.siniestroStatusNombre}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseRegistroSiniestro">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => {
                                        submitForm(values);
                                    }}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Detalle de contacto</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn'
                                                                    type="submit"
                                                                    disabled={formDisabled ? formDisabled : !(isValid)}>
                                                                    Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="reportaNombre"
                                                            disabled={formDisabled}
                                                            label="Nombre de quien reporta"
                                                            holder=""
                                                            name="reportaNombre"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("reportaNombre", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoMovil" disabled={formDisabled} label="Teléfono móvil"
                                                            holder="" name="telefonoMovil" type="text" onChange={(event) => {
                                                                setFieldValue("telefonoMovil", handlePhonNumberFormatter(event.target.value));
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoFijo" disabled={formDisabled} label="Teléfono fijo"
                                                            holder="" name="telefonoFijo" type="text" onChange={(event) => {
                                                                setFieldValue("telefonoFijo", handlePhonNumberFormatter(event.target.value));
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="telefonoTrabajo" disabled={formDisabled} label="Teléfono de trabajo" holder="" name="telefonoTrabajo" type="text" onChange={(event) => {
                                                            setFieldValue("telefonoTrabajo", handlePhonNumberFormatter(event.target.value));
                                                        }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="correoEletronico" disabled={formDisabled} label="Correo electrónico" holder="" name="correoElectronico" type="text" onChange={(event) => {
                                                            setFieldValue("correoElectronico", event.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Detalle de siniestro</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <fieldset disabled={formDisabled} onClick={handleUploadFile} className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-file "></i> Subir documento
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="siniestroTipoId"
                                                            label="Tipo de siniestro"
                                                            name="siniestroTipoId"
                                                            disabled={formDisabled}
                                                            items={dataTipoSiniestro}
                                                            onChange={(event) => {
                                                                setFieldValue("siniestroTipoId", event.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="unidadDetenida"
                                                            label="Unidad detenida por autoridad"
                                                            name="unidadDetenida"
                                                            disabled={formDisabled}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("unidadDetenida", event.value);
                                                                setUnidadDetenida(event.value);
                                                            }} />
                                                    </div>
                                                    {/* <div className="col-12 col-lg-3">
                                                        <SelectField id="cuentaFolioAseguradora"
                                                            label="¿Cuenta con folio aseguradora?"
                                                            name="cuentaFolioAseguradora"
                                                            disabled={formDisabled}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("cuentaFolioAseguradora", event.value);
                                                            }} />
                                                    </div> */}
                                                    {/* {
                                                        values.cuentaFolioAseguradora === 1 && */}
                                                    <div className="col-12 col-lg-3">
                                                        <TextField id="folioAseguradora"
                                                            disabled={formDisabled}
                                                            label="Folio siniestro aseguradora"
                                                            holder="Escribe"
                                                            name="folioAseguradora"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("folioAseguradora", event.target.value);
                                                            }} />
                                                    </div>
                                                    {/* } */}
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios del siniestro"
                                                            disabled={formDisabled}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={formDisabled} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.fechaAlta !== "" &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                }
                                                            </div>
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido &&
                                                                    <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaFin, 'Fin de proceso: ')}</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                initialValue.concluido &&
                <PagoTramitesCard state={state} />
            }
            {
                (initialValue.concluido && UnidadDetenida == 0) &&
                <ValuacionAseguradoraCard state={state} />
            }
            {
                (initialValue.concluido && UnidadDetenida == 1) &&
                <UnidadDetenidaCard state={state} />
            }
            {
                initialValue.concluido &&
                <>
                    <ClienteEntregaUnidadCard />
                    <BoletinarClienteCard />
                    <ClienteDesisteCard />
                </>

            }
        </>
    )
})
export default RegistroSiniestroCard