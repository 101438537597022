import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import BasicModal from '../../../Components/Modal/BasicModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import { SelectField } from '../../../Components/SelectField';
import DataTable from '../../../Components/datatable/DataTable'
import { useEffect } from 'react';
import { Field, Formik } from 'formik'
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import { setUrlSlice } from '../../../Redux/Slice/IncidenciasSlice/urlSlice';
import BuscadorSiniestroVINModal from '../../../Components/Modal/BuscadorSiniestroVINModal';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Llamada from '../../../Services/Cartera/Llamadas';
import { setInfoVin } from '../../../Redux/Slice/IncidenciasSlice/dataVinSlice';
import Garantias from '../../../Services/Garantias/Garantias';
import { Section, SubModule } from '../../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
// import Garantias from 'Services/Garantias/Garantias';

const SeguimientoSiniestros = () => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    //const options = []
    const [dataSiniestroTipo, setDataSiniestroTipo] = useState([]);


    let initial = {
        buscar: null,
        estatus: null,
        fechaInicio: null,
        fechaFin: null,
        siniestroTipoId: null,
        concluido: null,
        page: 1,
        rows: 10
    }

    const [tabs, setTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabInfo, setTabInfo] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState({
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    });

    const columns = [
        { field: 'folio', headerName: 'Folio siniestro Dataware' },
        { field: 'vin', headerName: 'VIN' },
        { field: 'contrato', headerName: 'Contrato' },
        { field: 'marca', headerName: 'Marca' },
        { field: 'modelo', headerName: 'Modelo' },
        { field: 'año', headerName: 'Año' },

        { field: 'siniestroTipo', headerName: 'Tipo siniestro' },
        { field: 'fecha', headerName: 'Fecha del reporte' },
        { field: 'siniestroProceso', headerName: 'Proceso' },
        { field: 'estatus', headerName: 'Estatus' },
        { field: 'subestatus', headerName: 'sub Estatus' },
        { field: 'fechaUltimaActualizacion', headerName: 'Ultima Actualización' }
    ];

    const listStatus = [
        { value: 'Siniestro', label: 'Siniestro' },
        { value: 'Registro', label: 'Registro' },
        { value: 'Cliente entrega unidad', label: 'Cliente entrega unidad' },
        { value: 'Resguardo unidad', label: 'Resguardo unidad' },
        { value: 'Boletinar a cliente', label: 'Boletinar a cliente' },
        { value: 'Cliente desiste', label: 'Cliente desiste' },
        { value: 'Pago de trámites', label: 'Pago de trámites' },
        { value: 'Unidad detenida por autoridad', label: 'Unidad detenida por autoridad' },
        { value: 'Valuación por sseguradora', label: 'Valuación por aseguradora' },
    ];


    useEffect(() => {
        getSiniestroTipo();
    }, []);

    const getSiniestroTipo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroTipo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.SiniestroTipoId, label: item.SiniestroTipo })
                });
                setDataSiniestroTipo(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const seguimientoSiniestros = async (values = initialValue, page = 1) => {
        values.page = page;
        if (!refFormik.current.values.concluido) {
            values.concluido = false;
        }
        setLoading(true);

        await Siniestros.getSeguimientoSiniestro(values)
            .then(resp => {
                let tmpData = [];
                let tabsName = ['Todo'];
                resp.data?.data?.forEach(data => {
                    if (!tabsName.includes(data.siniestroProceso) && data.siniestroProceso !== '') {
                        tabsName.push(data.siniestroProceso);
                    }
                    tmpData.push({
                        generalId: data.generalId,
                        siniestroId: data.siniestroId,
                        vin: data.vin,
                        contrato: data.contrato,
                        marca: data.marca,
                        modelo: data.modelo,
                        año: data.año,
                        folio: data.folio,
                        siniestroProceso: data.siniestroProceso,
                        estatus: data.estatus,
                        subestatus: data.subestatus,
                        fecha: dateFormat(data.fecha),
                        siniestroTipo: data.siniestroTipo,
                        fechaUltimaActualizacion: data.fechaUltimaActualizacion
                    })
                });
                setTabs(tabsName);
                resp.data.data = tmpData;
                setData(resp.data);
                setDataToShow(resp.data);

                let filter = [];
                tabsName.forEach(tab => {

                    if (tab) {
                        if (tab.toLowerCase() === 'todo') {
                            filter['Todo'] = resp.data.data;
                        } else {
                            if (!filter[tab]) {
                                filter[tab] = resp.data.data.filter((item) => item.siniestroProceso === tab);
                            }
                        }
                    }

                })
                setTabInfo(filter);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }

    const dateFormat = (d) => {
        let dateBD = new Date(d + "Z");
        let formattedDate = ("0" + dateBD.getDate()).slice(-2) + "-" + ("0" + dateBD.getMonth()).slice(-2) + "-" + dateBD.getFullYear(); //+" "+("0"+dateBD.getHours()).slice(-2)+":"+("0"+dateBD.getMinutes()).slice(-2)+" hrs.";
        return formattedDate;
    }

    const handleEdit = (item) => {
        const dataUrl = {
            url: location.pathname
        }
        dispatch(setUrlSlice(dataUrl));
        navigate(`/seguro/siniestros/${item.vin}/${item.generalId}/${item.siniestroId}`, {
            state: item
        });
    }

    const [pagina, setPagina] = useState(1);

    const handlePageClick = (event) => {
        let page = event.selected + 1;
        setInitialValue({
            ...initialValue,
            page,
            estatus: refFormik.current.values.estatus,
            concluido: refFormik.current.values.concluido
        });
        setCurrenPage(page);
        setPagina(page);
        seguimientoSiniestros(initialValue, page)
    };

    const getCounter = (tab) => {
        console.log(tabInfo[tab]);
        return tabInfo[tab]?.length || 0;
    };

    const [collpsableGrid, setCollpsableGrid] = useState(false);
    const [busquedaAvanzada, setBusquedaAvanzada] = useState(false);

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={560}>
                        {
                            modalMessage.type === 1 &&
                            <ErrorModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 2 &&
                            <SuccessModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                            />
                        }
                        {
                            modalMessage.type === 3 &&
                            <BuscadorSiniestroVINModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                                handleGetAll={() => { seguimientoSiniestros(initialValue) }}
                            />
                        }
                    </Modal>

                    <section>
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Seguimiento a siniestros</strong>
                                        </div>
                                        <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro).Agregar}>
                                            <div className="col-auto">
                                                <button className='btn' onClick={(e) => { setModalMessage({ isOpen: true, type: 3, title: 'Buscador de VIN' }) }}>
                                                    Registrar siniestro
                                                </button>
                                            </div>
                                        </ValidatePermission>
                                    </div>
                                </div>
                                <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro).Ver}>
                                    <div className="row justify-content-between">
                                        <div className='col-9' >
                                            <div className="row" id="collapseBusqueda">
                                                <div className="col-12 col-md-3 mt-2 mr-1 inner-addon right-addon">
                                                    <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("buscar", event.target.value);
                                                    }} />
                                                </div>
                                                {
                                                    busquedaAvanzada &&
                                                    <>
                                                        <div className="col-12 col-md-3 mt-2 mr-1">
                                                            <SelectField id={'siniestroTipoId'}
                                                                name={'siniestroTipoId'}
                                                                items={dataSiniestroTipo}
                                                                onChange={(event) => {
                                                                    setFieldValue('siniestroTipoId', event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-md-3 mt-2 mr-1">
                                                            <SelectField id={'estatus'}
                                                                name={'estatus'}
                                                                items={listStatus}
                                                                onChange={(event) => {
                                                                    if (event.value === '') {
                                                                        setFieldValue('estatus', null);
                                                                    }
                                                                    else {
                                                                        setFieldValue('estatus', event.value);
                                                                    }

                                                                }} />
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-12 col-md-3 mt-2 mr-1">
                                                    <TextField id="fechaInicio" label="" name="fechaInicio" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaInicio", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-12 col-md-3 mt-2 mr-1">
                                                    <TextField id="fechaFin" label="" name="fechaFin" type="date" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("fechaFin", event.target.value);
                                                    }} />
                                                </div>
                                                <div className="col-12 col-md-auto mt-3 mr-1">
                                                    <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }} />
                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Incluir finalizados</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className="row justify-content-end">
                                                <div className="col-auto col-md-auto mt-2 mr-1">
                                                    <button className='btn btn-blue' onClick={(e) => seguimientoSiniestros(values)}>
                                                        <i className="glyphicon fas fa-search"></i>
                                                    </button>
                                                </div>
                                                <div className="col-auto col-md-auto mt-2 mr-1">
                                                    <button className='btn btn-blue' onClick={(e) => setBusquedaAvanzada(!busquedaAvanzada)}>
                                                        {busquedaAvanzada ? 'Búsqueda Simple' : 'Búsqueda Avanzada'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-12'>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                {
                                                    tabs.map((tab, idx) => {
                                                        return <li className="nav-item" key={'tab_' + idx} role="presentation">
                                                            <button className={`nav-link ${tabIndex == idx ? 'tab-active' : ''}`} value={idx} type="button" role="tab" onClick={(e) => { setTabIndex(idx); setDataToShow(tabInfo[tab]) }}>{tab} ({getCounter(tab)})</button>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                            {
                                                tabIndex !== 0 && dataToShow.length > 0 &&
                                                <DataTable
                                                    column={columns}
                                                    data={dataToShow}
                                                    editable={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro).Actualizar}
                                                    handleEdit={handleEdit}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                                />
                                            }
                                            {
                                                tabIndex === 0 &&
                                                <DataTable
                                                    column={columns}
                                                    data={data?.data}
                                                    editable={ValidatePermissionById(4, Section.SeguimientoSiniestroSection, SubModule.SeguimientoSiniestro).Actualizar}
                                                    handleEdit={handleEdit}
                                                    pageCount={data?.totalPages}
                                                    currentPage={currenPage - 1}
                                                    handlePageClick={handlePageClick}
                                                // pageCount={0}
                                                // currentPage={1 - 1}
                                                />
                                            }
                                        </div>
                                    </div>
                                </ValidatePermission>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoSiniestros;
