import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { SubModule } from '../Auth/Authorization';
import { AuthExpireSesion } from '../Utils/functions';
import AuthLogin from '../Utils/AuthLogin';

const auth = new AuthLogin();

const GestoriaSidebar = () => {

    const navigate = useNavigate();

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }
    return (
        <>
            <aside id="sidebar" className="sidebar">
                <div id="lbl_Seguros_Sidebar" className='fs-2 mb-2'>Gestoría</div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>Gestión de gestoría</strong></li>                   
                    {/* <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PagoRefrendoPlacas)}
                    > */}
                        <li className="nav-item">
                            <NavLink to="/gestoria/seguimiento" className="nav-link collapsed text-dark">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span>Seguimiento de gestoría</span>
                            </NavLink >
                        </li>
                    {/* </ValidatePermission> */}
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PagoRefrendoPlacas)}
                    >
                        <li className="nav-item">
                            <NavLink to="/gestoria/pagorefrendoyplacas" className="nav-link collapsed text-dark">
                                <i className="icon-dark bx bx-dollar-circle"></i>
                                <span>Pago refrendo y placas</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.PendienteEnviarAcendes)}
                    >
                        <li className="nav-item">
                            <NavLink to="/gestoria/pendienteenviaracendes" className="nav-link collapsed text-dark">
                                <i className="icon-dark bx bx-send"></i>
                                <span>Pendiente enviar Acendes</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>                    
                </ul>
            </aside>
        </>
    )
}

export default GestoriaSidebar