import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RolesService from '../../Services/Roles/RolesService';

const useRoles = () => {
  const navigate = useNavigate();

    const columns = [
        { field: 'role', headerName: 'Rol', width: 250 },
        { field: 'descripcion', headerName: 'Descripción', width: 450 },
        { field: 'estatusRol', headerName: 'Estatus', width: 100 }
    ];

    const statusData = [
        { value: "", label: 'Todos' },
        { value: true, label: 'Activo' },
        { value: false, label: 'Inactivo' }
    ];

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [idRol, setIdRol] = useState("");
    const [status, setStatus] = useState("");
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState();
    const [dataRol, setDataRol] = useState([]);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });    
    const [pageRows, setpageRows] = useState(10)
    const [openModalRol, setOpenModalRol] = useState(false);

    useEffect(() => {
        handleGetallRoles(1, pageRows,str);
        setPage(1);
    }, [str,pageRows])

    useEffect(() => {
        getAllRol();
    }, [])



    const handlePageClick = (event) => {
        setPage(event.selected + 1);
        handleGetallRoles(event.selected + 1);
      };

    const handleGetallRoles = async (pag) => {
        setLoading(true);
        await RolesService.getAllRoles(pag, pageRows,str)
            .then(res => {                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const getAllRol = async () => {
        setLoading(true);
        await Usuario.getRoles()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos los Roles"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.idRole,
                        label: item.role
                    })
                });
                setDataRol(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleDetails = (value) =>{
        
        navigate(`/admin/rol/${value.idRole}`, {
            state: {
              page: 'Rol',
              title: "Editar usuario"
            },
          });
    }
    const handleNewRol = () =>{
        navigate(`/admin/usuario/0`, {
            state: {
              page: 'Usuario',
              title: "Nuevo usuario"
            },
          });
    }
    return {
        columns,
        data,
        pageCount,
        dataRol,
        statusData,
        setIdRol,
        setStatus,
        setStr,
        loading,
        setModalMessage,
        modalMessage,
        handlePageClick,
        handleDetails,
        handleNewRol,
        page,
        setpageRows,
        setOpenModalRol,
        openModalRol
    }
}

export default useRoles