import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { AddDays_CurrentDate, message, pageRows } from '../../Utils/functions'
import { useDispatch, useSelector } from 'react-redux'

import ClavesDemoService from '../../Services/Inventario/ClavesDemoService';
import { format } from 'date-fns';

const AgregarClaveDemoModal = ({ modalMessage, setModalMessage, getClaves}) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const todayStr = AddDays_CurrentDate(1);

    const initial = {
        claveDemo: "",
        fechaVencimiento: null,
    }

    const [initialValue, setInitialValue] = useState(initial)

    // Obtén la fecha actual y conviértela a una cadena de texto en el formato 'yyyy-MM-dd'
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    //const todayStr = today.toISOString().split('T')[0];

    
    const validate = Yup.object().shape({
        claveDemo: Yup.string()
            .typeError('Debe ser un texto de 6 dígitos')
            .required('Campo requerido')
            .matches(/^\d{6}$/, 'Debe ser un texto de 6 dígitos numéricos'),
        fechaVencimiento: Yup.string()
            .typeError('Debe ser una fecha válida')
            .required('Campo requerido')
            .test('is-greater', 'La fecha de vencimiento no puede ser anterior a la fecha actual', function(value) {
                const [year, month, day] = value.split('-').map(Number);
                const inputDate = new Date(year, month - 1, day);
                inputDate.setHours(0, 0, 0, 0);
                return inputDate >= today;
            }),
    });

    
    const handlerGuardar = async (values) => {
        await ClavesDemoService.saveClaveDemo(values)
        .then(resp => {
            if(resp.data.data.ban === 1){
                toast.success(message("¡Corrrecto!",resp.data.data.mensaje));
                getClaves();
            }else{
                toast.error(message("¡Error!",resp.data.data.mensaje));
                setModalMessage({ isOpen: false });   
            }       
        })
        .catch(err => {
            toast.error(message("¡Error",err?.message));
            console.error(err);
        });
    }

    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }

    

    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.clave = ""
        initialValue.fecha = ""
        
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const onlyNumbers = (field, value, fn, evt) => {
        const re = /^[0-9\b]+$/;
        console.log(value, re.test(value));
        if (value === ''  || re.test(value) === true) {
            fn(field, value);
        }else{
            let data = value.replace(/(?!-)[^0-9.]/g, "");
            fn(field, data);
        }
    }

    return (
        <>
        
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    claveDemo: true,
                    fechaVencimiento: true
                }}

            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación ingresa la clave de 6 dígitos y su vigencia
                                        </small>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="claveDemo"
                                                disabled={false}
                                                label="Clave"
                                                name="claveDemo"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => {
                                                    setFieldValue("claveDemo", event.target.value);
                                                }} />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField 
                                                id="fechaVencimiento" 
                                                label="Fecha de vencimiento" 
                                                name="fechaVencimiento" 
                                                type="date" 
                                                min={todayStr}
                                                holder="Escribe" 
                                                onChange={(event) => {
                                                    console.log(event.target.value);
                                                    setFieldValue("fechaVencimiento", event.target.value);
                                                    
                                                }}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handlerGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default AgregarClaveDemoModal;
