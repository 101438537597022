import axios from '../../Utils/ApiService';

const ConfigDataDocsService = {
    getAllConfigBinarios:(page,rows,FicheroId,EtapaId)=> {        
        return axios.get(`/functions/GetAllConfigBinario?page=${page}&Rows=${rows}&FicheroId=${FicheroId}&EtapaId=${EtapaId}`);
    }, 
    getAllDocuments:(FicheroId,EtapaId,OrigenId)=> {        
        return axios.get(`/functions/GetAllDocuments?FicheroId=${FicheroId}&EtapaId=${EtapaId}&OrigenId=${OrigenId}`);
    },   
    UpdateFichero:(data)=>{
        return axios.put(`/functions/UpdateConfigFichero`,data);
    },
    AddConfigBinario:(data)=>{
        return axios.post(`/functions/AddConfigBinario`,data);
    },
    UpdConfigBinario:(data)=>{
        return axios.put(`/functions/UpdateConfigBinario`,data);
    },
    DeleteConfigBinario:(binarioId)=>{
        return axios.delete(`/functions/DeleteConfigBinario?BinarioId=${binarioId}`);
    },
    CreateFolderDataDocs:(data)=> {        
        return axios.post(`/functions/CreateFolderDatadocs`,data);
    },
    SaveInfoDataDocs:(data)=>{
        return axios.post(`/functions/AddRelacionBinario`,data);
    },
}

export default ConfigDataDocsService