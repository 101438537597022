import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import InventarioSidebar from '../Components/InventarioSidebar';
import SeguroSidebar from '../Components/SeguroSidebar';
import AuthLogin from '../Utils/AuthLogin';
import { AuthExpireSesion } from '../Utils/functions';
import { useSelector } from 'react-redux';
import { ValidatePermissionById } from '../Auth/ValidatePermission';
import { Module } from '../Auth/Authorization';
import NotFound from '../Pages/NotFound';
const LayoutMenuSeguro = () => {
  const auth = new AuthLogin();
  const usuario = auth.getUser()
  const { visible } = useSelector((state) => state.changeMenu);
  return (
    <>
      {
        (usuario && !AuthExpireSesion()) ?
          <>
            <Header />
            <SeguroSidebar />
            <main id="main" className={`main ${visible ? "noVisible" : "Visible"}`}>
              {
                ValidatePermissionById(1, Module.Seguros) ? <Outlet /> : <NotFound />
              }
            </main>
          </> :<Navigate to={'/'} />
      }
    </>
  )
}

export default LayoutMenuSeguro