import axios from '../../Utils/ApiService';

const Catalogo = {
    getdataTabla: (tableName, page, rows, string) => {
        return axios.get(`/catalogs/${tableName}?page=${page}&rows=${rows}&Buscar=${string}`);
    },
    getdataTipocosto: async (catCosto = null) => {
        return await axios.get(`/catalogs/GetCSTipoCosto?CategoriaCosto=${catCosto}`);
    },
    getdataTipoUbicacion: (catubicacion = null) => {
        return axios.get(`/catalogs/GetCSUbicacion?CategoriaTipoUbicacion=${catubicacion}`);
    },
    getdataCostosById: async (idGeneral) => {
        return await axios.get(`/functions/GetAltaCostos?GeneralId=${idGeneral}`);
    },
    getValorParametro: async (param) => {
        return await axios.get(`/functions/GetParams?Parametro=${param}`);
        // return axios.get(`GetValorParametro?Parametro=${param}`);
    },
    GetAllMunicipio: (page, rows) => {
        return axios.get(`/catalogs/Municipio?page=${page}&rows=${rows}`);
    },
    GetAllUsers: () => {
        return axios.get(`/functions/GetAllUsers`);
    },
    //Aseguradora
    updateAseguradora: (data) => {
        return axios.put(`/catalogs/AseguradoraUpdate`, data);
    },
    addAseguradora: (data) => {
        return axios.post(`/catalogs/AseguradoraAdd`, data);
    },
    updateStatus: (tableName, id, status) => {
        return axios.post(`/catalogs/${tableName}UpdStatus?sid=${id}&status=${status}`);
    },
    //banco
    updateBanco: (data) => {
        return axios.put(`/catalogs/BancoUpdate`, data);
    },
    addBanco: (data) => {
        return axios.post(`/catalogs/BancoAdd`, data);
    },
    //catCosto
    updateCatCosto: (data) => {
        return axios.put(`/catalogs/CategoriaCostoUpdate`, data);
    },
    addCatCosto: (data) => {
        return axios.post(`/catalogs/CategoriaCostoAdd`, data);
    },
    //Cobertura
    updateCobertura: (data) => {
        return axios.put(`/catalogs/CoberturaUpdate`, data);
    },
    addCobertura: (data) => {
        return axios.post(`/catalogs/CoberturaAdd`, data);
    },
    //Etapa
    updateEtapa: (data) => {
        return axios.put(`/catalogs/EtapaUpdate`, data);
    },
    addEtapa: (data) => {
        return axios.post(`/catalogs/EtapaAdd`, data);
    },
    //Extension
    updateExtension: (data) => {
        return axios.put(`/catalogs/ExtensionUpdate`, data);
    },
    addExtension: (data) => {
        return axios.post(`/catalogs/ExtensionAdd`, data);
    },
    //MotivoCS
    updateMotivoCancelacionSeguro: (data) => {
        return axios.put(`/catalogs/MotivoCancelacionSeguroUpdate`, data);
    },
    addMotivoCancelacionSeguro: (data) => {
        return axios.post(`/catalogs/MotivoCancelacionSeguroAdd`, data);
    },
    //MotivoUb
    updateMotivoUbicacion: (data) => {
        return axios.put(`/catalogs/MotivoUbicacionUpdate`, data);
    },
    addMotivoUbicacion: (data) => {
        return axios.post(`/catalogs/MotivoUbicacionAdd`, data);
    },
    //Producto
    updateProducto: (data) => {
        return axios.put(`/catalogs/ProductoUpdate`, data);
    },
    addProducto: (data) => {
        return axios.post(`/catalogs/ProductoAdd`, data);
    },
    //StatusPro
    updateStatusSeguro: (data) => {
        return axios.put(`/catalogs/StatusSeguroUpdate`, data);
    },
    addStatusSeguro: (data) => {
        return axios.post(`/catalogs/StatusSeguroAdd`, data);
    },
    //TipoAd
    updateTipoAdquisicion: (data) => {
        return axios.put(`/catalogs/TAdqUpdate`, data);
    },
    addTipoAdquisicion: (data) => {
        return axios.post(`/catalogs/TAdqAdd`, data);
    },
    //TipoCompra
    updateTipoCompra: (data) => {
        return axios.put(`/catalogs/TipoCompraUpdate`, data);
    },
    addTipoCompra: (data) => {
        return axios.post(`/catalogs/TipoCompraAdd`, data);
    },
    //TipoCompra
    updateTipoExterno: (data) => {
        return axios.put(`/catalogs/TipoExternoUpdate`, data);
    },
    addTipoExterno: (data) => {
        return axios.post(`/catalogs/TipoExternoAdd`, data);
    },
    //TipoPago
    updateTipoPago: (data) => {
        return axios.put(`/catalogs/TipoPagoUpdate`, data);
    },
    addTipoPago: (data) => {
        return axios.post(`/catalogs/TipoPagoAdd`, data);
    },
    //TipoResponsiva
    updateTipoResponsiva: (data) => {
        return axios.put(`/catalogs/TipoResponsivaUpdate`, data);
    },
    addTipoResponsiva: (data) => {
        return axios.post(`/catalogs/TipoResponsivaAdd`, data);
    },
    //TipoUbicacion
    updateTipoUbicacion: (data) => {
        return axios.put(`/catalogs/TipoUbicacionUpdate`, data);
    },
    addTipoUbicacion: (data) => {
        return axios.post(`/catalogs/TipoUbicacionAdd`, data);
    },
    //Transmision
    updateTransmision: (data) => {
        return axios.put(`/catalogs/TransmisionUpdate`, data);
    },
    addTransmision: (data) => {
        return axios.post(`/catalogs/TransmisionAdd`, data);
    },
    //Transmision
    updateUsoUnidad: (data) => {
        return axios.put(`/catalogs/UsoUnidadUpdate`, data);
    },
    addUsoUnidad: (data) => {
        return axios.post(`/catalogs/UsoUnidadAdd`, data);
    },
    //EdoMex
    updateEdoMexico: (data) => {
        return axios.put(`/catalogs/EdoMexicoUpdate`, data);
    },
    addEdoMexico: (data) => {
        return axios.post(`/catalogs/EdoMexicoAdd`, data);
    },
    //EdoMex
    updateCondicion: (data) => {
        return axios.put(`/catalogs/CondicionUpdate`, data);
    },
    addCondicion: (data) => {
        return axios.post(`/catalogs/CondicionAdd`, data);
    },
    //EdoMex
    updateFrecuenciaPago: (data) => {
        return axios.put(`/catalogs/FrecuenciaPagoUpdate`, data);
    },
    addFrecuenciaPago: (data) => {
        return axios.post(`/catalogs/FrecuenciaPagoAdd`, data);
    },
    //Interfaz
    updateInterfaz: (data) => {
        return axios.put(`/catalogs/InterfazUpdate`, data);
    },
    addInterfaz: (data) => {
        return axios.post(`/catalogs/InterfazAdd`, data);
    },
    //Interfaz
    updateTipoFactura: (data) => {
        return axios.put(`/catalogs/TipoFacturaUpdate`, data);
    },
    addTipoFactura: (data) => {
        return axios.post(`/catalogs/TipoFacturaAdd`, data);
    },
    //StatusPro
    updateStatusGarantia: (data) => {
        return axios.put(`/catalogs/StatusGarantiaUpdate`, data);
    },
    addStatusGarantia: (data) => {
        return axios.post(`/catalogs/StatusGarantiaAdd`, data);
    },
    //StatusPro
    updateMotivoGarantia: (data) => {
        return axios.put(`/catalogs/MotivoGarantiaUpdate`, data);
    },
    addMotivoGarantia: (data) => {
        return axios.post(`/catalogs/MotivoGarantiaAdd`, data);
    },
    //StatusPro
    updateMotivoContrato: (data) => {
        return axios.put(`/catalogs/MotivoContratoUpdate`, data);
    },
    addMotivoContrato: (data) => {
        return axios.post(`/catalogs/MotivoContratoAdd`, data);
    },
    //Tipo Comision
    updateTipoComision: (data) => {
        return axios.put(`/catalogs/TipoComisionUpdate`, data);
    },
    addTipoComision: (data) => {
        return axios.post(`/catalogs/TipoComisionAdd`, data);
    },
    //Motivo Cambio
    updateMotivoCambio: (data) => {
        return axios.put(`/catalogs/MotivoCambioUpdate`, data);
    },
    addMotivoCambio: (data) => {
        return axios.post(`/catalogs/MotivoCambioAdd`, data);
    },
    //StatusDatamovil
    updateStatusDatamovil: (data) => {
        return axios.put(`/catalogs/StatusDatamovilUpdate`, data);
    },
    addStatusDatamovil: (data) => {
        return axios.post(`/catalogs/StatusDatamovilAdd`, data);
    },
    //TipoCosto
    updateTipoCosto: (data) => {
        return axios.put(`/catalogs/TipoCostoUpdate`, data);
    },
    addTipoCosto: (data) => {
        return axios.post(`/catalogs/TipoCostoAdd`, data);
    },
    //TipoCosto
    updateColonia: (data) => {
        return axios.put(`/catalogs/ColoniaUpdate`, data);
    },
    addColonia: (data) => {
        return axios.post(`/catalogs/ColoniaAdd`, data);
    },
    //Municipio
    updateMunicipio: (data) => {
        return axios.put(`/catalogs/MunicipioUpdate`, data);
    },
    addMunicipio: (data) => {
        return axios.post(`/catalogs/MunicipioAdd`, data);
    },
    //InventarioExterno
    updateInventarioExterno: (data) => {
        return axios.put(`/catalogs/InventarioExternoUpdate`, data);
    },
    addInventarioExterno: (data) => {
        return axios.post(`/catalogs/InventarioExternoAdd`, data);
    },
    //TipoDocumento
    updateTipoDocumento: (data) => {
        return axios.put(`/catalogs/TipoDocumentoUpdate`, data);
    },
    addTipoDocumento: (data) => {
        return axios.post(`/catalogs/TipoDocumentoAdd`, data);
    },
    //TipoDocumentoUsuario
    updateTipoDocumentoUsuario: (data) => {
        return axios.put(`/catalogs/TipoDocumentoUsuarioUpdate`, data);
    },
    addTipoDocumentoUsuario: (data) => {
        return axios.post(`/catalogs/TipoDocumentoUsuarioAdd`, data);
    },
    //TipoArchivo
    updateTipoArchivo: (data) => {
        return axios.put(`/catalogs/TipoArchivoUpdate`, data);
    },
    addTipoArchivo: (data) => {
        return axios.post(`/catalogs/TipoArchivoAdd`, data);
    },
    //Tribunal
    updateTribunal: (data) => {
        return axios.put(`/catalogs/TribunalUpdate`, data);
    },
    addTribunal: (data) => {
        return axios.post(`/catalogs/TribunalAdd`, data);
    },
    //Tribunal
    updateReglaNegocio: (data) => {
        return axios.put(`/catalogs/ReglaNegocioUpdate`, data);
    },
    addReglaNegocio: (data) => {
        return axios.post(`/catalogs/ReglaNegocioAdd`, data);
    },
    //Empresa
    updateEmpresa: (data) => {
        return axios.put(`/catalogs/EmpresaUpdate`, data);
    },
    addEmpresa: (data) => {
        return axios.post(`/catalogs/EmpresaAdd`, data);
    },
    //RepresentanteLegaal
    updateRepresentanteLegal: (data) => {
        return axios.put(`/catalogs/RepresentanteLegalUpdate`, data);
    },
    addRepresentanteLegal: (data) => {
        return axios.post(`/catalogs/RepresentanteLegalAdd`, data);
    },
    //RepresentanteLegaal
    updatecConfiguracionFichero: (data) => {
        return axios.put(`/catalogs/ConfiguracionFicheroUpdate`, data);
    },
    addcConfiguracionFichero: (data) => {
        return axios.post(`/catalogs/ConfiguracionFicheroAdd`, data);
    },
    getListPeso: () => {
        return axios.get(`/catalogs/GetCSPeso`);
    },
    //RepresentanteLegaal
    updateSocioComercial: (data) => {
        return axios.put(`/catalogs/SocioComercialUpdate`, data);
    },
    addSocioComercial: (data) => {
        return axios.post(`/catalogs/SocioComercialAdd`, data);
    },
    //RepresentanteLegaal
    updateCambioEtapa: (data) => {
        return axios.put(`/catalogs/CambioEtapaUpdate`, data);
    },
    addCambioEtapa: (data) => {
        return axios.post(`/catalogs/CambioEtapaAdd`, data);
    },
    //Ubicacion
    updateUbicacion: (data) => {
        return axios.put(`/catalogs/UbicacionUpdate`, data);
    },
    addUbicacion: (data) => {
        return axios.post(`/catalogs/UbicacionAdd`, data);
    },
    getAllUbicacion: (page, rows) => {
        return axios.get(`/catalogs/Ubicacion?page=${page}&rows=${rows}`);
    },
    // mmunicipioId
    getMunicipiobyId: (id) => {
        return axios.get(`/catalogs/MunicipioByEdo?EdoId=${id}`);
    },
    getColoniaById: (id) => {
        return axios.get(`/catalogs/ColoniaByMunicipio?MunicipioId=${id}`);
    },
    updateVersion: (data) => {
        return axios.put(`/catalogs/VersionUpdate`, data);
    },
    addVersion: (data) => {
        return axios.post(`/catalogs/VersionAdd`, data);
    },
    addAgenteSeguro: (data) => {
        return axios.post(`/catalogs/AddAgenteSeguro`, data);
    },
    UpdAgenteSeguro: (data) => {
        return axios.put(`/catalogs/UpdAgenteSeguro`, data);
    },
    //Sucursal
    updateSucursal: (data) => {
        return axios.put(`/catalogs/UpdSucursales`, data);
    },
    addSucursal: (data) => {
        return axios.post(`/catalogs/AddSucursales`, data);
    },
    //Vendedor
    updateVendedor: (data) => {
        return axios.put(`/catalogs/UpdVendedor`, data);
    },
    addVendedor: (data) => {
        return axios.post(`/catalogs/AddVendedor`, data);
    },
    //MotivoMantener
    updateMotivoMantener: (data) => {
        return axios.put(`/catalogs/UpdMotivoMantener`, data);
    },
    addMotivoMantener: (data) => {
        return axios.post(`/catalogs/AddMotivoMantener`, data);
    },
    //MotivoLiberacion
    updateMotivoLiberacion: (data) => {
        return axios.put(`/catalogs/UpdMotivoLiberacionSeparado`, data);
    },
    addMotivoLiberacion: (data) => {
        return axios.post(`/catalogs/AddMotivoLiberacionSeparado`, data);
    },
    getAllSucursal: () => {
        return axios.get(`/catalogs/GetCSSucursales`);
    },
    // TipoEjecutivio
    updateTipoEjecutivo: (data) => {
        return axios.put(`/catalogs/TipoEjecutivoUpdate`, data);
    },
    addTipoEjecutivo: (data) => {
        return axios.post(`/catalogs/TipoEjecutivoAdd`, data);
    },
    getAllTipoEjecutivo: () => {
        return axios.get(`/catalogs/GetCSTipoEjecutivo`);
    },

    // TipoPromotor
    updateTipoPromotor: (data) => {
        return axios.put(`/catalogs/TipoPromotorUpdate`, data);
    },
    addTipoPromotor: (data) => {
        return axios.post(`/catalogs/TipoPromotorAdd`, data);
    },
    // Procedencia cliente
    updateProcendenciaCliente: (data) => {
        return axios.put(`/catalogs/ProcedenciaClienteUpdate`, data);
    },
    addProcendenciaCliente: (data) => {
        return axios.post(`/catalogs/ProcedenciaClienteAdd`, data);
    },
    // ejecutivo
    updateEjecutivo: (data) => {
        return axios.put(`/catalogs/EjecutivoUpdate`, data);
    },
    addEjecutivo: (data) => {
        return axios.post(`/catalogs/EjecutivoAdd`, data);
    },
    //LiberacionEnganche
    updateLiberacionenganche: (data) => {
        return axios.put(`/catalogs/MotivoLiberacionEngancheUpdate`, data);
    },
    addLiberacionenganche: (data) => {
        return axios.post(`/catalogs/MotivoLiberacionEngancheAdd`, data);
    },
    //Operacion Placas
    updateOperacionPlacas: (data) => {
        return axios.put(`/catalogs/OperacionPlacasUpdate`, data);
    },
    addOperacionPlacas: (data) => {
        return axios.post(`/catalogs/OperacionPlacasAdd`, data);
    },
    //Tecnico
    updateTecnico: (data) => {
        return axios.put(`/catalogs/TecnicoUpdate`, data);
    },
    addTecnico: (data) => {
        return axios.post(`/catalogs/TecnicoAdd`, data);
    },
    getAllTecnico: () => {
        return axios.get(`/catalogs/GetCSTecnico`);
    },
    getAllCSMecanico: () => {
        return axios.get(`/catalogs/GetCSMecanico`);
    },
    
    //motivo baja placas
    updateMotivoBajaPlacas: (data) => {
        return axios.put(`/catalogs/MotivoBajaPlacasUpdate`, data);
    },
    addMotivoBajaPlacas: (data) => {
        return axios.post(`/catalogs/MotivoBajaPlacasAdd`, data);
    },
    getAllMotivoBajaPlacas: () => {
        return axios.get(`/catalogs/GetCSMotivoBajaPlacas`);
    },
    getVehiculoPlacas: (id) => {
        return axios.get(`/functions/GetByIdVehiculoConPlacas?GeneralId=${id}`);
    },
    getdocRevisionUnidad: (id) => {
        return axios.get(`/functions/GetByIdDocRevisionUnidad?GeneralId=${id}`);
    },
    getMotivoLlamada: () => {
        return axios.get(`/catalogs/GetMotivoLlamada`);
    },
    getCarnet: () => {
        return axios.get(`/catalogs/GetCarnet`);
    },
    getClasificacionFalla: () => {
        return axios.get(`/catalogs/GetClasificacionFalla`);
    },
    getTipoReporte: () => {
        return axios.get(`/catalogs/GetTipoReporte`);
    },
    //Motivo liberaciob PM
    updateMotivoLiberacionPM: (data) => {
        return axios.put(`/catalogs/MotivoLiberacionPruebaManejoUpdate`, data);
    },
    addMotivoLiberacionPM: (data) => {
        return axios.post(`/catalogs/MotivoLiberacionPruebaManejoAdd`, data);
    },
    getAllMotivoLiberarPM: () => {
        return axios.get(`/catalogs/GetCSMotivoLiberacionPruebaManejo`);
    },
    //ANombreDe
    updateANombreDe: (data) => {
        return axios.put(`/catalogs/ANombreDeUpd`, data);
    },
    addANombreDe: (data) => {
        return axios.post(`/catalogs/ANombreDeAdd`, data);
    },
    getAllCSANombreDe: () => {
        return axios.get(`/catalogs/GetCSANombreDe`);
    },
    //CategoriaCosto 
    getAllCSCategoriaCosto: () => {
        return axios.get(`/catalogs/GetAllCSCategoriaCosto`);
    },
    //MotivoCancelacionGestoria
    updateMotivoCancelacionGestoria: (data) => {
        return axios.put(`/catalogs/MotivoCancelacionGestoriaUpd`, data);
    },
    addMotivoCancelacionGestoria: (data) => {
        return axios.post(`/catalogs/MotivoCancelacionGestoriaAdd`, data);
    },
    getAllCSMotivoCancelacionGestoria: () => {
        return axios.get(`/catalogs/GetCSMotivoCancelacionGestoria`);
    },
    //MotivoGarantiaCancelacion
    getMotivoCancelacion: () => {
        return axios.get(`/catalogs/MotivoGarantiaCancelacion`);
    },
    //MotivoCancelacionGestoria
    updateMotivoCancelacionEntrega: (data) => {
        return axios.put(`/catalogs/MotivoCancelacionEntregaUpd`, data);
    },
    addMotivoCancelacionEntrega: (data) => {
        return axios.post(`/catalogs/MotivoCancelacionEntregaAdd`, data);
    },
    getAllCSMotivoCancelacionEntrega: () => {
        return axios.get(`/catalogs/GetCSMotivoCancelacionEntrega`);
    },
    //TipoExpediente
    updateTipoExpediente: (data) => {
        return axios.put(`/catalogs/TipoExpedienteUpd`, data);
    },
    addTipoExpediente: (data) => {
        return axios.post(`/catalogs/TipoExpedienteAdd`, data);
    },
    getAllCSTipoExpediente: () => {
        return axios.get(`/catalogs/GetCSTipoExpediente`);
    },
    //MotivoCambioProducto
    updateMotivoCambioProducto: (data) => {
        return axios.put(`/catalogs/MotivoCambioProductoUpd`, data);
    },
    addMotivoCambioProducto: (data) => {
        return axios.post(`/catalogs/MotivoCambioProductoAdd`, data);
    },
    getAllCSMotivoCambioProducto: () => {
        return axios.get(`/catalogs/GetCSMotivoCambioProducto`);
    },
    //
    getAllTipoGarantia: () => {
        return axios.get(`/catalogs/TipoGarantia`);
    },
    getDropdownsByTable:(table, active = true)=>{
        // return axios.get(`/catalogs/ConsultasRaw?query=${table}&soloActivos=${active}`);
        return axios.get(`/catalogs/ConsultasRaw?query=${table}`);
    },
    //MotivoNoRenovacionSeguro
    updateMotivoNoRenovacionSeguro: (data) => {
        return axios.put(`/catalogs/MotivoNoRenovacionSeguroUpdate`, data);
    },
    addMotivoNoRenovacionSeguro: (data) => {
        return axios.post(`/catalogs/MotivoNoRenovacionSeguroAdd`, data);
    },
    getAllCSMotivoNoRenovacionSeguro: () => {
        return axios.get(`/catalogs/GetCSMotivoNoRenovacionSeguro`);
    },
    mantenimientoCatalogos: (data) => {
        return axios.post(`/catalogs/mantenimientoCatalogos`, data);
    },
    getAllCSConceptos: (id) => {
        return axios.get(`/functions/GetCSAllConceptos?GeneralId=${id}`);
    },
    // Revision de unidad
    updateRevisionDeUnidad: (data) => {
        return axios.put(`/catalogs/RevisionDeUnidadUpd`, data);
    },
    addRevisionDeUnidad: (data) => {
        return axios.post(`/catalogs/RevisionDeUnidadAdd`, data);
    },
    getAllCSRevisionDeUnidad: () => {
        return axios.get(`/catalogs/GetCSRevisionDeUnidad`);
    },
    //MEcanico
    updateMecanico: (data) => {
        return axios.put(`/catalogs/MecanicoUpdate`, data);
    },
    addMecanico: (data) => {
        return axios.post(`/catalogs/MecanicoAdd`, data);
    },
    getAllMecanico: () => {
        return axios.get(`/catalogs/GetCSMecanico`);
    },
    getMarcas: () => {
        return axios.get(`/catalogs/Marca`);
    },
    getModelos: (data) => {
        return axios.get(`/catalogs/Modelo?ClaveMarca=${data}`);
    },
    getYear: (data) => {
        return axios.get(`/catalogs/Year?ClaveMarca=${data.claveMarca}&ClaveModelo=${data.claveModelo}`);
    },
    getVersion: (data) => {
        return axios.get(`/catalogs/Version?ClaveMarca=${data.claveMarca}&ClaveModelo=${data.claveModelo}&ClaveYear=${data.claveYear}`);
    },
    ubicacionPorCP: (data) => {
        return axios.get(`/catalogs/ubicacionPorCP?codigoPostal=${data}`);
    },
    getSucursalesByTipo: (data) => {
        return axios.get(`/catalogs/getsucursalesbytipo?TipoUbicacion=${data}`);
    },
    getAllEtapaPorFichero: (ficheroId) => {
        return axios.get(`/catalogs/GetCSEtapasPorfichero?FicheroId=${ficheroId}`);
    },
    //Externo
    getAllCSTipoExterno: () => {
        return axios.get(`/catalogs/GetCSTipoExterno`);
    },
    //Uso CFDI
    getAllUsoCFDI: (RFId) => {
        return axios.get(`/catalogs/GetCSUsoCFDIById?RegimenFiscalId=${RFId}`);
    },
    updateUsoCFDI: (data) => {
        return axios.put(`/catalogs/UsoCFDIUpdate`, data);
    },
    addUsoCFDI: (data) => {
        return axios.post(`/catalogs/UsoCFDIAdd`, data);
    },
    //RegimenFiscal
    updateRegimenFiscal: (data) => {
        return axios.put(`/catalogs/RegimenFiscalUpdate`, data);
    },
    addRegimenFiscal: (data) => {
        return axios.post(`/catalogs/RegimenFiscalAdd`, data);
    },
    getAllRFbyId:(Id)=>{
        return axios.get(`/catalogs/RegimenFiscalById?UsoCFDIId=${Id}`);
    },
    //TipoCalculo
    updateTipoCalculo: (data) => {
        return axios.put(`/catalogs/TipoCalculoUpdate`, data);
    },
    addTipoCalculo: (data) => {
        return axios.post(`/catalogs/TipoCalculoAdd`, data);
    },
    //Clasificacion Vendedor
    updateClasificacionVendedor: (data) => {
        return axios.put(`/catalogs/ClasificacionVendedorUpdate`, data);
    },
    addClasificacionVendedor: (data) => {
        return axios.post(`/catalogs/ClasificacionVendedorAdd`, data);
    },
    //Sucursal Vendedor
    updateSucursalVendedor: (data) => {
        return axios.put(`/catalogs/SucursalVendedorUpdate`, data);
    },
    addSucursalVendedor: (data) => {
        return axios.post(`/catalogs/SucursalVendedorAdd`, data);
    },
    //UbicacionDestino
    updateUbicacionDestino: (data) => {
        return axios.put(`/catalogs/UbicacionDestinoUpdate`, data);
    },
    addUbicacionDestino: (data) => {
        return axios.post(`/catalogs/UbicacionDestinoAdd`, data);
    },
}

export default Catalogo