import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import Datamovil from '../../Services/Resguardos/Datamovil';
import Valuaciones from '../../Services/Valuaciones/Valuaciones';
import { useParams } from 'react-router-dom';
import Gestoria from '../../Services/Gestoria/Gestoria';

const ClavesModal = ({ modalMessage, setModalMessage, vin }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const initial = {}

    const [initialValue, setInitialValue] = useState(initial)
    // const { id, generalid, valuacionid } = useParams()
    const [claves, setClaves] = useState([])
    const [clavesDemo, setClavesDemo] = useState({});




    useEffect(() => {
        getClaves();
        ClavesDemoGet();
    }, []);

    const getClaves = async () => {
        let params = { vin: vin }
        await Valuaciones.getReporteClaves(params)
            .then(resp => {
                let items = []
                resp.data.forEach(item => {
                    const vencimiento = item.vencimiento;
                    let date;
                    if(typeof vencimiento === 'object'){
                        date = ("0" + vencimiento.day).slice(-2) + '-' + ("0" + (vencimiento.month - 2)).slice(-2) + '-' + vencimiento.year;
                    }else{
                        date = vencimiento.split("-").reverse().join("-");
                    }
                    items.push({ clave: item.clave, vigencia: date, tipoSolicitud: item.tipo_solicitud })
                })

                setClaves(items)

            }
            )
            .catch(err => {
                console.error(err)
            })
    };

    const ClavesDemoGet = async () => {
        await Gestoria.ClavesDemoGet()
        .then(resp => {
            resp.data.fechaVencimiento = resp.data.fechaVencimiento.split("T")[0];
            setClavesDemo(resp.data);
        })
        .catch(err => {
            console.error(err);
        });
    }


    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }


    const handleRegresar = () => {
        refFormik.current.resetForm();
        initialValue.aplicaCosto = ""
        initialValue.reparacionExterna = ""
        initialValue.tallerId = ""
        initialValue.aplicaGarantia = ""
        setModalMessage({ ...modalMessage, isOpen: false })
    }

    const validate = {}

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const cveReq = {
        background: '#E8EAF6',
        color: '#1A3D7E',
        width: '50px',
        height: '50px',
        borderRadius: '4px',
        padding: '8px',
        margin: '5px 2px'
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}


            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={() => handleRegresar()}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row">
                                        <small id="AT_TxtBasicModalMenssage">
                                            A continuación se muestra el tipo de solicitud, la clave y su vigencia del vehículo previamente seleccionado.
                                        </small>
                                    </div>
                                    {
                                        claves.map((clave, idx) => {
                                            return <>
                                                <div className="row mt-2" key={`claves_${idx}`}>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="row mb-2">
                                                            <span className="col-12">Clave</span>
                                                        </div>
                                                        {
                                                            clave.clave !== null &&
                                                            clave.clave?.split('').map((item, i) => {
                                                                return <>
                                                                    <span style={cveReq} key={`item_${i}`}>
                                                                        {item}
                                                                    </span>
                                                                </>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <span>Vigencia de la clave</span>
                                                        <div style={containerStyle}>
                                                            {
                                                                clave.vigencia !== null &&
                                                                clave.vigencia?.split('-').reverse().join('-')
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <span>Tipo de solicitud</span>
                                                        <div style={containerStyle}>
                                                            {
                                                                clave.tipoSolicitud
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }
                                    <div className="row mt-2">
                                        <div className="col-lg-4 col-12">
                                            <div className="row mb-2">
                                                <span className="col-12">Clave Demo</span>
                                            </div>
                                            {
                                                clavesDemo.claveDemo !== null &&
                                                clavesDemo.claveDemo?.split('').map((item, i) => {
                                                    return <>
                                                        <span style={cveReq} key={`item_${i}`}>
                                                            {item}
                                                        </span>
                                                    </>
                                                })
                                            }
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <span>Vigencia de la clave</span>
                                            <div style={containerStyle}>
                                                {
                                                    clavesDemo.fechaVencimiento !== null &&
                                                    clavesDemo.fechaVencimiento
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cerrar
                                        </button>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default ClavesModal;
