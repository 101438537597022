import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import InventarioSidebar from '../Components/InventarioSidebar';
import AuthLogin from '../Utils/AuthLogin';
import { useDispatch, useSelector } from 'react-redux';
import { chekItem } from '../Redux/Slice/quickActionsSlice';
import { AuthExpireSesion } from '../Utils/functions';
import NotFound from '../Pages/NotFound';
import { ValidatePermissionById } from '../Auth/ValidatePermission';
import { Module } from '../Auth/Authorization';
const LayoutMenuInventario = () => {
  const dispatch = useDispatch();
  const auth = new AuthLogin();
  const usuario = auth.getUser()
  useEffect(() => {
    dispatch(chekItem({ check: false, item: null }));
  }, [])
  const { visible } = useSelector((state) => state.changeMenu);
  return (
    <>
      {
        (usuario && !AuthExpireSesion()) ?
          <>
            <Header />
            <InventarioSidebar />
            <main id="main" className={`main ${visible ? "noVisible" : "Visible"}`}>
              {
                ValidatePermissionById(1, Module.Inventario) ? <Outlet /> : <NotFound/>
              }
            </main>
          </>:<Navigate to={'/'} />
    }
    </>
  )
}

export default LayoutMenuInventario