import React from 'react'
import DataTable from '../../Components/datatable/DataTable'
import useInterfaces from '../../Hooks/Logs/useInterfaces'
import Modal from '../../Components/Modal/Modal'
import ErrorModal from '../../Components/Modal/ErrorModal'
import SuccessModal from '../../Components/Modal/SuccessModal'
import Spinner from '../../Components/Loadig'
import Select from 'react-select'
import SubmenuSync from './SubmenuSync'
import useEliminarIntelimotor from '../../Hooks/SyncInventario/useSyncIntelimotorEliminar'
import { pageRows } from '../../Utils/functions'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'

const InvEliminarIntelimotor = () => {

    let {
        columns,
        data,
        loading,
        modalMessage,
        setModalMessage,
        handleCheck,
        pageCount,
        handlePageClick,
        setStr,
        setDateTime,
        initialPage,
        refBtn,
        setpageRows,
        DataMarca,
        DataModelo,
        handleChangeMarca,
        setClaveModelo,
        handleClick
    } = useEliminarIntelimotor();

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <SubmenuSync />
            <section className='mx-5 my-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehiculos pendientes a eliminar</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.EliminarIntelimotor, SubModule.ConfiguracionDeInventario).Ver}>
                        <div>
                            {/* <button className='icon btn btn-outline mx-2' onClick={handlepageDetail}><i className="bi bi-trash-fill" style={{ color: '#004c97' }}></i></button>
                        <button className='btn px-4 me-1' ref={refBtnExp} onClick={handleExportFile}>Exportar</button> */}
                            <button className='btn px-4 ms-1' ref={refBtn} onClick={handleClick}>Eliminar</button>
                        </div>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>A continuación se muestra el listado de vehículos pendientes a eliminar en Intelimotor.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.EliminarIntelimotor, SubModule.ConfiguracionDeInventario).Ver}>
                <section className='mt-5 mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onChange={(e) => { setStr(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1">
                            <input type="date" className="form-control mb-4" onChange={(e) => { setDateTime(e.target.value) }} />
                        </div>
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                        <Select options={dataProceso} className='col-12' onChange={(e) => { setProcesoId(e.value) }} placeholder="Resultados" />
                    </div> */}
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataMarca} className='col-12' onChange={(e) => { handleChangeMarca(e.value) }} placeholder="Marca" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataModelo} className='col-12' onChange={(e) => { setClaveModelo(e.value) }} placeholder="Modelo" />
                        </div>
                    </div>

                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handleCheck={handleCheck}
                        paginate={true}
                        handlePageClick={handlePageClick}
                        checkbox={true}
                        currentPage={initialPage}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default InvEliminarIntelimotor