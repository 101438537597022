import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import { chekItem, isOpenModalCheck } from '../Redux/Slice/quickActionsSlice';

const ProcesosSidebar = () => {
    const dispatch = useDispatch();
  const {cheked,item,isOpenModalClave} = useSelector((state) => state.quickActionsSlice);      
    return (
        <>
            <aside id="sidebar" className="sidebar">
                <div id="lbl_Inventario_Sidebar" className='fs-2 mb-2'>Procesos de cierre</div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>Gestión cierre de contrato</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/resguardos" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-lock"></i>
                            <span>Seguimiento resguardo</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/cierres" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-list-check bx-sm"></i>
                            <span>Seguimiento de cierre</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/datamovil" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-remote-control-fill"></i>
                            <span>Seguimiento datamovil</span>
                        </NavLink >
                    </li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/procesocierre/valuaciones" className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-remote-control-fill"></i>
                            <span>Seguimiento valuaciones</span>
                        </NavLink >
                    </li>
                    {/* <li className="nav-heading"><strong>Gestión de citas</strong></li>
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/autorizar`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-line"></i>
                            <span>Citas por autorizar</span>
                        </NavLink >
                    </li>    
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/citas/agenda`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-check-line"></i>
                            <span>Agenda de citas</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/inspeccionrapida`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-calendar-line"></i>
                            <span>Seguimiento de garantías</span>
                        </NavLink >
                    </li> 
                    <li className={`nav-item`}>
                        <NavLink to={`/garantias/devoluciones`} className="nav-link collapsed text-dark">
                            <i className="icon-dark ri-refresh-fill"></i>
                            <span>Cambio o devolución</span>
                        </NavLink >
                    </li> */}
                </ul>
            </aside>
        </>
    )
}

export default ProcesosSidebar