import React from 'react'
import Usuario from '../../Services/Users/Usuarios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useUsuario = () => {
  const navigate = useNavigate();

    const columns = [
        { field: 'nombreUsuario', headerName: 'Nombre', width: 350 },
        { field: 'email', headerName: 'Correo', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 160 },
        { field: 'role', headerName: 'Rol', width: 200 },
        { field: 'nombreSucursal', headerName: 'Sucursal', width: 200 },
        { field: 'tipoPermisos', headerName: 'Privilegio', width: 200 }
    ];

    const statusData = [
        { value: "", label: 'Todos' },
        { value: true, label: 'Activo' },
        { value: false, label: 'Inactivo' }
    ];

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [idRol, setIdRol] = useState("");
    const [status, setStatus] = useState("");
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState();
    const [dataRol, setDataRol] = useState([]);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });    
    const [pageRows, setpageRows] = useState(10)
    useEffect(() => {
        handleGetallUsers(1, pageRows, idRol, status, str);
        setPage(1);
    }, [str, idRol, status,pageRows])

    useEffect(() => {
        getAllRol();
    }, [])



    const handlePageClick = (event) => {
        setPage(event.selected + 1);
        handleGetallUsers(event.selected + 1);
      };

    const handleGetallUsers = async (pag) => {
        setLoading(true);
        await Usuario.getUsuario(pag, pageRows, idRol, status, str)
            .then(res => {
                
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const getAllRol = async () => {
        setLoading(true);
        await Usuario.getRoles()
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "",
                    label: "Todos los Roles"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.idRole,
                        label: item.role
                    })
                });
                setDataRol(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    const handleDetails = (value) =>{
        
        navigate(`/admin/usuario/${value.userId}`, {
            state: {
              page: 'Usuario',
              title: "Editar usuario"
            },
          });
    }
    const handleNewuser = () =>{
        navigate(`/admin/usuario/0`, {
            state: {
              page: 'Usuario',
              title: "Nuevo usuario"
            },
          });
    }
    return {
        columns,
        data,
        pageCount,
        dataRol,
        statusData,
        setIdRol,
        setStatus,
        setStr,
        loading,
        setModalMessage,
        modalMessage,
        handlePageClick,
        handleDetails,
        handleNewuser,
        page,
        setpageRows
    }
}

export default useUsuario