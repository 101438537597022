import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../Components/TextAreaField';
import { getDateFormat, message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import DataTable from '../../../Components/datatable/DataTable';
import ReparacionUnidadModal from '../../../Components/Modal/ReparacionUnidadModal';
import { TextField } from '../../../Components/TextField';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const ClienteEntregaUnidadCard
    = React.forwardRef(({ ...props }, ref) => {
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, generalid, siniestroid } = useParams();


        let initial = {
            color: 'default',
            proceso: "",
            siniestroClienteEntregaUnidadId: "",
            siniestroId: "",
            siniestroClienteEntregaUnidadStatusId: "",
            siniestroClienteEntregaUnidadStatus: "",
            siniestroClienteEntregaUnidadMotivoId: "",
            siniestroClienteEntregaUnidadMotivo: "",
            comentarios: "",
            fechaAlta: "",
            fechaActualiza: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: ""

        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [dataClienteEntregaUnidadMotivo, setDataClienteEntregaUnidadMotivo] = useState([]);

        useEffect(() => {
            getSiniestroClienteEntregaUnidadMotivo();
            getSiniestroClienteEntregaUnidad();

        }, []);


        /* CONSULTAS */
        const getSiniestroClienteEntregaUnidad = async () => {
            setLoading(true);
            await Siniestros.siniestroClienteEntregaUnidadGet(siniestroid)
                .then(resp => {
                    resp.data.fechaAlta = resp.data.fechaAlta?.split("T")[0];
                    resp.data.fechaActualiza = resp.data.fechaActualiza?.split("T")[0];
                    resp.data.fechaInicio = resp.data.fechaInicio?.split("T")[0];
                    resp.data.fechaFin = resp.data.fechaFin?.split("T")[0];
                    resp.data.comentarios = resp.data.comentarios ?? "";
                    resp.data.siniestroClienteEntregaUnidadMotivoId = resp.data.siniestroClienteEntregaUnidadMotivoId ?? "";
                    setInitialValue(resp.data);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        const getSiniestroClienteEntregaUnidadMotivo = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.SiniestroClienteEntregaUnidadMotivo')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.SiniestroClienteEntregaUnidadMotivoId, label: item.SiniestroClienteEntregaUnidadMotivo })
                    });
                    setDataClienteEntregaUnidadMotivo(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };
        /* TERMINA CATALOGOS */


        const formatMoney = (value) => {
            if (value === undefined) {
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const submitForm = async (values, modal) => {

            if (values.concluido) {
                values.siniestroStatusId = 30;
            }


            if (values.siniestroClienteEntregaUnidadId !== "") {
                setLoading(true);
                await Siniestros.siniestroClienteEntregaUnidadActualizar(values)
                    .then(resp => {
                        if (resp.data.data.ban === 1) {
                            toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                            getSiniestroClienteEntregaUnidad();
                        } else {
                            toast.error(message("¡Error.!", resp.data.data.mensaje));
                        }                    
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error(message("¡Error, al intentar actualizar el registro de entrega de unidad!"));
                    })
                    .finally(() => {
                        if (values.concluido) {
                            //crearReprogramacion();
                        }
                        setLoading(false);
                    });
            } else {
                setLoading(true);
                await Siniestros.siniestroClienteEntregaUnidadCrear(values)
                    .then(resp => {
                        if (resp.data.data.ban === 1) {
                            toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                            getSiniestroClienteEntregaUnidad();
                        } else {
                            toast.error(message("¡Error.!", resp.data.data.mensaje));
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error(message("¡Error, al intentar actualizar el registro de entrega de unidad!"));
                    })
                    .finally(() => {

                        if (values.concluido) {
                            //crearReprogramacion();
                        }
                        setLoading(false);
                    });
            }
        }

        const validate = Yup.object({
            siniestroClienteEntregaUnidadMotivoId: Yup.string()
                .required("El campo es requerido."),
            comentarios: Yup.string()
                .required("El campo es requerido."),
        });

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseClienteEntregaUnidad" role="button" aria-expanded="false" aria-controls="collapseClienteEntregaUnidad" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Cliente entrega la Unidad</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.siniestroClienteEntregaUnidadStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseClienteEntregaUnidad">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                        onSubmit={(values) => {
                                            submitForm(values);
                                        }}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <Form>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        type='submit'
                                                                        disabled={!(isValid)}
                                                                    >
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="siniestroClienteEntregaUnidadMotivoId"
                                                                label="Motivo de la entrega de la unidad"
                                                                name="siniestroClienteEntregaUnidadMotivoId"
                                                                items={dataClienteEntregaUnidadMotivo}
                                                                disabled={initialValue?.concluido}
                                                                onChange={(event) => {
                                                                    setFieldValue("siniestroClienteEntregaUnidadMotivoId", event.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <TextAreaField id="comentarios"
                                                                label="Comentarios"
                                                                disabled={initialValue?.concluido}
                                                                className="form-control col-12"
                                                                rows="3"
                                                                name="comentarios"
                                                                type="text"
                                                                holder="Escribe"
                                                                onChange={(event) => {
                                                                    setFieldValue("comentarios", event.target.value);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-6">
                                                            <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                                setFieldValue("concluido", event.target.value);
                                                            }} />
                                                            <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.fechaAlta !== "" &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaInicio, 'Inicio de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                                <div className="col-auto">
                                                                    {
                                                                        initialValue.concluido &&
                                                                        <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaFin, 'Fin de proceso: ')}</small>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </>
                                        )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default ClienteEntregaUnidadCard