import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { TextField } from '../../../Components/TextField';
import { TextAreaField } from '../../../Components/TextAreaField';
import { getDateFormat, message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../../../Components/datatable/DataTable';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import ValuacionAseguradoraCard from './ValuacionAseguradoraCard';

const UnidadDetenidaCard
    = React.forwardRef(({ ...props }, ref) => {
        const [collpsableGrua, setCollpsableGrua] = useState(false);
        const { refFormik } = useRef();
        const { id, generalid, siniestroid } = useParams();
        const navigate = useNavigate();
        let { state } = props;
        const { fechaEntrega,
            nombreYear,
            vin,
            color,
            nombreMarca,
            kilometraje,
            nombreModelo,
            procedenciaAuto,
            nombreVersion,
            numContrato,
            numSolicitud } = useSelector((state) => state.DataVinSlice);

        let initial = {
            color: "default",
            siniestroUnidadDetenidaId: "",
            siniestroUnidadDetenidaStatusId: "",
            siniestroId: "",
            siniestroUnidadDetenidaUbicacionTipoId: "",
            nombreUbicacion: "",
            ubicacionCalleNumero: "",
            ubicacionCodigoPostal: "",
            ubicacionPais: "",
            ubicacionEstadoId: "",
            ubicacionMunicipioId: "",
            ubicacionColoniaId: "",
            oficioLiberacion: "",
            comentarios: "",
            oficioLiberacionRegistrado: "",
            unidadEnCorralon: "",
            corralonCalleNumero: "",
            corralonCodigoPostal: "",
            corralonPais: "",
            corralonEstadoId: "",
            corralonMunicipioId: "",
            corralonColoniaId: "",
            unidadLiberada: "",
            fechaAlta: "",
            fechaActualiza: "",
            usuarioAlta: "",
            usuarioActualiza: "",
            status: "",
            fechaConclusion: "",
            concluido: "",
            siniestroUnidadDetenidaStatus: 'Pendiente',
            siniestroUnidadDetenidaUbicacionTipo: "",
            proceso: "",
            usuarioAltaNombre: "",
            usuarioActualizaNombre: "",
            ubicacionEstado: "",
            ubicacionMunicipio: "",
            ubicacionColonia: "",
            corralonEstado: "",
            corralonMunicipio: "",
            corralonColonia: "",
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];
        const [disabled, setDisabled] = useState(false);
        const [dataUbicacionTipo, setDataUbicacionTipo] = useState([]);

        const [dataEstado, setDataEstado] = useState([]);
        const [dataMun, setDataMun] = useState([]);
        const [dataColonia, setDataColonia] = useState([]);

        const [dataEstado2, setDataEstado2] = useState([]);
        const [dataMun2, setDataMun2] = useState([]);
        const [dataColonia2, setDataColonia2] = useState([]);



        useEffect(() => {
            handleGetAllSelects();
            getSiniestroUnidadDetenida();
        }, []);

        const handleGetAllSelects = async () => {
            setLoading(true)
            const [] = await Promise.all([handleGetAllEstado(), getSiniestroUnidadDetenidaUbicacionTipo()]).finally(() => {
                setLoading(false)
            });
        }
        const handleGetAllEstado = async () => {
            await Catalogo
                .getdataTabla("EdoMExico", 1, 100)
                .then(res => {

                    let NewData = [];
                    res.data.data?.map(item => {
                        NewData.push({
                            value: item.edoMexicoId,
                            label: item.estado
                        })
                    });
                    setDataEstado(NewData);
                    setDataEstado2(NewData);
                }).catch(e => {

                })
        }
        const handleGetAllMunicipioById = async (id, tipo) => {
            if (id === "") {
                setDataMun([]);
                return;
            }
            await Catalogo.getMunicipiobyId(id)
                .then(res => {
                    let NewData = [];
                    res.data.data?.map(item => {
                        NewData.push({
                            value: item.municipioId,
                            label: item.municipio
                        })
                    });
                    if (tipo === 1)
                        setDataMun(NewData);
                    else
                        setDataMun2(NewData);
                }).catch(e => {

                })
        }
        const handleGetAllColoniaById = async (id, tipo) => {
            if (id === '') {
                setDataColonia([])
                return;
            }
            await Catalogo.getColoniaById(id)
                .then(res => {
                    let NewData = [];
                    res.data.data?.map(item => {
                        NewData.push({
                            value: item.coloniaId,
                            label: item.colonia
                        })
                    });
                    if (tipo === 1)
                        setDataColonia(NewData);
                    else
                        setDataColonia2(NewData);
                }).catch(e => {

                })
        }
        /* CONSULTAS */
        const getSiniestroUnidadDetenida = async () => {
            setLoading(true);
            await Siniestros.siniestroUnidadDetenidaGet(siniestroid)
                .then(resp => {
                    let item = resp.data;                                                  
                    let newData = {                        
                        color: item?.color ?? "default",
                        siniestroUnidadDetenidaId: item?.siniestroUnidadDetenidaId ?? "",
                        siniestroUnidadDetenidaStatusId: item?.siniestroUnidadDetenidaStatusId ?? "",
                        siniestroId: item?.siniestroId ?? "",
                        siniestroUnidadDetenidaUbicacionTipoId: item?.siniestroUnidadDetenidaUbicacionTipoId ?? "",
                        nombreUbicacion: item?.nombreUbicacion ?? "",
                        ubicacionCalleNumero: item?.ubicacionCalleNumero ?? "",
                        ubicacionCodigoPostal: item?.ubicacionCodigoPostal ?? "",
                        ubicacionEstadoId: item?.ubicacionEstadoId ?? "",
                        ubicacionMunicipioId: item?.ubicacionMunicipioId ?? "",
                        ubicacionColoniaId: item?.ubicacionColoniaId ?? "",
                        oficioLiberacion: item?.oficioLiberacion ?? "",
                        comentarios: item?.comentarios ?? "",
                        oficioLiberacionRegistrado: item?.oficioLiberacionRegistrado ?? "",
                        unidadEnCorralon: item?.unidadEnCorralon ?? "",
                        corralonCalleNumero: item?.corralonCalleNumero ?? "",
                        corralonCodigoPostal: item?.corralonCodigoPostal ?? "",
                        corralonEstadoId: item?.corralonEstadoId ?? "",
                        corralonMunicipioId: item?.corralonMunicipioId ?? "",
                        corralonColoniaId: item?.corralonColoniaId ?? "",
                        unidadLiberada: item?.unidadLiberada ?? "",
                        // fechaAlta: "",
                        // fechaActualiza: "",
                        // usuarioAlta: "",
                        // usuarioActualiza: "",
                        // status: "",
                        // fechaConclusion: "",
                        concluido: item?.concluido ?? false,
                        siniestroUnidadDetenidaStatus: item?.siniestroUnidadDetenidaStatus ?? 'Pendiente',
                        siniestroUnidadDetenidaUbicacionTipo: item?.siniestroUnidadDetenidaUbicacionTipo ?? "",
                        proceso: item?.proceso ?? "",
                        // usuarioAltaNombre: "",
                        // usuarioActualizaNombre: "",
                        // ubicacionEstado: "",
                        // ubicacionMunicipio: "",
                        // ubicacionColonia: "",
                        // corralonEstado: "",
                        // corralonMunicipio: "",
                        // corralonColonia: "",
                        codEstatus: item?.codEstatus ?? 2,  //2=Cerrado
                        codSubStatus: item?.codSubStatus === null || item.codEstatus === undefined ? 1 : item.codSubStatus >= 3 ? 2 : item.codSubStatus, //1=inicial 2= liberando 
                        subido:item?.subido
                    }
                    console.log("unidadDetenida",newData)
                    if (initialValue.codSubStatus !== 1) {                        
                        handleGetAllMunicipioById(item?.ubicacionEstadoId, 1);
                        handleGetAllColoniaById(item?.ubicacionMunicipioId, 1);
                        if(item?.corralonEstadoId && item?.corralonMunicipioId){
                            handleGetAllMunicipioById(item?.corralonEstadoId, 2);
                            handleGetAllColoniaById(item?.corralonMunicipioId, 2);
                        }                  
                    }
                    // let data = { ...resp.data, codEstatus: 1, codSubStatus: 2 }
                    // data.fechaAlta = resp?.data?.fechaAlta?.split("T")[0] ?? "";
                    // data.fechaActualiza = resp.data?.fechaActualiza?.split("T")[0] ?? "";
                    // data.fechaConclusion = resp.data?.fechaFin?.split("T")[0] ?? "";
                    // data.nombreUbicacion = resp.data?.nombreUbicacion ?? "";
                    // data.color = resp.data.color ?? "default";
                    // data.siniestroUnidadDetenidaStatus = data.siniestroUnidadDetenidaStatus ?? 'Pendiente';

                    setInitialValue(newData);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        };
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        const getSiniestroUnidadDetenidaUbicacionTipo = async () => {
            await Catalogo.getDropdownsByTable('Catalogos.SiniestroUnidadDetenidaUbicacionTipo')
                .then(resp => {
                    let items = [];
                    resp.data.forEach(item => {
                        items.push({ value: item.SiniestroUnidadDetenidaUbicacionTipoId, label: item.SiniestroUnidadDetenidaUbicacionTipo })
                    });
                    setDataUbicacionTipo(items);
                })
                .catch(err => {
                    console.error(err);
                });
        };

        /* TERMINA CATALOGOS */

        const submitForm = async (values) => {
            if (values.concluido) {
                if (!values.oficioLiberacion) {
                    toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario cargar la evidencia.")}</div>);
                    return;
                }
            }

            if (values.unidadLiberada) {
                // setFieldValue("siniestroUnidadDetenidaStatusId", 30) ;
                // setFieldValue("concluido", true) ;
                values.siniestroUnidadDetenidaStatusId = 30;
                values.concluido = true;

            }

            setLoading(true);
            await Siniestros.siniestroUnidadDetenidaActualizar(values)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        setLoading(false);
                        toast.success(message("Correcto!", res.data.data.mensaje));
                        getSiniestroUnidadDetenida()
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error!", res.data.data.mensaje));
                    }

                    // resp.data.fechaAlta = resp.data.fechaAlta?.split("T")[0];
                    // resp.data.fechaActualiza = resp.data.fechaActualiza?.split("T")[0];
                    // resp.data.fechaConclusion = resp.data.fechaConclusion?.split("T")[0];
                    // setInitialValue(resp.data);

                    // toast.success(message("Se actualizó el registro de unidad deteninda correctamente"));


                })
                .catch(err => {
                    console.error(err);
                    toast.error(message("¡Error, al intentar actualizar el registro de unidad detenida!"));
                })
                .finally(() => {

                    if (values.concluido) {

                        //crearVualuacionAseguradora();
                    }
                    setLoading(false);
                });
        }

        const crearVualuacionAseguradora = async () => {
            let param = {
                generalId: generalid,
                siniestroValuacionAseguradoraStatusId: 10,
                siniestroId: siniestroid
            };

            await Siniestros.siniestroValuacionAseguradoraCrear(param);
        }

        const validate = Yup.object({

            siniestroUnidadDetenidaUbicacionTipoId: Yup.string()
                .required("El campo es requerido."),
            nombreUbicacion: Yup.string()
                .required("El campo es requerido."),
            ubicacionCalleNumero: Yup.string()
                .required("El campo es requerido."),
            ubicacionCodigoPostal: Yup.string()
                .required("El campo es requerido."),
            ubicacionEstadoId: Yup.string()
                .required("El campo es requerido."),
            ubicacionMunicipioId: Yup.string()
                .required("El campo es requerido."),
            ubicacionColoniaId: Yup.string()
                .required("El campo es requerido."),
            //Detalle de unidad liberada
            comentarios: Yup.string()
                .when("codSubStatus", {
                    is: 2,
                    then: Yup.string()
                        .required("El campo es requerido.")
                }),
            unidadEnCorralon: Yup.string()
                .when("codSubStatus", {
                    is: 2,
                    then: Yup.string()
                        .required("El campo es requerido.")
                }),
            corralonCalleNumero: Yup.string()
                .when(["unidadEnCorralon", "codSubStatus"], (unidadEnCorralon, codSubStatus) => {
                    if (unidadEnCorralon == 1 && codSubStatus === 2) {
                        return Yup.string().test({
                            name: 'is-sku',
                            skipAbsent: true,
                            test(value, ctx) {
                                if (value === undefined)
                                    return ctx.createError({ message: 'El campo es requerido' })
                                return true
                            }
                        })
                    }

                }),
            corralonCodigoPostal: Yup.string()
                .when(["unidadEnCorralon", "codSubStatus"], (unidadEnCorralon, codSubStatus) => {
                    if (unidadEnCorralon == 1 && codSubStatus === 2) {
                        return Yup.string().test({
                            name: 'is-sku',
                            skipAbsent: true,
                            test(value, ctx) {
                                if (value === undefined)
                                    return ctx.createError({ message: 'El campo es requerido' })
                                return true
                            }
                        })
                    }

                }),
            corralonEstadoId: Yup.string()
                .when(["unidadEnCorralon", "codSubStatus"], (unidadEnCorralon, codSubStatus) => {
                    if (unidadEnCorralon == 1 && codSubStatus === 2) {
                        return Yup.string().test({
                            name: 'is-sku',
                            skipAbsent: true,
                            test(value, ctx) {
                                if (value === undefined)
                                    return ctx.createError({ message: 'El campo es requerido' })
                                return true
                            }
                        })
                    }

                }),
            corralonMunicipioId: Yup.string()
                .when(["unidadEnCorralon", "codSubStatus"], (unidadEnCorralon, codSubStatus) => {
                    if (unidadEnCorralon == 1 && codSubStatus === 2) {
                        return Yup.string().test({
                            name: 'is-sku',
                            skipAbsent: true,
                            test(value, ctx) {
                                if (value === undefined)
                                    return ctx.createError({ message: 'El campo es requerido' })
                                return true
                            }
                        })
                    }

                }),
            corralonColoniaId: Yup.string()
                .when(["unidadEnCorralon", "codSubStatus"], (unidadEnCorralon, codSubStatus) => {
                    if (unidadEnCorralon == 1 && codSubStatus === 2) {
                        return Yup.string().test({
                            name: 'is-sku',
                            skipAbsent: true,
                            test(value, ctx) {
                                if (value === undefined)
                                    return ctx.createError({ message: 'El campo es requerido' })
                                return true
                            }
                        })
                    }

                }),
        });

        const containerStyle = {
            width: '100%',
            height: '37px',
            background: '#F5F5F5',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
            alignItems: 'center',
            padding: '8px', // Cambiado el valor 'box' a '8px'
            borderRadius: '4px',
        };


        const formatMoney = (value) => {
            if (value === undefined) {
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }
        const handleUploadFile = () => {
            navigate(`/seguro/siniestros/datadocs/fSiniestro/eSiniestro/${state?.folio}/${siniestroid}`, {
                state: { vin: vin, nombreMarca: nombreMarca, nombreModelo: nombreModelo, nombreVersion: nombreVersion, nombreYear: nombreYear }
            });
        }
        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseUnidadDetenidaAutoridad" role="button" aria-expanded="false" aria-controls="collapseUnidadDetenidaAutoridad" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                    <div className="col-11">
                                        <h6><span className={`semaforo semaforo-${initialValue.color}`}></span> <strong>Unidad detenida por autoridad</strong></h6>
                                    </div>
                                    <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                        <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                    </div>
                                    <div className='col-12'>
                                        <small>{initialValue.siniestroUnidadDetenidaStatus}</small>
                                    </div>
                                </div>
                                <div className="collapse" id="collapseUnidadDetenidaAutoridad">
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                        onSubmit={(values) => {
                                            // if (values.dictamenRegistrado && !values.concluido)
                                            //     values.codSubStatus = 3;
                                            // else if (values.dictamenRegistrado && values.concluido)
                                            //     values.codSubStatus = 3;
                                            // else
                                            values.codSubStatus = 2;      
                                            if(values.unidadLiberada)      
                                                values.codSubStatus = 3;      
                                            submitForm(values);
                                        }}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>                                         
                                                <Form>
                                                    <div className="separator"></div>
                                                    <div className="row mt-2 px-4 mb-2">
                                                        <div className="col-3">
                                                            <small><strong>Ubicación de unidad</strong></small>
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="row justify-content-end">
                                                                <div className="col-auto">
                                                                    <button
                                                                        className='btn'
                                                                        type='submit'
                                                                        disabled={!(isValid)}
                                                                    // disabled={initialValue.siniestroUnidadDetenidaStatusId === 30}
                                                                    >
                                                                        Guardar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField id="siniestroUnidadDetenidaUbicacionTipoId"
                                                                label="Tipo de ubicación"
                                                                disabled={initialValue?.concluido}
                                                                name="siniestroUnidadDetenidaUbicacionTipoId"
                                                                items={dataUbicacionTipo}
                                                                onChange={(event) => {
                                                                    setFieldValue("siniestroUnidadDetenidaUbicacionTipoId", event.value);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="nombreUbicacion" disabled={initialValue?.concluido} label="Nombre de la ubicación" holder="Nombre de la ubicación" name="nombreUbicacion" type="text" onChange={(event) => {
                                                                setFieldValue("nombreUbicacion", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 px-4">
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="ubicacionCalleNumero" disabled={initialValue?.concluido} label="Calle y número" holder="Calle y número" name="ubicacionCalleNumero" type="text" onChange={(event) => {
                                                                setFieldValue("ubicacionCalleNumero", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <TextField id="ubicacionCodigoPostal" disabled={initialValue?.concluido} label="Código postal" holder="CP" name="ubicacionCodigoPostal" type="text" onChange={(event) => {
                                                                setFieldValue("ubicacionCodigoPostal", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField
                                                                id="ubicacionEstadoId"
                                                                label="Estado"
                                                                disabled={initialValue?.concluido}
                                                                name="ubicacionEstadoId"
                                                                items={dataEstado}
                                                                onChange={(event) => {
                                                                    setFieldValue("ubicacionEstadoId", event.value);                                                                    
                                                                    setFieldValue("ubicacionMunicipioId", "");
                                                                    setFieldValue("ubicacionColoniaId", "");
                                                                    handleGetAllMunicipioById(event.value, 1);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField
                                                                id="ubicacionMunicipioId"
                                                                label="Municipio"
                                                                disabled={initialValue?.concluido}
                                                                name="ubicacionMunicipioId"
                                                                items={dataMun}
                                                                onChange={(event) => {
                                                                    setFieldValue("ubicacionMunicipioId", event.value);                                                                    
                                                                    setFieldValue("ubicacionColoniaId", "");
                                                                    handleGetAllColoniaById(event.value, 1);
                                                                }} />
                                                        </div>
                                                        <div className="col-12 col-lg-3">
                                                            <SelectField
                                                                id="ubicacionColoniaId"
                                                                label="Colonia"
                                                                disabled={initialValue?.concluido}
                                                                name="ubicacionColoniaId"
                                                                items={dataColonia}
                                                                onChange={(event) => {
                                                                    setFieldValue("ubicacionColoniaId", event.value);                                                                    
                                                                }} />
                                                        </div>
                                                    </div>
                                                    {
                                                        values.codSubStatus === 2 &&
                                                        <>
                                                            <div className="row mt-2 px-4 mb-2">
                                                                <div className="col-auto">
                                                                    <small><strong>Detalle de unidad liberada</strong></small>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4 mb-2">
                                                                <div className="col-auto">
                                                                    <small>Oficio de liberación</small>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4 mb-2">
                                                                <div className="col-auto">
                                                                    <fieldset
                                                                        disabled={initialValue?.concluido}
                                                                        onClick={handleUploadFile}
                                                                        className='btn btn-gray'>
                                                                        <i className="icon-light fa fa-file "></i> Subir documento
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <TextAreaField id="comentarios"
                                                                        label="Comentarios"
                                                                        disabled={initialValue?.concluido}
                                                                        className="form-control col-12"
                                                                        rows="3"
                                                                        name="comentarios"
                                                                        type="text"
                                                                        holder="Escribe"
                                                                        onChange={(event) => {
                                                                            setFieldValue("comentarios", event.target.value);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">                                                               
                                                                <div className="col-12 col-lg-6">
                                                                    <Field className="form-check-input" disabled={!values?.subido} type="checkbox" name="oficioLiberacion" onClick={(event) => {
                                                                        setFieldValue("oficioLiberacion", event.target.value);
                                                                    }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Oficio de liberación registrado en sistema</label>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4 mb-2">
                                                                <div className="col-auto">
                                                                    <small><strong>Unidad detenida en corralón</strong></small>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-3">
                                                                    <SelectField id="unidadEnCorralon"
                                                                        label="¿Unidad en corralón?"
                                                                        disabled={initialValue?.concluido}
                                                                        name="unidadEnCorralon"
                                                                        items={opcionesBool}
                                                                        onChange={(event) => {
                                                                            setFieldValue("unidadEnCorralon", event.value);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                            {
                                                                values.unidadEnCorralon == 1 &&
                                                                <div className="row mt-2 px-4">
                                                                    <div className="col-12 col-lg-3">
                                                                        <TextField id="corralonCalleNumero" disabled={initialValue?.concluido} label="Calle y número" holder="Calle y número" name="corralonCalleNumero" type="text" onChange={(event) => {
                                                                            setFieldValue("corralonCalleNumero", event.target.value);
                                                                        }} />
                                                                    </div>
                                                                    <div className="col-12 col-lg-3">
                                                                        <TextField id="corralonCodigoPostal" disabled={initialValue?.concluido} label="Código postal" holder="CP" name="corralonCodigoPostal" type="text" onChange={(event) => {
                                                                            setFieldValue("corralonCodigoPostal", event.target.value);
                                                                        }} />
                                                                    </div>
                                                                    <div className="col-12 col-lg-3">
                                                                        <SelectField
                                                                            id="corralonEstadoId"
                                                                            label="Estado"
                                                                            disabled={initialValue?.concluido}
                                                                            name="corralonEstadoId"
                                                                            items={dataEstado2}
                                                                            onChange={(event) => {
                                                                                setFieldValue("corralonEstadoId", event.value);                                                                                
                                                                                setFieldValue("corralonMunicipioId", "");   
                                                                                setFieldValue("corralonColoniaId", "");
                                                                                handleGetAllMunicipioById(event.value, 2);
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-12 col-lg-3">
                                                                        <SelectField
                                                                            id="corralonMunicipioId"
                                                                            label="Municipio"
                                                                            disabled={initialValue?.concluido}
                                                                            name="corralonMunicipioId"
                                                                            items={dataMun2}
                                                                            onChange={(event) => {
                                                                                setFieldValue("corralonMunicipioId", event.value);                                                                                
                                                                                setFieldValue("corralonColoniaId", "");
                                                                                handleGetAllColoniaById(event.value, 2);
                                                                            }} />
                                                                    </div>
                                                                    <div className="col-12 col-lg-3">
                                                                        <SelectField
                                                                            id="corralonColoniaId"
                                                                            label="Colonia"
                                                                            disabled={initialValue?.concluido}
                                                                            name="corralonColoniaId"
                                                                            items={dataColonia2}
                                                                            onChange={(event) => {
                                                                                setFieldValue("corralonColoniaId", event.value);                                                                                
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div className="row mt-2 px-4">
                                                                <div className="col-12 col-lg-6">
                                                                    <Field className="form-check-input" disabled={initialValue?.concluido} type="checkbox" name="unidadLiberada" onClick={(event) => {
                                                                        setFieldValue("unidadLiberada", event.target.value);
                                                                        setFieldValue("concluido", event.target.value);
                                                                    }} />
                                                                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Unidad liberada</label>
                                                                </div>
                                                                <div className="col-12 col-lg-6">
                                                                    <div className="row justify-content-end">
                                                                        <div className="col-auto">
                                                                            {
                                                                                initialValue.fechaAlta !== "" &&
                                                                                <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaAlta, 'Inicio de proceso: ')}</small>
                                                                            }
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            {
                                                                                initialValue.concluido &&
                                                                                <small className='text-body-tertiary'>{getDateFormat(initialValue.fechaConclusion, 'Fin de proceso: ')}</small>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </Form>
                                            </>
                                        )
                                        }
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    initialValue.concluido &&
                    <ValuacionAseguradoraCard state={state} />
                }
            </>
        )
    })
export default UnidadDetenidaCard