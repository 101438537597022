import React from 'react'
import { pageRows } from '../Utils/functions'
import Select from 'react-select'

const RowPages = ({setpageRows}) => {
    return (
        <div>
            <div className='d-flex justify-content-end align-items-center row col-12 px-4 mt-2'>
                <div className='col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end'>
                    <span style={{ fontSize: '12px' }} className=''>Resultado por pagina</span>
                </div>
                <div className='col-lg-1 col-md-6 col-sm-8 col-12 p-0'>
                    <Select options={pageRows()} className='col-lg-10' defaultValue={{ value: 10, label: 10 }} onChange={(e) => { setpageRows(e.value) }} />
                </div>
            </div>
        </div>
    )
}

export default RowPages