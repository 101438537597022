import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import { chekItem, isOpenModalCheck } from '../Redux/Slice/quickActionsSlice';

const CobranzaSidebar = () => {
    const dispatch = useDispatch();
  const {cheked,item,isOpenModalClave} = useSelector((state) => state.quickActionsSlice);      
    return (
        <>
            <aside id="sidebar" className="sidebar">
                <div id="lbl_Inventario_Sidebar" className='fs-2 mb-2'>Procesos de cierre</div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-heading"><strong>Gestión de cobranza</strong></li>
                    <li className="nav-item" onClick={()=>{dispatch(chekItem({check:false,item:null}))}}>
                        <NavLink to="/cobranza/accesorios" className="nav-link collapsed text-dark">
                            <i className="icon-dark bx bx-credit-card-front"></i>
                            <span>Financiamiento de accesorios</span>
                        </NavLink >
                    </li>
                </ul>
            </aside>
        </>
    )
}

export default CobranzaSidebar