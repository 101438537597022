import React from 'react';
import '../Components/Loading.css';

const Spinner = ({message}) => {
    return(
        <>
        <div className="loader-background">
            <div className="loader"></div>
            <span className='message'>{message}</span>
        </div>         
        </>
    )
}

export default Spinner