import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, pageRows } from '../../../Utils/functions'
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import { SelectField } from '../../../Components/SelectField';
import Valuaciones from '../../../Services/Valuaciones/Valuaciones';
import DataTable from '../../../Components/datatable/DataTable';
import ReparacionUnidadModal from '../../../Components/Modal/ReparacionUnidadModal';
import Siniestros from '../../../Services/Siniestro/Siniestro';
import AuthLogin from '../../../Utils/AuthLogin';
import axios from 'axios';
import dataDocsServices from '../../../Services/DataDocs/dataDocsServices';
import VisorFile from '../../../Components/VisorFile';

const auth = new AuthLogin();
const InformacionSeguroCard
    = React.forwardRef(({ ...props }, ref) => {
        const { refFormik } = useRef();
        const { id, generalid } = useParams();
        let url = auth.UrlDataDocs();


        let initial = {
            numeroPoliza: '',
            fechaInicio: null,
            fechaFin: null,
            aseguradora: '',
            telefono: '',
            diasVigentes: null,
            cobertura: '',
            idFolder: "",
            documentoId: "",
            nombreDocumento: ""
        }

        const [initialValue, setInitialValue] = useState(initial);
        const [loading, setLoading] = useState(false);
        const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

        const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

        const [fileName, setFileName] = useState("");
        const [isOpenDocs, setIsOpenDocs] = useState(false)
        const [SelectedDocs, setSelectedDocs] = useState([]);

        useEffect(() => {
            getSiniestroInformacionSeguro();
        }, []);

        const dummy = {
            dias: 100
        }


        /* CONSULTAS */
        const getSiniestroInformacionSeguro = async () => {
            setLoading(true);

            await Siniestros.getSiniestroInformacionSeguro(generalid)
                .then(resp => {
                    // resp.data.fechaInicio = resp.data.fechaInicio?.split("T")[0];
                    // resp.data.fechaFin = resp.data.fechaFin?.split("T")[0];
                    resp.data.telefono = setFormatPhone(resp.data.telefono);
                    setInitialValue(resp.data);                    
                }).catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        }
        /*  TERMINA CONSULTAS */

        /* CATALOGOS */
        /* TERMINA CATALOGOS */

        const setFormatPhone = (value) => {

            if (value) {
                let prevPhone = value.replace(/[()-]/g, '');
                let formatted = "";

                if (prevPhone.length < 3) {
                    formatted = prevPhone;
                } else if (prevPhone.length >= 3 && prevPhone.length <= 6) {
                    let lada = prevPhone.substr(0, 2);
                    let firstSegment = prevPhone.substr(2, 4);
                    formatted = "(" + lada + ")-" + firstSegment;
                } else if (prevPhone.length > 6) {
                    let lada = prevPhone.substr(0, 2);
                    let firstSegment = prevPhone.substr(2, 4);
                    let secondSegment = prevPhone.substr(6, 4);
                    formatted = "(" + lada + ")-" + firstSegment + "-" + secondSegment;
                }

                return formatted;
            } else {
                return value;
            }


        };

        const formatMoney = (value) => {
            if (value === undefined) {
                value = 0;
            }
            let money = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
            return money;
        }

        const getDateFormat = (fecha, msj) => {
            let d = new Date(fecha + "Z");
            let format = "";
            if (fecha !== undefined && fecha !== "") {
                format = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                return msj + format;
            }
        }



        const validate = Yup.object({});

        const styleSuccess = {
            padding: '5px 15px',
            backgroundColor: '#D8F2CD',
            color: '#47A066',
            minWidth: '110px',
        }
        const styleWarning = {
            padding: '5px 15px',
            backgroundColor: '#fae7bc',
            color: '#b46b16',
            minWidth: '110px',
        }
        const styleError = {
            padding: '5px 15px',
            backgroundColor: '#F5CECE',
            color: '#CE2222',
            minWidth: '110px',
        }
        const handleGetTokenDatadocs = async () => {
            await dataDocsServices.UpdateTokenDataDc()
                .then(res => {
                    auth.handleAuthSesionDataDocs(res.data.data);
                }).catch(e => { })
        }
        const handleViewdocs = async () => {
            setLoading(true);
            setFileName(initialValue.nombreDocumento);
            await handleGetTokenDatadocs();
            await axios.post(`${url}Documento/GetFileById/${initialValue.idFolder}/${initialValue.documentoId}/false`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + auth.tokenDataDocs(),
                },
                responseType: "blob"
            })
                .then(res => {
                    var file = new File([res.data], res.data.name, { type: res.data.type });
                    setSelectedDocs([file])
                    setLoading(false);
                    setIsOpenDocs(true)
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("Error.!", e.response.data.errors?.error));
                    else
                        toast.error(message("Error.!", e.message));
                })
        }

        return (
            <>
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                <Modal isOpen={modalMessage.isOpen} color='#fff' width={660}>
                    {
                        modalMessage.type === 1 &&
                        <ErrorModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                    {
                        modalMessage.type === 2 &&
                        <SuccessModal
                            modalMessage={modalMessage}
                            setModalMessage={setModalMessage}
                        />
                    }
                </Modal>

                {
                    isOpenDocs &&
                    <VisorFile
                        isOpen={isOpenDocs}
                        setIsOIpen={setIsOpenDocs}
                        fileName={fileName}
                        doc={SelectedDocs}
                    />
                }
                <Toaster
                    position="top-right"
                    toastOptions={{
                        success: {
                            style: {
                                background: '#47a066',
                                color: '#FFFF',
                                borderLeft: '10px solid #2f7246'
                            },
                        },
                        error: {
                            style: {
                                background: '#d53f3f',
                                color: '#FFFF',
                                borderLeft: '10px solid #ac241a'
                            },
                        },
                    }}
                />
                <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                    <div className='row mx-md-4 my-4' >
                        <div className="col-12 px-md-3">
                            <div className="p-4 white-wrapper mb-2">

                                <div className="row mt-2 px-4">
                                    <div className="col-11">
                                        <h6><strong>Información de seguro</strong></h6>
                                    </div>
                                </div>
                                <div>
                                    <Formik
                                        innerRef={refFormik}
                                        enableReinitialize={true}
                                        validationSchema={validate}
                                        initialValues={initialValue}
                                    >
                                        {({ isValid, values, setFieldValue, errors }) => (
                                            <>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Detalle del seguro</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    onClick={() => { handleViewdocs() }}
                                                                    className='btn btn-gray'>
                                                                    <i className="icon-light fa fa-eye "></i> Ver documentos
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Póliza:</strong> {initialValue.numeroPoliza}</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small><strong>Aseguradora:</strong> {initialValue.aseguradora}</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small><strong>Teléfono Aseguradora:</strong> {initialValue.telefono}</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small><strong>Cobertura:</strong> {initialValue.cobertura}</small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Fecha Inicio Póliza:</strong> {initialValue.fechaInicio}</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small><strong>Fecha Fin Póliza:</strong> {initialValue.fechaFin}</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small><strong>Vigencia: </strong>
                                                            {
                                                                initialValue.diasVigentes > 60 &&
                                                                <span style={styleSuccess}><small>{initialValue.diasVigentes} días</small></span>
                                                            }
                                                            {
                                                                initialValue.diasVigentes <= 60 &&
                                                                initialValue.diasVigentes > 30 &&
                                                                <span style={styleWarning}><small>{initialValue.diasVigentes} días</small></span>
                                                            }
                                                            {
                                                                initialValue.diasVigentes <= 30 &&
                                                                initialValue.diasVigentes > 1 &&
                                                                <span style={styleError}><small>{initialValue.diasVigentes} días</small></span>
                                                            }
                                                            {
                                                                initialValue.diasVigentes <= 1 &&
                                                                initialValue.diasVigentes > 0 &&
                                                                <span style={styleError}><small>{initialValue.diasVigentes} día</small></span>
                                                            }
                                                            {
                                                                initialValue.diasVigentes === 0 &&
                                                                <span style={styleError}><small>{initialValue.diasVigentes} días</small></span>
                                                            }
                                                        </small>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
export default InformacionSeguroCard