import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import AuthLogin from '../Utils/AuthLogin';
import { AuthExpireSesion } from '../Utils/functions';
import GestoriaSidebar from '../Components/GestoriaSidebar';
import { ValidatePermissionById } from '../Auth/ValidatePermission';
import { Module } from '../Auth/Authorization';
import NotFound from '../Pages/NotFound';
const LayoutMenuGestoria = () => {
  const auth = new AuthLogin();
  const usuario = auth.getUser()

  return (

    <>
      {
        (usuario && !AuthExpireSesion())  ?
          <>
            <Header />
            <GestoriaSidebar />
            <main id="main" className="main">
              {
                ValidatePermissionById(1, Module.Gestoria) ? <Outlet /> : <NotFound />
              }
            </main>
          </> : <Navigate to={'/'} />
      }
    </>
  )
}

export default LayoutMenuGestoria