import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import AuthLogin from '../Utils/AuthLogin';
import { AuthExpireSesion } from '../Utils/functions';
import { useSelector } from 'react-redux';
import useClave from '../Hooks/Login/useClave';
import { ValidatePermissionById } from '../Auth/ValidatePermission';
import { Module } from '../Auth/Authorization';
import NotFound from '../Pages/NotFound';

const auth = new AuthLogin();
const LayoutMenu = () => {
  const usuario = auth.getIngresarSistema()
  const { visible } = useSelector((state) => state.changeMenu);
  return (
    <>
      {
        (usuario && !AuthExpireSesion()) ?
        <>
          <Header />
          <Sidebar />
          <main id="main" className={`main ${visible ? "noVisible" : "Visible"}`}>
            {
              ValidatePermissionById(1, Module.PanelAdministrativo) ? <Outlet /> : <NotFound/>
            }
          </main>
        </>:<Navigate to={'/'} />

      }
    </>
  )
}

export default LayoutMenu