import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { SelectField } from '../../Components/SelectField';
import Usuario from '../../Services/Users/Usuarios';
import { TextField } from '../../Components/TextField';
import * as Yup from 'yup';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import SubmenuUser from './SubmenuUser';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import TablaReglas from './TablaReglas';
import SocioComercialServices from '../../Services/Inventario/SocioComercialService';

const UsuarioAddEdit = () => {
    let { id } = useParams();
    const { value } = useSelector((state) => state.tabNavigateUser);
    let initial = {
        userId: "",
        nombreUsuario: "",
        email: "",
        status: true,
        idRol: "",
        sucursalId: "",
        numeroEmpleado: "",
        password: "",
        socioId: "",
        nombreRol: ""
    }
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        numeroEmpleado: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .max(150, "Maximo 150 caracteres"),
        nombreUsuario: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            // .matches(
            //     "^[a-zA-Z]+$",
            //     // "La información ingresada es incorrecta."
            // )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .max(150, "Maximo 150 caracteres"),
        email: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .email("ingrese un email valido."),
        password: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .min(8, "La contraseña debe tener 8 caracteres")
            .max(8, "La contraseña debe tener 8 caracteres"),
        idRol: Yup.string()
            .required("El campo es requerido."),
        sucursalId: Yup.string()
            .required("El campo es requerido."),
        socioId: Yup.string()
            .when("nombreRol", (nombreRol) => {
                if (nombreRol == "DMSocioComercial") {
                    return Yup.string()
                        .required("El campo es requerido.");
                }
            }),
    });

    const { state } = useLocation();
    const navigate = useNavigate();

    const [dataRol, setDataRol] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataSuc, setDataSuc] = useState([]);
    const [dataModulo, setDataModulo] = useState([]);
    const [curretnRol, setCurretnRol] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });
    const [str, setStr] = useState("");
    const [ModuloId, setModuloId] = useState('0');
    const [SocioComercial, setDtaSocioComercial] = useState([]);

    useEffect(() => {
        getAllRol();
        hangleGEtSucuarsal();
        if (id != 0) {
            handleGetUserById(id);
        }
    }, [])

    useEffect(() => {
        if (curretnRol !== "") {
            handleGetModuleByrol(curretnRol, value);
            setModuloId("0")
        }
        else
            setDataModulo([])
    }, [curretnRol, value])

    useEffect(() => {

        if (ModuloId != '0' && curretnRol !== "") {
            handlegetSecctionByMmodule(ModuloId);
        } else {
            setDataTable([]);
        }
    }, [str, ModuloId, curretnRol])


    const getAllRol = async () => {
        setLoading(true);
        await Usuario.getRoles()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.idRole,
                        label: item.role
                    })
                });
                setDataRol(NewData);
                setLoading(false);
                // handleGetModuleByrol(NewData.idRole,value);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetUserById = async (id) => {
        setLoading(true);
        await Usuario.getUsuarioById(id)
            .then(res => {

                let item = res.data.data;
                let initial = {
                    userId: item?.userId ?? "",
                    nombreUsuario: item.nombreUsuario ?? "",
                    email: item?.email ?? "",
                    status: item?.status ?? false,
                    idRol: item?.idRole ?? "",
                    sucursalId: item?.sucursalId ?? "",
                    numeroEmpleado: item?.numeroEmpleado ?? "",
                    password: item?.password ?? "",
                    socioId: item?.socioComercialId ?? "",
                    nombreRol: item?.role ?? ""
                }
                setInitialValue(initial);
                setCurretnRol(item.idRole);
                // handleGetModuleByrol(item.idRole,value);}
                handleGetAllSC({value:"",label:item?.role})
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const hangleGEtSucuarsal = async () => {
        setLoading(true);
        await Usuario.getSucursal(1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.sucursalId,
                        label: item.nombre
                    })
                });
                setDataSuc(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handleGetModuleByrol = async (rol, menu) => {
        // if (rolId == null) {
        //     let NewData = [];
        //     NewData.push({
        //         value: "0",
        //         label: "Seleccione"
        //     })
        //     setDataModulo(NewData);
        //     return
        // }
        setLoading(true);

        await Usuario.getMmoduleByRol(value)
            .then(res => {
                let NewData = [];
                NewData.push({
                    value: "0",
                    label: "Seleccione"
                })
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.idSubModulo,
                        label: item.subModulo
                    })
                });
                setDataModulo(NewData);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }
    const handlegetSecctionByMmodule = async (idModulo) => {
        if (idModulo == '0') {
            setDataTable([]);
            return
        }

        setLoading(true);
        if (id != 0) {
            await Usuario.getsectionByUser(id, idModulo, curretnRol)
                .then(res => {
                    setDataTable(res.data.data);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else {
            await Usuario.getSectionByMModule(str, idModulo, curretnRol)
                .then(res => {
                    setDataTable(res.data.data);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }

    }
    //checkbox change state
    const handleChanagestatusAgregar = (value, item,) => {
        setDataTable((current) =>
            current?.map((data) =>
                data.idSeccion === item.idSeccion
                    ? {
                        ...data,
                        agregar: value
                    }
                    : data
            )
        );
    }
    const handleChanagestatusVer = (value, item,) => {
        setDataTable((current) =>
            current?.map((data) =>
                data.idSeccion === item.idSeccion
                    ? {
                        ...data,
                        ver: value
                    }
                    : data
            )
        );
    }
    const handleChanagestatusActualizar = (value, item,) => {
        setDataTable((current) =>
            current?.map((data) =>
                data.idSeccion === item.idSeccion
                    ? {
                        ...data,
                        actualizar: value
                    }
                    : data
            )
        );
    }
    const handleChanagestatusAcDes = (value, item) => {
        setDataTable((current) =>
            current?.map((data) =>
                data.idSeccion === item.idSeccion
                    ? {
                        ...data,
                        activarDesactivar: value
                    }
                    : data
            )
        );
    }
    // change
    const handleChangeProps = (value, item, propName) => {
        console.log(value, item, propName)
        setDataTable((current) =>
            current?.map((data) =>
                data.idSeccion === item.idSeccion
                    ? {
                        ...data,
                        [propName]: value ? 1 : 0
                    }
                    : data
            )
        );
    }
    // end
    const handleAddEdit = async (value) => {

        setLoading(true);
        if (id != 0) {
            await Usuario.updateUser(value)
                .then(res => {
                    setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos actualizados correctamente." })
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        } else {
            await Usuario.AddUser(value)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        setLoading(false);
                        navigate(-1);
                    } else {
                        setLoading(false);
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje })
                    }
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                    else
                        setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
                })
        }

    }
    const handleSaveRol = async () => {
        let data = {
            userId: id,
            rolId: curretnRol,
            subModuloId: ModuloId,
            permisos: dataTable
        }
        setLoading(true);
        await Usuario.SavePermisosRoles(data)
            .then(res => {
                setModalMessage({ ...modalMessage, isOpen: true, type: 2, message: "Datos Guardados correctamente." });
                setLoading(false);
                handlegetSecctionByMmodule(ModuloId);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
        // 
    }
    const handleGetAllSC = (values) => {        
        if (values.label === "DMSocioComercial") {
            handleGetSocioComercial();
            return true;
        } {
            setDtaSocioComercial([]);
        }
        return false;
    }
    const handleGetSocioComercial = async () => {
        await SocioComercialServices.getAllSocioComercialList()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.socioComercialId,
                        label: item.nombre
                    })
                });
                setDtaSocioComercial(NewData);
            }).catch(e => {

            })
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);

            }}
        // onChange={(value) => {
        //     
        // }}
        >{
                ({ isValid, values, handleChange, setFieldValue }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff'>
                            {
                                modalMessage.type === 1 &&
                                <ErrorModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }
                            {
                                modalMessage.type === 2 &&
                                <SuccessModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }

                        </Modal>
                        <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
                            <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                            Regresar a Registro {state.page}
                        </header>
                        <Form className='mx-5'>
                            <section className=' py-4'>
                                <div className='d-flex justify-content-between'>
                                    <span className='fs-5'><strong>Registro nuevo usuario</strong></span>
                                    <div>
                                        <button className='btn px-4' type='submit' disabled={!(isValid)}>Guardar</button>
                                    </div>
                                </div>
                                <span className='fs-6'>Datos personales</span>
                            </section>
                            <div className="mb-3 row">
                                <div className="col-sm-4">
                                    <label className="col-sm-2 col-form-label">Estatus</label>
                                    <div className="col-ms-6 form-check form-switch">
                                        <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                        <Field className="form-check-input" type="checkbox" name="status" />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <SelectField className="col-12" label="Rol" name="idRol" disabled={id != '0' ? true : false} items={dataRol} onChange={(event) => {
                                        setFieldValue("idRol", event.value);
                                        setFieldValue("nombreRol", event.label);
                                        setCurretnRol(event.value);
                                        let res = handleGetAllSC(event);
                                        if (!res)
                                            setFieldValue("socioId", "");
                                    }} />
                                </div>
                                {
                                    values.nombreRol === "DMSocioComercial" &&
                                    <div className="col-sm-4">
                                        <SelectField className="col-8" label="Socio Comercial" name="socioId" items={SocioComercial} onChange={(event) => {
                                            setFieldValue("socioId", event.value);
                                        }} />
                                    </div>
                                }

                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-4">
                                    <TextField label="Nombre Completo" name="nombreUsuario" type="text" holder="Escribe" />
                                </div>
                                <div className="col-sm-4">
                                    <TextField label="Número de Empleado" name="numeroEmpleado" type="text" holder="Escribe" />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-sm-4">
                                    <TextField label="Correo Electronico" name="email" type="text" holder="Escribe" />
                                </div>
                                <div className="col-sm-4">
                                    <SelectField className="col-12" label="Sucursal" name="sucursalId" items={dataSuc} onChange={(event) => {
                                        setFieldValue("sucursalId", event.value);
                                    }} />
                                </div>
                            </div>
                            <div className='mb-3 row'>
                                <div className="col-sm-4">
                                    <TextField label="Contraseña" name="password" type="password" holder="" />
                                </div>
                            </div>
                            {
                                id != '0' &&
                                <section className='mt-5'>
                                    <span className='fs-5'><strong>Privilegio</strong></span>
                                    <SubmenuUser />
                                    {
                                        value === '053f88fd-ae9a-4e69-87e1-e20f042ae090' &&
                                        <TablaReglas
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    }
                                    {
                                        value === '0030f26f-6af2-400e-a7b5-3aebf4e710ec' &&
                                        <TablaReglas
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    }
                                    {
                                        value === '03b1e9d4-96c5-4512-b956-dccf481bc516' &&
                                        <TablaReglas
                                            setStr={setStr}
                                            setModuloId={setModuloId}
                                            dataModulo={dataModulo}
                                            handleSaveRol={handleSaveRol}
                                            dataTable={dataTable}
                                            handleChangeProps={handleChangeProps}
                                            id={id}
                                        />
                                    }
                                </section>
                            }

                        </Form>
                    </>
                )
            }

        </Formik>

    )
}

export default UsuarioAddEdit