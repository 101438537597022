import React from 'react'
import { Link, Navigate, redirect, useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer';
import '../Login/Login.css';
import imagen from '../../Assets/img/LogotipoArrendadoraAzul.png';
import { useEffect, useState } from 'react'
import Usuario from '../../Services/Users/Usuarios';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import AuthLogin from '../../Utils/AuthLogin';
import useLogin from '../../Hooks/Login/useLogin';

const Login = () => {

 const { loading,
    modalMessage,
    setModalMessage,
    validateForm,
    navigate,
    values, 
    errors, 
    handleChange,
    touched, 
    handleUsernameChange, 
    handleSubmit,
    loginGo,
    dispatch,
    handelSession}= useLogin()
  
 
 
  
  return (
    <>
        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff'>
                            {
                                modalMessage.type === 1 &&
                                <ErrorModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }
                            {
                                modalMessage.type === 2 &&
                                <SuccessModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                />
                            }

        </Modal>
        <div className="contenedor">            
                <div className="col-12 col-sm-8 col-md-6 col-xl-3 container-login">
                    <figure className="logo-container">
                        <img  className="logo img-fluid" src={imagen} style={{width:'13em'}} alt="logo" />
                    </figure>
                    <div className="">
                        <h5 className="text-left fs-4">Iniciar sesión</h5>                       
                        <form className="mt-4"  onSubmit={handleSubmit}>
                            <div>
                                <div className="form-group">  
                                <label className="form-label">Usuario</label>
                                    {/* <i className="glyphicon fas fa-user"></i> */}

                                    <input 
                                    type="text" 
                                    placeholder="Escribe aquí" 
                                    name="email" id="email" 
                                    onChange={handleChange} 
                                    className="form-control mb-4 {errors.email ? 'input-error':''}" />    
                                </div>
                                {errors.email && touched.email && <p className='error'>{errors.email}</p>}
                                <div className="form-group">    
                                    <label className="form-label">Contraseña</label>
                                    {/* <i className="glyphicon fas fa-lock"></i> */}
                                    <input 
                                    id="AT_password"
                                    type="password" 
                                    value={values.password} 
                                    name="password" 
                                    placeholder="Escribe aquí" 
                                    maxLength="8" 
                                    onChange={handleChange} 
                                    className="form-control mb-4 {errors.password ? 'input-error':''}" />    
                                </div>
                                {errors.password && touched.password && <p className='error'>{errors.password}</p>}

                            </div>  
                            <div className='d-grid gap-2 col-12 mx-auto'>
                                <button id="AT_btnIniciar"type="submit" className="btn"> Iniciar sesión </button>
                            </div>                                                                                                      
                            <div className="text-center mt-2">
                                <Link to="/Password" style={{color:'#000'}} className="remenber-pass" >                                            
                                    Olvidé mi contraseña
                                </Link>                                
                            </div> 
                           
                        </form>                            
                    </div>
                </div>                               
            </div>
                <Footer
                cls={'fixed'}
                />  
    </>
  )
}

export default Login